import type React from "react";
import { useContext, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { ThemeContext } from "styled-components";
import invariant from "ts-invariant";

import { useGetPTCareEventsQuery } from "api/hooks/useGetPTCareEventsQuery";
import type { CareEvent, PatientStateTypes } from "api/models/CareEvent";
import type { PremiumType } from "api/models/PatientProfile";
import { SmallBlueClock } from "assets";
import { useProfileContext } from "contexts/ProfileContext";
import MedicalNoteLabel from "routes/patients/PatientProfile/components/PatientHeader/SignNotesTable/components/MedicalNoteLabel";
import Pagination from "shared/atoms/Pagination";
import PremiumIcon from "shared/atoms/PremiumIcon";
import {
  Table,
  TableCell,
  TableFooter,
  TableHeader,
  TableMissingContent,
  TableRow,
  TableSort,
} from "shared/molecules/Table";
import useLocalizedDate from "utils/date";
import { useWindowSize } from "utils/hooks";
import { getStorageValue, setStorageValue } from "utils/storage";

import { CommunicationGroupIcon } from "./CommunicationGroupIcon";
import {
  AfterNameIcon,
  Closed,
  Date,
  DateWrapper,
  Name,
  NameWrapper,
  NameWrapperInner,
  Type,
  TypeWrapper,
} from "./styles";
import typeSort from "./utils/typeSort";

type Note = {
  id: number;
  communication_group: string | null;
  patient: {
    id: number;
    name: string;
    deleted: boolean;
    state?: PatientStateTypes;
    premium_type: PremiumType;
  };
  type: string;
  date: Date;
  therapist_assignment_role?: string | null;
  week_number?: number;
};

const MedicalNotesTable: React.VFC = () => {
  const { t } = useTranslation();
  const { parseISO, format } = useLocalizedDate();
  const theme = useContext(ThemeContext);
  const { width } = useWindowSize();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const columns =
    width > parseInt(theme.breakpoint, 10)
      ? [
          t("dashboard.tables.notes.labels.patient"),
          t("dashboard.tables.notes.labels.type"),
          t("dashboard.tables.notes.labels.date"),
        ]
      : [t("dashboard.tables.notes.labels.patient"), t("dashboard.tables.notes.labels.date")];

  const [sort, setSort] = useState({
    selectedColumn: columns[2],
    descending: false,
  });
  const [offset, setOffset] = useState(0);
  const [notes, setNotes] = useState<Array<Note>>([]);
  const { profile } = useProfileContext();

  invariant(profile);

  const { id: userId } = profile;
  const { data, isLoading, error } = useGetPTCareEventsQuery({ userId });

  const LIMIT = 10;

  const compare = (a: CareEvent, b: CareEvent) => {
    let x: string | number | Date = "";
    let y: string | number | Date = "";
    switch (sort.selectedColumn) {
      case t("dashboard.tables.notes.labels.patient"):
        x = a.patient_name.toLowerCase();
        y = b.patient_name.toLowerCase();
        break;
      case t("dashboard.tables.notes.labels.type"):
        x = typeSort[a.label] ?? 99;
        y = typeSort[b.label] ?? 99;
        break;
      case t("dashboard.tables.notes.labels.date"):
        x = parseISO(a.start_time ?? "");
        y = parseISO(b.start_time ?? "");
        break;
      default:
        break;
    }
    if (x === y) {
      x = a.id;
      y = b.id;
    }
    if (sort.descending) {
      return y < x ? 1 : -1;
    }
    return x < y ? 1 : -1;
  };

  useEffect(() => {
    const storedMedicalNoteSorting = getStorageValue("medical-notes-sort");
    if (storedMedicalNoteSorting) {
      setSort(storedMedicalNoteSorting);
    }
  }, []);

  useEffect(() => {
    if (data) {
      const filteredNotes = data
        .sort(compare)
        .filter((_node: CareEvent, index: number) => index >= offset && index < offset + LIMIT)
        .map((node: CareEvent) => {
          return {
            id: node.id,
            communication_group: node.communication_group,
            patient: {
              id: node.patient_id,
              name: node.patient_name,
              deleted: node.patient_deleted,
              state: node.patient_state,
              premium_type: node.premium_type,
            },
            type: node.label.toUpperCase(),
            date: parseISO(node.start_time ?? ""),
            therapist_assignment_role: node.therapist_assignment_role,
            week_number: node.week_number,
          };
        });
      setNotes(filteredNotes);
    }
  }, [data, sort, offset, parseISO]);

  if (error || isLoading) {
    return (
      <Table>
        <thead>
          <TableHeader colSpan={columns.length} title={t("dashboard.tables.notes.title")} />
        </thead>
        <tbody>
          <TableMissingContent error={!!error} loading={isLoading} />
        </tbody>
        <tfoot />
      </Table>
    );
  }

  const hasNextPage = data ? data.length > offset + LIMIT : false;
  const hasPreviousPage = offset !== 0;
  const totalCount = data?.length || 0;

  const onSort = (clickedColumn: string) => {
    if (sort.selectedColumn === clickedColumn) {
      setSort({ selectedColumn: clickedColumn, descending: !sort.descending });
      setStorageValue("medical-notes-sort", { selectedColumn: clickedColumn, descending: !sort.descending });
    } else {
      setSort({ selectedColumn: clickedColumn, descending: true });
      setStorageValue("medical-notes-sort", { selectedColumn: clickedColumn, descending: true });
    }
    setOffset(0);
  };

  return (
    <Table>
      <thead>
        <TableHeader colSpan={columns.length} title={t("dashboard.tables.notes.title")} notifications={totalCount} />
        {notes.length > 0 && (
          <TableSort columns={columns} sortBy={sort.selectedColumn} descending={sort.descending} onSort={onSort} />
        )}
      </thead>
      <tbody>
        {notes.length > 0 &&
          notes.map(note => (
            <TableRow
              key={note.id}
              onClick={() =>
                navigate(`/patients/${note.patient.id}`, {
                  state: {
                    pathname,
                    text: "dashboard",
                  },
                })
              }
            >
              <NameWrapper dataTestId="medical-note">
                <NameWrapperInner>
                  <PremiumIcon size="small" premiumType={note.patient.premium_type} margin="0 4px 0 0" />
                  <Name
                    $label_state={
                      note.patient.state === "treatment_ended" ||
                      note.patient.state === "discharged" ||
                      note.patient.state === "in_selfcare"
                    }
                  >
                    {note.patient.name}
                    {note.patient.deleted && <Closed data-testid="account-closed">{t("errors.account_closed")}</Closed>}
                    {!note.patient.deleted && note.patient.state === "treatment_ended" && (
                      <Closed data-testid="treatment-ended">{t("errors.account_treatment_ended")}</Closed>
                    )}
                    {!note.patient.deleted && note.patient.state === "discharged" && (
                      <Closed data-testid="discharged">{t("errors.account_discharged")}</Closed>
                    )}
                    {!note.patient.deleted && note.patient.state === "in_selfcare" && (
                      <Closed data-testid="in-selfcare">{t("errors.account_in_selfcare")}</Closed>
                    )}
                  </Name>
                  {note.therapist_assignment_role === "substitute_therapist" && (
                    <AfterNameIcon title={t("patients.tool_tip.substitute_therapist")}>
                      <SmallBlueClock />
                    </AfterNameIcon>
                  )}
                </NameWrapperInner>
              </NameWrapper>
              <TypeWrapper dataTestId="medical-note-type">
                <Type data-testid={note.type}>
                  <CommunicationGroupIcon communicationGroup={note.communication_group} />
                  <MedicalNoteLabel weekNumber={note.week_number} careEventLabel={note.type} />
                </Type>
                {width < parseInt(theme.breakpoint, 10) && <Date>{format(note.date, "P")}</Date>}
              </TypeWrapper>
              <DateWrapper dataTestId="medical-note-date">
                <Date>{format(note.date, "P")}</Date>
              </DateWrapper>
            </TableRow>
          ))}

        {notes.length <= 0 && (
          <TableRow hoverEnabled={false}>
            <TableCell>{t("dashboard.tables.notes.no_notes") as string}</TableCell>
          </TableRow>
        )}
      </tbody>
      <tfoot>
        {totalCount > LIMIT && (
          <TableFooter colSpan={columns.length} addTopBorder>
            <Pagination
              totalCount={totalCount}
              first={offset + 1}
              last={offset + LIMIT < totalCount ? offset + LIMIT : totalCount}
              pageInfo={{ hasNextPage, hasPreviousPage }}
              onPageChange={goTo => (goTo === "next" ? setOffset(offset + LIMIT) : setOffset(offset - LIMIT))}
            />
          </TableFooter>
        )}
      </tfoot>
    </Table>
  );
};

export default MedicalNotesTable;
