import styled from "styled-components";

import withDefaultProps from "utils/react/withDefaultProps";

type ButtonProps = {
  $size?: "medium" | "large";
};

const Button = styled.button<ButtonProps>`
  display: flex;
  align-items: center;

  background-color: #fff;
  border: 2px solid ${props => props.theme.colors.greys.silver};
  border-radius: ${props => props.theme.borderRadius.basic};
  color: ${props => props.theme.colors.greys.dark};
  cursor: pointer;

  /* Medium is default */
  padding: 8px 14px;
  ${props => props.$size === "large" && "padding: 16px;"}

  margin: 0;

  &:active {
    background-color: ${props => props.theme.colors.greys.light5};
    border-color: ${props => props.theme.colors.greys.pinkish};
  }

  &:disabled {
    cursor: default;
    opacity: 0.5;
  }

  &:hover:not(:disabled) {
    background-color: ${props => props.theme.colors.greys.light4};
    border-color: ${props => props.theme.colors.greys.pinkish};
  }
`;

export default withDefaultProps(Button, { type: "button" });
