import type React from "react";
import { useState } from "react";

import { zodResolver } from "@hookform/resolvers/zod";
import type { SubmitHandler } from "react-hook-form";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import type { z } from "zod";

import { EmailIcon } from "assets";
import { passwordResetSchema } from "routes/login/utils/validation/emailPasswordSchemas";
import Notification from "shared/atoms/Notification";
import Spinner from "shared/atoms/Spinner";

import { resetPassword } from "../../queries";
import LoginInput from "../LoginInput";
import { Form, SubmitButton } from "../styledComponents";

interface PasswordResetFormProps {
  setSuccess: () => void;
}

type FormValues = z.infer<typeof passwordResetSchema>;

const PasswordResetForm: React.VFC<PasswordResetFormProps> = ({ setSuccess }) => {
  const { t } = useTranslation();
  const [request, setRequest] = useState({ loading: false, error: false, message: "" });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: zodResolver(passwordResetSchema),
  });

  const onSubmit: SubmitHandler<FormValues> = async body => {
    setRequest({ loading: true, error: false, message: "" });
    resetPassword(body)
      .then(() => {
        try {
          setRequest({ loading: false, error: false, message: "" });
          setSuccess();
        } catch {
          setRequest({ loading: false, error: true, message: t("errors.generic") });
        }
      })
      .catch(() => {
        setRequest({ loading: false, error: false, message: "" });
        setSuccess();
      });
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div>
        <LoginInput
          type="email"
          icon={EmailIcon}
          label={t("form.email")}
          placeholder={t("form.email")}
          {...register("email")}
          // FIXME: type translation
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          error={errors.email?.message && t(errors.email.message)}
        />
        {request.error && <Notification type="danger">{request?.message}</Notification>}
      </div>
      <SubmitButton disabled={request.loading} type="submit">
        {request.loading ? <Spinner small /> : t("buttons.reset_password")}
      </SubmitButton>
    </Form>
  );
};

export default PasswordResetForm;
