import type { AxiosResponse } from "axios";
import axios from "axios";

import { getRestHeaders } from "utils/cookie";
import { getAppDataRegion } from "utils/region";

// We don't use the regular AxiosClient here because this function can be
// called by the auth interceptors.
const signOut = (): Promise<AxiosResponse<{ success: boolean }>> =>
  axios.delete("/auth/sign_out", {
    baseURL: `${process.env.REACT_APP_RAILS_URL}/api/v1/`,
    headers: {
      "ja-app-name": "physio",
      "ja-app-build-number": process.env.REACT_APP_VERSION as string,
      "ja-app-market": getAppDataRegion() as string,
      ...(getRestHeaders() || {}),
    },
  });

export default signOut;
