import { createGlobalStyle } from "styled-components";

const ChartStyles = createGlobalStyle`
/* The grid lines */
.recharts-cartesian-grid-vertical > line {
  stroke: #dadfe2;
}

/* The x-axis line */
.recharts-cartesian-axis-line {
  stroke: #c4c4c4;
  stroke-width: 2;
}

/* The tiny lines connecting the x-axis line with the x-axis numbers.
   Same as background to hide them. */
.recharts-cartesian-axis-tick-line {
  stroke: #c4c4c4;
  stroke-width: 2;
}

/* The numbers on the x-axis */
.recharts-cartesian-axis-tick-value {
  fill: ${props => props.theme.colors.redesign.db80};
}
 
/* Change the inline styles for the legend wrapper element.
   Was not able to find a better way to position it. */
.recharts-legend-wrapper {
  top: 0 !important;
}
`;

export default ChartStyles;

export const chartMargin = {
  bottom: 5,
  left: 5,
  right: 20,
  top: 20,
};
