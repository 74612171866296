import type React from "react";
import { useContext, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { Navigate, Route, Routes, useLocation, useMatch, useParams } from "react-router-dom";
import styled from "styled-components";

import { SidebarCollapseIcon } from "assets";
import { useProfileContext } from "contexts/ProfileContext";
import { useVideoCallContext } from "contexts/VideoCallContext";
import ChatWindow from "routes/messages/components/ChatWindow";
import Spinner from "shared/atoms/Spinner";
import { AnalyticsPages, AnalyticsService } from "utils/analytics";
import { CurrentPatientContext } from "utils/contexts";
import { useWindowSize } from "utils/hooks";
import { userIsTherapist } from "utils/profile/profileHelper";
import { getStorageValue, removeStorageValue, setStorageValue } from "utils/storage";

import PatientHeader from "../PatientProfile/components/PatientHeader";
import { RelativePosition } from "../PatientProfile/components/UserCourse/components/SharedStyledComponents";
import UserCourseScreen from "../PatientProfile/components/UserCourse/UserCourseScreen";
import { Comments, Messages, Overview, Protocol } from "../PatientProfile/views";
import Information from "../PatientProfile/views/Information";
import acknowledgeNewPatientAlert from "../queries/acknowledgeNewPatientAlert";

const PatientProfile: React.VFC = () => {
  const { profile } = useProfileContext();
  const { patient, patientLoading, setPatientId, setPreviousPage, previousTreatmentId, setPreviousTreatmentId } =
    useContext(CurrentPatientContext);
  const isTherapist = userIsTherapist(profile);
  const match = useMatch("/*");
  const { height } = useWindowSize();
  const { state } = useLocation();
  const { patientId } = useParams();
  const { t } = useTranslation();

  const path = match?.pathname;

  const { callData, minimised } = useVideoCallContext();
  const inMaximisedCall = callData !== null && !minimised;
  const [sideContainer, setSideContainer] = useState(!getStorageValue("hideSideContainer"));

  useEffect(() => {
    if (state) {
      setPreviousPage(state);
    }
  }, [state]);

  useEffect(() => {
    if (path) {
      AnalyticsService.viewedPage(AnalyticsPages.PATIENTS.PATIENTS_PROFILE, path);
    }
    setPatientId(patientId);

    return () => {
      setPatientId(undefined);
      setPreviousTreatmentId(null);
    };
  }, [patientId]);

  useEffect(() => {
    if (profile && patient && patient.new_patient) {
      acknowledgeNewPatientAlert(String(patient.id), String(profile.id)).catch((error: unknown) => {
        /* eslint-disable no-console */
        console.warn("acknowledgeNewPatientAlert error", error);
      });
    }
  }, [profile, patient]);

  const sideContainerShowButton = () => {
    if (!sideContainer && isTherapist) {
      return (
        <SideContainerButtonShow
          onClick={() => {
            setSideContainer(true);
            removeStorageValue("hideSideContainer");
          }}
        >
          <Label>{t("sidebar.show_messages")}</Label>
          <SidebarCollapseIcon />
        </SideContainerButtonShow>
      );
    }
    return null;
  };

  const previousTreatmentNotification = () => {
    return (
      previousTreatmentId && (
        <PreviousTreatmentNotification>{t("patients.previous_treatment_selected")}</PreviousTreatmentNotification>
      )
    );
  };

  if (patient && !patientLoading) {
    return (
      <Wrapper>
        <Container>
          <Routes>
            <Route
              path="information/*"
              element={
                <SubMenuContainer vh={height}>
                  <PatientHeader />
                  <PatientsContentContainer $adjustForCall={inMaximisedCall}>
                    {previousTreatmentNotification()}
                    <Information />
                  </PatientsContentContainer>
                  {sideContainerShowButton()}
                </SubMenuContainer>
              }
            />
            <Route
              path="protocol/*"
              element={
                <Routes>
                  <Route path="/" element={<Navigate to={{ pathname: "current" }} state={state} />} />
                  <Route
                    path=":weekInfo/*"
                    element={
                      <SubMenuContainer vh={height}>
                        <PatientHeader />
                        <PatientsContentContainer $adjustForCall={inMaximisedCall}>
                          {previousTreatmentNotification()}
                          <Protocol />
                        </PatientsContentContainer>
                        {sideContainerShowButton()}
                      </SubMenuContainer>
                    }
                  />
                </Routes>
              }
            />
            <Route
              path="comments"
              element={
                <SubMenuContainer vh={height} id="comments-container">
                  <PatientHeader />
                  <PatientsContentContainer $adjustForCall={inMaximisedCall}>
                    {previousTreatmentNotification()}
                    <Comments />
                  </PatientsContentContainer>
                  {sideContainerShowButton()}
                </SubMenuContainer>
              }
            />
            <Route
              path="messages"
              element={
                <MessagesContainer vh={height}>
                  <PatientHeader />
                  <PatientsContentContainer $adjustForCall={inMaximisedCall}>
                    {previousTreatmentNotification()}
                    <Messages />
                  </PatientsContentContainer>
                  {sideContainerShowButton()}
                </MessagesContainer>
              }
            />
            <Route
              path="overview"
              element={
                <SubMenuContainer vh={height}>
                  <PatientHeader />
                  <PatientsContentContainer $adjustForCall={inMaximisedCall}>
                    {previousTreatmentNotification()}
                    <Overview />
                  </PatientsContentContainer>
                  {sideContainerShowButton()}
                </SubMenuContainer>
              }
            />
            <Route
              path="usercourse/*"
              element={
                <SubMenuContainer vh={0}>
                  <PatientsContentContainer $adjustForCall={inMaximisedCall}>
                    {previousTreatmentNotification()}
                    <RelativePosition>
                      <UserCourseScreen />
                    </RelativePosition>
                  </PatientsContentContainer>
                  {sideContainerShowButton()}
                </SubMenuContainer>
              }
            />
            <Route path="/" element={<Navigate to={{ pathname: "overview" }} replace state={state} />} />
          </Routes>
          {sideContainer && isTherapist && (
            <SideContainer>
              <ChatWrapper>
                <ChatWindow selectedPatient={patient.id} />
              </ChatWrapper>
              <SideContainerButtonHide
                data-testid="hide-side-panel"
                onClick={() => {
                  setSideContainer(false);
                  setStorageValue("hideSideContainer", "hide");
                }}
              >
                <MessagesCollapseIcon />
                <Label>{t("sidebar.hide_messages")}</Label>
              </SideContainerButtonHide>
            </SideContainer>
          )}
        </Container>
      </Wrapper>
    );
  }
  return (
    <LoadingContainer vh={height}>
      <Spinner />
    </LoadingContainer>
  );
};

export default PatientProfile;

type PatientsContainerProps = {
  vh: number;
};

const SubMenuContainer = styled.div<PatientsContainerProps>`
  height: ${props => (props.vh ? `${props.vh}px` : "100vh")};
  overflow: auto;
  flex-grow: 1;
  ${props => props.theme.aboveBreakpoint} {
    /* width */
    ::-webkit-scrollbar {
      width: 8px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: rgba(0, 0, 0, 0.1);
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.2);
      border-radius: 4px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: rgba(0, 0, 0, 0.3);
    }
  }
`;

const MessagesContainer = styled(SubMenuContainer)`
  flex-direction: column;
  overflow: unset;
`;

type PatientsContentContainerProps = {
  $adjustForCall: boolean;
};

const PatientsContentContainer = styled.div<PatientsContentContainerProps>`
  background: ${({ theme }) => theme.colors.white};
  ${props =>
    props.$adjustForCall
      ? `
        @media screen and (min-width: ${
          parseInt(props.theme.breakpointLarge, 10) + props.theme.videoCallWidthMaximised
        }px) and (max-width: ${parseInt(props.theme.breakpointLarge, 10) + props.theme.videoCallWidthMaximised * 2}px) {
          margin-left: auto;
          max-width: ${props.theme.containerWidth + 40}px;
        }`
      : ""};
`;

type LoadingContainerProps = {
  vh: number;
};

const LoadingContainer = styled.div<LoadingContainerProps>`
  height: ${props => (props.vh ? `${props.vh}px` : "100vh")};
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Wrapper = styled.div`
  width: 100%;
  background: ${props => props.theme.colors.white};
`;

const Container = styled.div`
  display: flex;
`;

const SideContainer = styled.div`
  @media screen and (max-width: 1600px) {
    display: none;
  }

  max-width: 585px;
  background-color: ${props => props.theme.colors.greys.light2};
  border-left: 1px solid ${props => props.theme.colors.greys.silver};
`;

const ChatWrapper = styled.div`
  height: ${() => window.innerHeight - 32}px;
  display: flex;
  flex-direction: column;
`;

const SideContainerButtonShow = styled.div`
  @media screen and (max-width: 1600px) {
    display: none;
  }

  display: flex;
  align-items: center;
  background-color: ${props => props.theme.colors.greys.light3};
  height: 32px;
  line-height: 32px;
  cursor: pointer;
  padding: 0 4px;
  right: 8px;
  bottom: 0;
  z-index: 1;
  position: absolute;
`;

const SideContainerButtonHide = styled.div`
  display: flex;
  align-items: center;
  background-color: ${props => props.theme.colors.redesign.b10};
  height: 32px;
  line-height: 32px;
  cursor: pointer;
  padding-left: 12px;
`;

const Label = styled.div`
  font-size: 12px;
  padding: 0 4px;
  color: ${props => props.theme.colors.redesign.b100};
  font-weight: 500;
`;

const MessagesCollapseIcon = styled(SidebarCollapseIcon)`
  transform: rotate(180deg);
`;

const PreviousTreatmentNotification = styled.div`
  color: ${props => props.theme.colors.primary.base};
  background-color: ${props => props.theme.colors.exercise.background};
  border: 1px solid ${props => props.theme.colors.exercise.accent};
  max-width: 1000px;
  box-sizing: border-box;
  margin: 24px auto 12px;
  padding: 12px;
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
  text-align: center;
  ${props => props.theme.belowBreakpoint} {
    border-radius: 0;
    border-left: none;
    border-right: none;
  }
`;
