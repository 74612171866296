const AnalyticsPages = {
  CALENDAR: "Calendar",
  LOGIN: {
    EMAIL: "Email Login",
    BANK_ID: "BankID Login",
  },
  RESET_PASSWORD: {
    FORGOT_PASSWORD: "Password Reset",
    NEW_PASSWORD: "New password",
  },
  SIGNUP: {
    EMAIL: "Signup Email",
  },
  ONBOARDING: "Onboarding",
  DASHBOARD: "Dashboard",
  MESSAGES: "Messages",
  SETTINGS: "Settings",
  MONTHLY_REPORT: "Monthly Report",
  LIBRARY: {
    MAIN: "Library",
    ACTIVITY: "Library Activity",
  },
  PATIENTS: {
    PATIENTS_LIST: "Patients List",
    PATIENTS_PROFILE: "Patients Profile",
    OVERVIEW: "Patient Overview Tab",
    MESSAGES: "Patient Messages Tab",
    COMMENTS: "Patient Comments Tab",
    PROTOCOL: "Patient Protocol Tab",
    INFORMATION: {
      PARTNER_MEDICAL_RECORD: "Patient Information Tab - Medical record, viewed by partner",
      MEDICAL_RECORD: "Patient Information Tab - Medical record",
      SIGN_UP_QUESTIONS: "Patient Information Tab - Sign-up questions",
      HEALTH_QUESTIONNAIRES: "Patient Information Tab - Health questionnaires",
      GOAL: "Patient Information Tab - Goal",
      PERSONAL_DETAILS: "Patient Information Tab - Personal details",
    },
  },
};

export default AnalyticsPages;
