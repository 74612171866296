import type { VFC } from "react";
import { useMemo } from "react";

import { addDays } from "date-fns";
import { useTranslation } from "react-i18next";
import { Bar, BarChart, CartesianGrid, LabelList, ReferenceLine, ResponsiveContainer, XAxis, YAxis } from "recharts";
import styled from "styled-components";

import { ChevronLeftIcon, ChevronRightIcon } from "assets";
import useLocale from "utils/date/useLocale";

import Button from "../Button";
import ButtonGroup from "../ButtonGroup";
import ChartCaption from "../ChartCaption";
import { chartMargin } from "../ChartStyles";
import ChartTable from "../ChartTable";
import type { ChartTableData } from "../ChartTable";
import CustomBarLabel from "../CustomBarLabel";
import CustomBarShape from "../CustomBarShape";
import CustomReferenceLineLabel from "../CustomReferenceLineLabel";
import DateSpan from "../DateSpan";

import type { WorkChartData } from "./WorkChartData";

type WorkChartProps = {
  chartData: WorkChartData[];
  firstDayOfWeek: Date;
  onNext: () => void;
  onPrevious: () => void;
  signedCareEvents: [ChartTableData[], ChartTableData[]];
  unsignedCareEvents: [ChartTableData[], ChartTableData[]];
};

type ValueAccessorProps = {
  payload: WorkChartData;
};

const WorkChart: VFC<WorkChartProps> = ({
  chartData,
  firstDayOfWeek,
  onNext,
  onPrevious,
  signedCareEvents,
  unsignedCareEvents,
}) => {
  const locale = useLocale();
  const { t } = useTranslation();
  const lastDayOfWeek = addDays(firstDayOfWeek, 6);
  const average = useMemo(() => sumValues(chartData) / chartData.length, [chartData]);
  const averageFormatted = average.toLocaleString(locale.code, { maximumFractionDigits: 2 });

  return (
    <>
      <ChartTableContainer>
        <ChartTable
          dataTestId="work-chart-signed-care-events-table"
          head={signedCareEvents[0]}
          body={signedCareEvents[1]}
        />
        <ChartTable
          dataTestId="work-chart-unsigned-care-events-table"
          head={unsignedCareEvents[0]}
          body={unsignedCareEvents[1]}
        />
      </ChartTableContainer>
      <ChartCaption>{t("monthly_report.your_work.chart_caption")}</ChartCaption>
      <ButtonContainer>
        <ButtonGroup>
          <Button $size="medium" data-testid="work-chart-previous" onClick={onPrevious}>
            <ChevronLeftIcon />
          </Button>
          <DateSpan dataTestId="work-chart-date-span" fromDate={firstDayOfWeek} toDate={lastDayOfWeek} />
          <Button $size="medium" data-testid="work-chart-next" onClick={onNext}>
            <ChevronRightIcon />
          </Button>
        </ButtonGroup>
      </ButtonContainer>
      <div data-testid="work-chart">
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={chartData} layout="horizontal" margin={chartMargin}>
            <CartesianGrid strokeDasharray="4" vertical={false} />
            <XAxis dataKey="name" type="category" />
            <YAxis tickCount={8} type="number" />
            <Bar
              data-testid="work-chart-bar"
              dataKey="value"
              fill="#5096e8"
              isAnimationActive={false}
              name={t("monthly_report.your_work.chart_caption")}
              shape={<CustomBarShape />}
            >
              <LabelList
                content={<CustomBarLabel />}
                valueAccessor={({ payload }: ValueAccessorProps) => ({
                  amount: payload.value,
                })}
              />
            </Bar>
            <ReferenceLine
              y={average}
              stroke="#70c8bc"
              label={<CustomReferenceLineLabel value={averageFormatted} />}
              strokeDasharray="3 3"
              strokeWidth={2}
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </>
  );
};

const sumValues = (items: WorkChartData[]) => items.reduce((sum, { value }) => sum + value, 0);

export default WorkChart;

const ChartTableContainer = styled.div`
  display: flex;
  gap: 24px;
  margin-bottom: 24px;

  ${props => props.theme.belowBreakpoint} {
    flex-direction: column;
  }

  & > div {
    flex: 1;
  }
`;

const ButtonContainer = styled.div`
  margin-top: 10px;
`;
