import type { VFC } from "react";

import useLocale from "utils/date/useLocale";
import isFiniteNumber from "utils/number/is-finite-number";

type CustomBarLabelProps = {
  height?: number;
  offset?: number;
  value?: {
    amount: number;
    currency?: string;
  };
  width?: number;
  x?: number;
  y?: number;
};

// The `customAccessorValue` is what is returned from the `valueAccessor` on <LabelList>
const CustomBarLabel: VFC<CustomBarLabelProps> = ({ height, offset, value: customAccessorValue, width, x, y }) => {
  const locale = useLocale();
  const fontSize = 14;
  const centeredX = x && width ? x + width / 2 : x;
  const centeredY = y && height && offset ? y + offset + height / 2 : y;
  const { amount } = customAccessorValue ?? {};

  if (!amount) {
    return null;
  }
  return (
    <text
      fill="#fff"
      fontSize={fontSize}
      height={height}
      offset={offset}
      textAnchor="middle"
      width={width}
      x={centeredX}
      y={centeredY}
    >
      {isFiniteNumber(amount)
        ? amount.toLocaleString(locale.code, {
            maximumFractionDigits: 0,
          })
        : amount}
    </text>
  );
};

export default CustomBarLabel;
