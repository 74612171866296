import type {
  CareEventFieldPath,
  CareEventSubset,
  StructuredField,
  StructuredFieldPath,
} from "types/MedicalNoteConfig";

const applyChanges = <
  R extends CareEventSubset,
  C extends CareEventSubset,
  M extends R & C & { structured_fields?: R["structured_fields"] & C["structured_fields"] }
>(
  reference: R,
  changes: C
): M => {
  const updated = { ...reference, ...changes };

  if (updated.structured_fields)
    updated.structured_fields = { ...reference.structured_fields, ...changes.structured_fields };
  return updated as M;
};

const isStructuredFieldPath = (field: CareEventFieldPath): field is StructuredFieldPath =>
  /structured_fields/.test(field);

const trim = (field: StructuredFieldPath): StructuredField => field.split(".")[1] as StructuredField;

const reduceCareEventToFields = (fieldNames: CareEventFieldPath[], careEvent: CareEventSubset): CareEventSubset =>
  fieldNames.reduce((acc, field) => {
    if (!isStructuredFieldPath(field)) {
      const hasField = field in careEvent;
      if (!hasField) return acc;
      return { ...acc, [field]: careEvent[field] };
    }

    const structuredField = trim(field);
    const { structured_fields } = careEvent;
    const hasField = structured_fields && structuredField in structured_fields;
    if (!hasField) return acc;

    return {
      ...acc,
      structured_fields: {
        ...acc.structured_fields,
        [structuredField]: structured_fields[structuredField],
      },
    };
  }, Object.create(null));

export { applyChanges, isStructuredFieldPath, reduceCareEventToFields };
