import type React from "react";
import { useState } from "react";

import { zodResolver } from "@hookform/resolvers/zod";
import type { SubmitHandler } from "react-hook-form";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import type { z } from "zod";

import { LockIcon } from "assets";
import { getErrorMessage, newPasswordSchema } from "routes/login/utils/validation/emailPasswordSchemas";
import Notification from "shared/atoms/Notification";
import Spinner from "shared/atoms/Spinner";
import { useQueryParams } from "utils/hooks";

import { authenticateWithEmail, newPassword } from "../../queries";
import LoginInput from "../LoginInput";
import { Form, SubmitButton } from "../styledComponents";

type FormValues = z.infer<typeof newPasswordSchema>;

const NewPasswordForm: React.VFC = () => {
  const query = useQueryParams();
  const client = query.get("client");
  const uid = query.get("uid");
  const accessToken = query.get("access-token");
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [request, setRequest] = useState({ loading: false, error: false, message: "" });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    mode: "onTouched",
    resolver: zodResolver(newPasswordSchema),
  });

  const onSubmit: SubmitHandler<FormValues> = async body => {
    setRequest({ loading: true, error: false, message: "" });

    try {
      if (accessToken && client && uid) {
        await newPassword(body, {
          "access-token": accessToken,
          client,
          "token-type": "Bearer",
          uid,
        });
      }

      if (uid) {
        await authenticateWithEmail({ email: uid, password: body.password });
      }

      setRequest({ loading: false, error: false, message: "" });
      navigate("/");
    } catch (_error) {
      setRequest({ loading: false, error: true, message: t("errors.generic") });
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div>
        <LoginInput
          icon={LockIcon}
          label={t("settings.profile_password.new_password")}
          type="password"
          {...register("password")}
          error={getErrorMessage(t, errors.password)}
          autoFocus
        />
        <LoginInput
          icon={LockIcon}
          label={t("settings.profile_password.confirm_password")}
          type="password"
          {...register("password_confirmation")}
          error={getErrorMessage(t, errors.password_confirmation)}
        />
        {request.error && <Notification type="danger">{request?.message}</Notification>}
      </div>
      <SubmitButton disabled={request.loading} type="submit">
        {request.loading ? <Spinner small /> : t("buttons.create_password")}
      </SubmitButton>
    </Form>
  );
};

export default NewPasswordForm;
