import { useEffect, useState } from "react";

import { faCirclePlus } from "@fortawesome/pro-regular-svg-icons";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import type { HealthFormType, UserCourseExercise } from "api/schemas/UserCourse";
import useLocale from "utils/date/useLocale";

import IconLink from "./IconLink";
import RadioButtons from "./RadioButtons";
import { OuterMargin, TopBar, TopLeft, TopRight } from "./SharedStyledComponents";
import UserCourseExerciseItem from "./UserCourseExercise";

const weekSort = (locale?: string) => (a: UserCourseExercise, b: UserCourseExercise) => {
  if (a.introduction_protocol_week === b.introduction_protocol_week) {
    return a.levels[a.current_level - 1].title.localeCompare(b.levels[b.current_level - 1].title, locale, {
      numeric: true,
      sensitivity: "base",
    });
  }
  return a.introduction_protocol_week >= b.introduction_protocol_week ? 1 : -1;
};

const ExercisesList = ({
  exercises,
  onOpenExercisePicker,
  currentProtocolWeek,
  onDeleteExercise,
  onChangeLevel,
  onChangeWeek,
  onChangeEndWeek,
  onClickExercise,
  newlyAddedExercises,
}: {
  exercises: UserCourseExercise[];
  onOpenExercisePicker: () => void;
  onDeleteExercise: (id: number) => void;
  currentProtocolWeek: number;
  onChangeLevel: (id: number, level: number) => void;
  onChangeWeek: (id: number, week: number) => void;
  onChangeEndWeek: (id: number, week: number | null) => void;
  onClickExercise: (n: number) => void;
  newlyAddedExercises: number[];
}) => {
  type ActiveFilterValue = "all" | "active" | "future";

  const { t } = useTranslation();
  const [filteredExercises, setFilteredExercises] = useState<UserCourseExercise[]>([]);
  const [activeFilter, setActiveFilter] = useState<ActiveFilterValue>("all");
  const locale = useLocale().code;

  const filterOptions: { label: string; value: ActiveFilterValue }[] = [
    {
      label: t("usercourse.exerciselist.all"),
      value: "all",
    },
    {
      label: t("usercourse.exerciselist.active"),
      value: "active",
    },
    {
      label: t("usercourse.exerciselist.future"),
      value: "future",
    },
  ];

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const healthFormTypeOptions: { label: string; value: HealthFormType }[] = [
    { label: t("usercourse.healthformtype.generic"), value: "generic" },
    { label: t("usercourse.healthformtype.neck"), value: "neck" },
    { label: t("usercourse.healthformtype.hand_oa"), value: "hand_oa" },
    { label: t("usercourse.healthformtype.hip_oa"), value: "hip_oa" },
    { label: t("usercourse.healthformtype.knee_oa"), value: "knee_oa" },
    { label: t("usercourse.healthformtype.low_back_pain"), value: "low_back_pain" },
    { label: t("usercourse.healthformtype.shoulder_pain"), value: "shoulder_pain" },
  ];

  useEffect(() => {
    const sortedExcercises = exercises.slice().sort(weekSort(locale));
    if (activeFilter === "all") {
      setFilteredExercises(sortedExcercises);
    }
    if (activeFilter === "active") {
      setFilteredExercises(sortedExcercises.filter(e => e.active));
    } else if (activeFilter === "future") {
      setFilteredExercises(sortedExcercises.filter(e => !e.active));
    }
  }, [exercises, activeFilter]);

  return (
    <div style={{ width: "100%" }}>
      <PanelBg>
        <OuterMargin>
          <TopBar>
            <TopLeft>
              <RadioButtons
                options={filterOptions}
                onChange={v => {
                  setActiveFilter(v);
                }}
                value={activeFilter}
              />
            </TopLeft>
            <TopRight>
              <IconLink icon={faCirclePlus} title={t("usercourse.add_exercise")} onClick={onOpenExercisePicker} />
            </TopRight>
          </TopBar>
          {filteredExercises && (
            <>
              <Exercises>
                {filteredExercises.map(exercise => {
                  return (
                    <UserCourseExerciseItem
                      newlyAdded={newlyAddedExercises.includes(exercise.id)}
                      key={`usercourseexercise_${exercise.id}`}
                      exercise={exercise}
                      onChangeLevel={onChangeLevel}
                      onDelete={onDeleteExercise}
                      currentProtocolWeek={currentProtocolWeek}
                      onChangeWeek={onChangeWeek}
                      onChangeEndWeek={onChangeEndWeek}
                      onClickExercise={onClickExercise}
                    />
                  );
                })}
              </Exercises>
            </>
          )}
        </OuterMargin>
      </PanelBg>
    </div>
  );
};

export default ExercisesList;

const Exercises = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
`;

const PanelBg = styled.div`
  padding: 16px 0;
  flex-wrap: wrap;
  gap: 16px;
`;
