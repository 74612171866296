import type React from "react";
import { useState } from "react";

import { useTranslation } from "react-i18next";
import styled from "styled-components";
import invariant from "ts-invariant";

import useGetPersonalInvitationLink from "api/hooks/useGetPersonalInvitationLink";
import { CheckIcon, LinkIcon } from "assets";
import { useProfileContext } from "contexts/ProfileContext";
import Notification from "shared/atoms/Notification";
import Spinner from "shared/atoms/Spinner";

const PersonalInvitationLink: React.VFC = () => {
  const { t } = useTranslation();
  const { profile } = useProfileContext();
  invariant(profile);
  const { data, isLoading, isError } = useGetPersonalInvitationLink(profile.id);
  const [copied, setCopied] = useState(false);

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  return (
    <>
      <InviteLinksDescription>{t("form.personal_links_form.description")}</InviteLinksDescription>

      {data && (
        <>
          <StyledLink onClick={() => copyToClipboard(data.personal_invitation_url)}>
            <StyledLinkIcon />
            {t("form.personal_links_form.invitation_link")}
          </StyledLink>
          {copied && (
            <CopiedWrapper>
              <StyledCheckIcon />
              <CopiedLabel>{t("form.personal_links_form.copied")}</CopiedLabel>
            </CopiedWrapper>
          )}
        </>
      )}

      {isError && (
        <Notification type="danger">
          <span>{t("errors.generic")}</span>
        </Notification>
      )}

      {isLoading && <Spinner style={{ width: "50px", height: "50px" }} />}
    </>
  );
};

export { PersonalInvitationLink };

const InviteLinksDescription = styled.div`
  margin-bottom: 20px;
  line-height: 1.5em;
  text-align: center;
  color: ${props => props.theme.colors.primary.base};
`;

const StyledLink = styled.div`
  display: flex;
  width: 100%;
  height: 50px;
  border-radius: 8px;
  background-color: ${props => props.theme.colors.greys.light3};
  color: ${props => props.theme.colors.redesign.b100};
  line-height: 50px;

  font-weight: 500;
  font-size: 18px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const StyledLinkIcon = styled(LinkIcon)`
  margin-right: 8px;
`;

const CopiedWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const StyledCheckIcon = styled(CheckIcon)`
  width: 18px;
  height: 18px;
`;

const CopiedLabel = styled.div`
  margin-left: 8px;
`;
