import type React from "react";
import { useState } from "react";

import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import invariant from "ts-invariant";

import { usePutTherapistMutation } from "api/hooks/usePutTherapistMutation";
import { EditIcon, EyeIcon } from "assets";
import { useProfileContext } from "contexts/ProfileContext";
import { TextButton } from "shared/atoms/Button";
import Notification from "shared/atoms/Notification";

import {
  ProfileBioFields,
  StyledHeader,
  StyledIconButton,
  StyledParagraph,
  StyledPrimaryButton,
  StyledSubHeader,
  StyledTextArea,
  TextContainer,
  Wrapper,
} from "./helpers";
import ProfileBioPopup from "./ProfileBioPopup";

const ButtonContainer = styled.div`
  ${props => props.theme.aboveBreakpoint} {
    display: flex;
    gap: 40px;
  }
`;
const StyledEyeIcon = styled(EyeIcon)`
  height: 20px;
  width: 20px;
`;

type OnSubmitData = {
  bio: string;
  experience: string;
  availability: string;
  outsideClinic: string;
};

const ProfileBio: React.VFC = () => {
  const { t } = useTranslation();
  const { profile, setProfile } = useProfileContext();
  const [isEditing, setIsEditing] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const putTherapistMutation = usePutTherapistMutation();

  invariant(profile);

  const { handleSubmit, register, reset } = useForm({
    defaultValues: {
      bio: profile.therapist_profile.bio || "",
      experience: profile.therapist_profile.bio_experience || "",
      availability: profile.therapist_profile.bio_availability || "",
      outsideClinic: profile.therapist_profile.bio_outside_the_clinic || "",
    },
  });

  const handleChangeBioClick = () => {
    putTherapistMutation.reset();
    setIsEditing(!isEditing);
  };

  const handlePreviewBioClick = () => {
    setShowPreview(!showPreview);
  };

  const onSubmit = async ({ bio, experience, availability, outsideClinic }: OnSubmitData) => {
    const therapistId = profile.id;
    const data = {
      therapist_profile: {
        bio,
        bio_experience: experience,
        bio_availability: availability,
        bio_outside_the_clinic: outsideClinic,
      },
    };

    const variables = { data, therapistId };

    putTherapistMutation.mutate(variables, {
      onSuccess(response) {
        setProfile(response);
        reset({
          bio: data.therapist_profile.bio || "",
          experience: data.therapist_profile.bio_experience || "",
          availability: data.therapist_profile.bio_availability || "",
          outsideClinic: data.therapist_profile.bio_outside_the_clinic || "",
        });
        setIsEditing(!isEditing);
      },
    });
  };

  const { bio, bio_experience, bio_availability, bio_outside_the_clinic } = profile.therapist_profile;

  return (
    <TextContainer data-testid="profile-bio">
      <form onSubmit={handleSubmit(onSubmit)}>
        <StyledHeader>{t("settings.profile_bio.heading")}</StyledHeader>
        {isEditing ? (
          <>
            <StyledSubHeader>{t("settings.profile_bio.general_info.heading")}</StyledSubHeader>
            <StyledTextArea dataTestId="bio" {...register("bio")} rows={7} />
            <StyledSubHeader>{t("settings.profile_bio.experience.heading")}</StyledSubHeader>
            <StyledTextArea dataTestId="experience" {...register("experience")} rows={7} />
            <StyledSubHeader>{t("settings.profile_bio.availability.heading")}</StyledSubHeader>
            <StyledTextArea dataTestId="availability" {...register("availability")} rows={7} />
            <StyledSubHeader>{t("settings.profile_bio.hobbies.heading")}</StyledSubHeader>
            <StyledTextArea dataTestId="outsideClinic" {...register("outsideClinic")} rows={7} />
            <Wrapper>
              <TextButton onClick={handleChangeBioClick}>{t("buttons.cancel")}</TextButton>
              <StyledPrimaryButton type="submit">{t("buttons.save")}</StyledPrimaryButton>
            </Wrapper>
          </>
        ) : (
          <>
            <ProfileBioFields>
              {bio && (
                <>
                  <StyledSubHeader>{t("settings.profile_bio.general_info.heading")}</StyledSubHeader>
                  <StyledParagraph data-testid="bio-info">{bio}</StyledParagraph>
                </>
              )}
              {bio_experience && (
                <>
                  <StyledSubHeader>{t("settings.profile_bio.experience.heading")}</StyledSubHeader>
                  <StyledParagraph data-testid="bio-experience-info">{bio_experience}</StyledParagraph>
                </>
              )}
              {bio_availability && (
                <>
                  <StyledSubHeader>{t("settings.profile_bio.availability.heading")}</StyledSubHeader>
                  <StyledParagraph data-testid="bio-availability-info">{bio_availability}</StyledParagraph>
                </>
              )}
              {bio_outside_the_clinic && (
                <>
                  <StyledSubHeader>{t("settings.profile_bio.hobbies.heading")}</StyledSubHeader>
                  <StyledParagraph data-testid="bio-outside-clinic-info">{bio_outside_the_clinic}</StyledParagraph>
                </>
              )}
            </ProfileBioFields>
            <ButtonContainer>
              <StyledIconButton
                dataTestId="edit-bio-button"
                label={t("settings.profile_bio.change_profile_bio")}
                onClick={handleChangeBioClick}
              >
                <EditIcon />
              </StyledIconButton>
              <StyledIconButton
                dataTestId="preview-bio-button"
                label={t("settings.profile_bio.preview_profile_bio")}
                onClick={handlePreviewBioClick}
              >
                <StyledEyeIcon />
              </StyledIconButton>
            </ButtonContainer>
          </>
        )}
        {showPreview && <ProfileBioPopup setOpen={setShowPreview} />}
        {putTherapistMutation.isError && (
          <Notification m="10px 0" type="danger">
            {t("errors.generic")}
          </Notification>
        )}
      </form>
    </TextContainer>
  );
};

export default ProfileBio;
