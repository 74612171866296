import Cookies from "js-cookie";

import { isNewAuthSession } from "utils/auth";

interface LegacyAuthenticationHeaders {
  "access-token": string;
  "token-type": string;
  uid: string;
  client: string;
}

interface AuthenticationHeaders {
  Authorization: string;
}

const getLegacyAuthenticationHeaders = (): LegacyAuthenticationHeaders | null => {
  const data = Cookies.get("rja_auth_headers");

  if (data) {
    const result = JSON.parse(data);
    return {
      "token-type": result?.tokenType,
      "access-token": result?.accessToken,
      client: result?.client,
      uid: result?.uid,
    };
  }

  return null;
};

const getNewAuthenticationHeaders = (): AuthenticationHeaders | null => {
  const idToken = Cookies.get("rja_auth_id_token");
  if (idToken) {
    return {
      Authorization: `Bearer ${idToken}`,
    };
  }

  return null;
};

const getRestHeaders = (): AuthenticationHeaders | LegacyAuthenticationHeaders | null => {
  if (isNewAuthSession() && getNewAuthenticationHeaders()) {
    return getNewAuthenticationHeaders();
  }

  return getLegacyAuthenticationHeaders();
};

export default getRestHeaders;
