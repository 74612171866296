import type React from "react";
import { useContext, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useMatch, useNavigate } from "react-router-dom";
import styled from "styled-components";

import type { Activity } from "api/models/Activity";
import { generateUrlSuffix, sortActivities } from "routes/patients/utils/activityHelpers";
import Hidden from "shared/atoms/Hidden";
import { ProtocolContext } from "utils/contexts";
import useLocalizedDate from "utils/date";

import CalendarCard from "../CalendarCard";
import ProtocolActivityGroup from "../ProtocolActivityGroup";

import { CalendarColumn, CalendarContent, DayItem, DayList, DayListWrapper, DayTab, DayTabs } from "./helpers";

const Container = styled.div`
  width: 100%;
  background-color: ${props => props.theme.colors.white};
`;

const NoActivities = styled.div`
  ${props => props.theme.font.header5}
  color: ${props => props.theme.colors.greys.dark};
  text-align: center;
  margin-top: ${props => props.theme.spacing.S_10};
`;

interface Day {
  date: string;
  activities: Activity[];
}

interface CalendarProps {
  days: Day[];
  onSelectChange: (day?: number) => void;
}

const Calendar: React.VFC<CalendarProps> = ({ days, onSelectChange }) => {
  const { t } = useTranslation();
  const { format, isToday, parseISO, isSameDay } = useLocalizedDate();
  const { selectedDayMobile, setSelectedDayMobile } = useContext(ProtocolContext);
  const [selected, setSelected] = useState(0);
  const navigate = useNavigate();
  const match = useMatch("*");

  useEffect(() => {
    if (selectedDayMobile !== null) {
      setSelected(selectedDayMobile);
      onSelectChange(selectedDayMobile);
      setSelectedDayMobile(null);
    } else {
      const today = days.findIndex(day => isToday(parseISO(day.date)));
      if (today !== -1) {
        setSelected(today);
        onSelectChange(today);
      } else {
        setSelected(0);
        onSelectChange(0);
      }
    }
  }, [match?.pathname]);

  return (
    <Container data-testid="calendar">
      <DayListWrapper>
        <Hidden type="belowLarge">
          <DayList>
            {days.map(day => (
              <DayItem current={isToday(parseISO(day.date))} key={day.date}>
                {`${format(parseISO(day.date), "ccc")} ${format(parseISO(day.date), t("common.date.noYear"))}`}
              </DayItem>
            ))}
          </DayList>
        </Hidden>
        <Hidden type="aboveLarge">
          <DayTabs>
            {days.map((day, index) => (
              <DayTab
                key={day.date}
                current={isToday(parseISO(day.date))}
                selected={index === selected}
                onClick={() => {
                  setSelected(days.indexOf(day));
                  onSelectChange(days.indexOf(day));
                }}
              >
                {`${format(parseISO(day.date), "ccc")} `}
                <Hidden type="belowTablet">{format(parseISO(day.date), t("common.date.noYear"))}</Hidden>
              </DayTab>
            ))}
          </DayTabs>
        </Hidden>
      </DayListWrapper>
      <Hidden type="belowLarge">
        <CalendarContent>
          {days.map((day, index) => (
            <CalendarColumn key={day.date} current={isToday(parseISO(day.date))}>
              {day.activities.length === 0 && <NoActivities>{t("patients.protocol.no_activities")}</NoActivities>}
              {day.activities.sort(sortActivities).map(activity => (
                <CalendarCard
                  key={`${day.date}_${activity.id}`}
                  openActivity={
                    activity.gqlId
                      ? () => navigate(`${match?.pathname}/${activity.contentType}/${generateUrlSuffix(activity)}`)
                      : undefined
                  }
                  responsive
                  title={activity.name}
                  subtitle={activity.part}
                  type={activity.contentType}
                  completed={
                    isSameDay(parseISO(activity.completedAt ?? ""), parseISO(day.date))
                      ? "same date"
                      : activity.completedAt
                  }
                  levels={activity.levels}
                  currentLevel={activity.currentLevel}
                  required={activity.required}
                  comment={activity.comment}
                  reply={activity.reply}
                  isPast={activity.isPast}
                  commentPlace={index < 3 ? "side-right" : "side-left"}
                  id={activity.id}
                  premium={activity.premium}
                />
              ))}
            </CalendarColumn>
          ))}
        </CalendarContent>
      </Hidden>
      <Hidden type="aboveLarge">
        {days[selected]?.activities.length === 0 ? (
          <NoActivities>{t("patients.protocol.no_activities")}</NoActivities>
        ) : (
          <ProtocolActivityGroup selected={selected} activities={days[selected]?.activities || []} isOverdue={false} />
        )}
      </Hidden>
    </Container>
  );
};

export default Calendar;
