import type { VFC } from "react";

import type { LegendProps } from "recharts";
import styled from "styled-components";

const CustomLegend: VFC<LegendProps> = ({ payload }) => (
  <CustomLegendList>
    {payload?.map(({ color, value }) => (
      <CustomLegendItem $color={color} key={value}>
        {value}
      </CustomLegendItem>
    ))}
  </CustomLegendList>
);

export default CustomLegend;

const CustomLegendList = styled.div`
  color: ${props => props.theme.colors.redesign.db80};
  display: flex;
  gap: 50px;
  margin: -20px 0 0 20px;
  font-size: 14px;
`;

type CustomLegendItemProps = {
  $color?: string;
};

const CustomLegendItem = styled.div<CustomLegendItemProps>`
  position: relative;

  &:before {
    background-color: ${props => props.$color};
    border-radius: 4px;
    content: "";
    font-size: 0.875rem;
    font-weight: 300;
    height: 16px;
    left: -24px;
    position: absolute;
    top: 0;
    width: 16px;
  }
`;
