import type React from "react";
import { useContext, useState } from "react";

import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import { useGetCurrentCourseQuery } from "api/hooks/useGetCurrentCourseQuery";
import type { Exercise } from "api/models/CurrentCourse";
import { PrimaryButton } from "shared/atoms/Button";
import Hidden from "shared/atoms/Hidden";
import Modal from "shared/atoms/Modal";
import Notification from "shared/atoms/Notification";
import Popup from "shared/atoms/Popup";
import Spinner from "shared/atoms/Spinner";
import { AnalyticsEvents, AnalyticsService } from "utils/analytics";
import { CurrentPatientContext } from "utils/contexts";

import DifficultyItem from "../DifficultyItem";
import useUpdateExerciseRecommendations from "../queries/useUpdateExerciseRecommendations";

interface Props {
  open: boolean;
  closeCallback: (close?: boolean) => void;
}

const ChangeLevels: React.VFC<Props> = ({ open, closeCallback }) => {
  const { t } = useTranslation();
  const { patient, previousTreatmentId } = useContext(CurrentPatientContext);
  const { patientId } = useParams<{ patientId: string }>();
  const [changedExercises, setChangedExercises] = useState<Record<number, number>>({});
  const [sendError, setSendError] = useState<string | null>(null);
  const updateExerciseRecommendations = useUpdateExerciseRecommendations();
  const { data, error, isLoading, refetch } = useGetCurrentCourseQuery({
    patientId: patient.id,
    treatment_id: previousTreatmentId,
  });

  const trackChangedExercises = () => {
    data?.exercises.forEach(exercise => {
      if (changedExercises[exercise.id]) {
        const levelZero = exercise.levels?.find(level => level.level === 0)?.id;

        if (changedExercises[exercise.id] === levelZero) {
          AnalyticsService.track(AnalyticsEvents.PATIENTS.REMOVE_EXERCISE, {
            patient: patientId,
            exercise: exercise.name,
          });
        } else {
          AnalyticsService.track(AnalyticsEvents.PATIENTS.CHANGE_LEVELS, {
            patient: patientId,
            exercise: exercise.name,
            oldLevel: exercise.level,
            newLevel: changedExercises[exercise.id],
          });
        }
      }
    });
  };

  const handleChange = (exerciseId: number, levelId: number) => {
    const clickedExercise = data?.exercises.find(exercise => exercise.id === exerciseId);
    const currentLevelId = clickedExercise?.levels.find(level => level.level === clickedExercise.level)?.id;

    if (currentLevelId !== levelId) {
      setChangedExercises({ ...changedExercises, [exerciseId]: levelId });
    } else if (changedExercises[exerciseId]) {
      const exercises = { ...changedExercises };
      delete exercises[exerciseId];
      setChangedExercises(exercises);
    }
  };

  const handleSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (Object.keys(changedExercises).length !== 0) {
      const changedExercisesIds = Object.keys(changedExercises).map(
        changedExercise => changedExercises[parseInt(changedExercise, 10)]
      );

      if (patientId)
        updateExerciseRecommendations
          .mutateAsync({ patientId, exercise_level_ids: changedExercisesIds })
          .then(() => {
            trackChangedExercises();
            setSendError(null);
            setChangedExercises({});
            refetch();
            closeCallback();
          })
          .catch(() => {
            setSendError(t("errors.generic"));
          });
    }
  };

  const checkIfChanged = (exercise: Exercise) => {
    if (changedExercises[exercise.id]) {
      return exercise.levels.find(level => level.id === changedExercises[exercise.id])?.level;
    }
    return exercise.level;
  };

  return (
    <div data-testid="change-levels-info">
      <Hidden type="belowTablet">
        <Popup fullBgOpacity showCloseButton onClickOutside={() => closeCallback()}>
          <Container>
            <TextWrapper>{t("patients.protocol.levels_description")}</TextWrapper>
            {!isLoading && !error && data ? (
              <>
                <Levels>
                  {data.exercises
                    .map(exercise => ({
                      ...exercise,
                      // FIXME: type translation
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      translatedName: t(`activities.exercise_${exercise.exercise_number}`),
                    }))
                    // FIXME: type translation
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    .sort((a, b) => a.translatedName.localeCompare(b.translatedName))
                    .map(exercise => (
                      <Level key={exercise.id}>
                        <DifficultyItem
                          exerciseId={exercise.id}
                          // FIXME: type translation
                          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          // @ts-ignore
                          label={t(`activities.exercise_${exercise.exercise_number}`)}
                          levelData={exercise.levels.map(({ id, level }) => {
                            return { id, level };
                          })}
                          currentLevel={checkIfChanged(exercise)}
                          levels={exercise.levels.length - 1}
                          onChange={(exerciseId, levelId) => handleChange(exerciseId, levelId)}
                          premium={exercise.premium}
                        />
                      </Level>
                    ))}
                </Levels>
                {sendError && <Notification type="danger">{sendError}</Notification>}
                <LevelsFooter>
                  <PrimaryButton
                    data-testid="protocol-exercise-levels-save"
                    disabled={Object.keys(changedExercises).length === 0}
                    onClick={handleSubmit}
                  >
                    {t("buttons.save")}
                  </PrimaryButton>
                </LevelsFooter>
              </>
            ) : (
              <LoadingContainer data-testid="no-data">
                {isLoading && <Spinner small />}
                {error && <Notification type="danger">{t("errors.generic")}</Notification>}
              </LoadingContainer>
            )}
          </Container>
        </Popup>
      </Hidden>
      <Hidden type="aboveTablet">
        <Modal title={t("patients.protocol.change_levels")} open={open} close={() => closeCallback()}>
          <PaddingWrapper>
            <TextWrapper>{t("patients.protocol.levels_description")}</TextWrapper>
            {!isLoading && !error && data ? (
              <>
                <Levels>
                  {data.exercises
                    .map(exercise => ({
                      ...exercise,
                      // FIXME: type translation
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      translatedName: t(`activities.exercise_${exercise.exercise_number}`),
                    }))
                    // FIXME: type translation
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    .sort((a, b) => a.translatedName.localeCompare(b.translatedName))
                    .map(exercise => (
                      <Level key={exercise.id} $modal>
                        <DifficultyItem
                          exerciseId={exercise.id}
                          // FIXME: type translation
                          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          // @ts-ignore
                          label={t(`activities.exercise_${exercise.exercise_number}`)}
                          levelData={exercise.levels.map(({ id, level }) => {
                            return { id, level };
                          })}
                          currentLevel={checkIfChanged(exercise)}
                          levels={exercise.levels.length - 1}
                          onChange={(exerciseId, levelId) => handleChange(exerciseId, levelId)}
                          premium={exercise.premium}
                        />
                      </Level>
                    ))}
                </Levels>
                {sendError && <Notification type="danger">{sendError}</Notification>}
                <LevelsFooter>
                  <PrimaryButton
                    disabled={Object.keys(changedExercises).length === 0}
                    onClick={event => handleSubmit(event)}
                  >
                    {t("buttons.save")}
                  </PrimaryButton>
                </LevelsFooter>
              </>
            ) : (
              <LoadingContainer data-testid="no-data">
                {isLoading && <Spinner small />}
                {error && <Notification type="danger">{t("errors.generic")}</Notification>}
              </LoadingContainer>
            )}
          </PaddingWrapper>
        </Modal>
      </Hidden>
    </div>
  );
};

export default ChangeLevels;

const Container = styled.div`
  width: 750px;
  box-sizing: border-box;
  min-height: 430px;
  padding: ${props => props.theme.spacing.S_15};
  display: flex;
  flex-direction: column;
`;

const PaddingWrapper = styled.div`
  padding: ${props => props.theme.spacing.S_20};
`;

const TextWrapper = styled.div`
  margin-bottom: ${props => props.theme.spacing.S_20};
  white-space: pre-wrap;
  ${props => props.theme.font.body1}
  color: ${props => props.theme.colors.greys.dark};
`;

const Levels = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  overflow: auto;
`;

const Level = styled.div<{ $modal?: boolean }>`
  flex: 1 1 ${props => (props.$modal ? "280px" : "300px")};
  min-width: ${props => (props.$modal ? "280px" : "300px")};
  height: 50px;
  flex-wrap: wrap;
  &:last-child {
    flex: 0 1 ${props => (props.$modal ? "760px" : "360px")};
    max-width: ${props => (props.$modal ? "760px" : "360px")};
  }
`;

const LevelsFooter = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;
