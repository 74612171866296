import type React from "react";
import { useContext, useEffect } from "react";

import { useTranslation } from "react-i18next";
import { useMatch, useNavigate } from "react-router-dom";
import styled from "styled-components";
import invariant from "ts-invariant";

import { useActivityLibraryEntries } from "api/hooks/useActivityLibraryEntries";
import type { ActivityLibraryEntry } from "api/schemas/ActivityLibraryEntry";
import type { Joint } from "api/types/GetActivityLibraryEntriesArgs";
import { useFeatureToggleContext } from "contexts/FeatureToggleContext";
import Notification from "shared/atoms/Notification";
import Spinner from "shared/atoms/Spinner";
import { AnalyticsPages, AnalyticsService } from "utils/analytics";
import { LibraryContext } from "utils/contexts";
import { Feature } from "utils/features/types";

import ActivityCard from "../components/ActivityCard";
import ActivityDivider from "../components/ActivityDivider";
import LibrarySearch from "../components/LibrarySearch";

const CATEGORIES = {
  show_all: "library.show_all",
  lesson: "library.textLessons",
  exercise: "library.exercises",
  functionality: "library.functionalTests",
};

const Library: React.VFC = () => {
  const match = useMatch("*");
  const navigate = useNavigate();
  const { category, jointFilter, search } = useContext(LibraryContext);
  const { hasFeature } = useFeatureToggleContext();
  const { t } = useTranslation();

  useEffect(() => {
    if (match) {
      AnalyticsService.viewedPage(AnalyticsPages.LIBRARY.MAIN, match.pathname);
    }
  }, [match?.pathname]);

  const getTargetJoints = (): Joint[] => {
    return jointFilter ? [jointFilter] : ["hip"];
  };

  const getEmptyText = () => {
    switch (jointFilter) {
      case "hand":
        return t("library.hand_no_functionality_activity");
      case "shoulder":
        return t("library.shoulder_no_functionality_activity");
      case "neck":
        return t("library.neck_no_functionality_activity");
      default:
        return t("library.no_activities");
    }
  };

  const isFunctionTestAvailable = () => {
    switch (jointFilter) {
      case "hip":
      case "knee":
      case "lower_back":
      case "upper_back":
        return true;
      default:
        return false;
    }
  };

  const { data, isLoading, error } = useActivityLibraryEntries({
    page: 0,
    page_size: 1000,
    target_joints: getTargetJoints(),
    types: ["FunctionalityTest", "TextLesson", "Exercise"],
    title_search: search,
  });

  const compareActivityCards = (a: ActivityLibraryEntry, b: ActivityLibraryEntry) => {
    const errorMsg = "Activity ID pattern mismatch";

    if (a.title === b.title) {
      const rA = a.id.match(/::(\d+)/);
      invariant(rA, errorMsg);
      const iA = parseInt(rA[1], 10);

      const rB = b.id.match(/::(\d+)/);
      invariant(rB, errorMsg);
      const iB = parseInt(rB[1], 10);
      return iA > iB ? 1 : -1;
    }
    return a.title > b.title ? 1 : -1;
  };

  const functionalTestTranslations = data?.filter(({ type }) => type === "FunctionalityTest");
  const exerciseTranslations = data?.filter(({ type }) => type === "Exercise")?.sort(compareActivityCards);
  const textLessonTranslations = data?.filter(({ type }) => type === "TextLesson")?.sort(compareActivityCards);

  return (
    <Wrapper data-testid="library-container" $extended={hasFeature(Feature.BACK_LIBRARY)}>
      <LibrarySearch categories={Object.values(CATEGORIES)} />

      {isLoading && (
        <CenterContainer data-testid="library-loading">
          <Spinner dataTestId="spinner" />
        </CenterContainer>
      )}

      {error && (
        <Notification type="danger">
          <span>{t("errors.loading_failed")}</span>
        </Notification>
      )}

      {!isLoading && !error && (
        <>
          {[CATEGORIES.show_all, CATEGORIES.lesson].includes(category) && (
            <>
              <ActivityDivider title={t("library.textLessons")} />
              <Container>
                {textLessonTranslations?.length ? (
                  textLessonTranslations.map(({ id, image_url, order, parts_count, title, type }) => {
                    const part =
                      Number(parts_count) > 1
                        ? t("library.lesson_part", { part: order, count: Number(parts_count) })
                        : null;

                    return (
                      <ActivityCard
                        key={id}
                        onClick={() => {
                          navigate(`lesson/${id}`);
                        }}
                        type={type}
                        title={title}
                        currentLevel={order}
                        part={part}
                        image={image_url}
                      />
                    );
                  })
                ) : (
                  <EmptyText>{t("library.no_activities")}</EmptyText>
                )}
              </Container>
            </>
          )}

          {[CATEGORIES.show_all, CATEGORIES.exercise].includes(category) && (
            <>
              <ActivityDivider title={t("library.exercises")} />
              <Container>
                {exerciseTranslations?.length ? (
                  exerciseTranslations.map(
                    ({ id, image_url, level, max_level, title, type, variation_title, premium }) => (
                      <ActivityCard
                        key={id}
                        onClick={() => {
                          navigate(`exercise/${id}`);
                        }}
                        type={type}
                        title={title}
                        variation={variation_title}
                        levels={max_level}
                        currentLevel={level}
                        image={image_url}
                        premium={premium}
                      />
                    )
                  )
                ) : (
                  <EmptyText>{t("library.no_activities")}</EmptyText>
                )}
              </Container>
            </>
          )}

          {[CATEGORIES.show_all, CATEGORIES.functionality].includes(category) && (
            <>
              <ActivityDivider title={t("library.functionalTests")} />
              <Container>
                {functionalTestTranslations?.length && isFunctionTestAvailable() ? (
                  functionalTestTranslations.map(({ id, image_url, title, type }) => (
                    <ActivityCard
                      key={id}
                      onClick={() => {
                        navigate(`functionality/${id}`);
                      }}
                      type={type}
                      title={title}
                      image={image_url}
                    />
                  ))
                ) : (
                  <EmptyText>{getEmptyText()}</EmptyText>
                )}
              </Container>
            </>
          )}
        </>
      )}
    </Wrapper>
  );
};

export default Library;

const Wrapper = styled.div<{ $extended: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CenterContainer = styled.div`
  width: 100vw;
  height: calc(100vh - (70px + 200px));
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  max-width: 990px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

const EmptyText = styled.span`
  ${props => props.theme.font.header4};
  color: ${props => props.theme.colors.greys.warm};
  flex: auto;
  text-align: center;
  margin-bottom: ${props => props.theme.spacing.S_30};
`;
