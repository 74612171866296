import type { DefaultTheme } from "styled-components";

// GREY BLUES
const DARK_GREY_BLUE = "#384a5f";
const GREY_BLUE = "#567291";
const LIGHT_GREY_BLUE = "#6585ab";

// REDS
const TOMATO = "#e32926";
const SALMON = "#fd7b7b";
const LIGHT_RED = "#ffe6e6";
const ERROR = "#ff5959";

// YELLOWS
const ORANGE = "#FA8D29";
const LIGHT_ORANGE = "#ffe7d1";
const BRIGHT_ORANGE = "#fff3E6";

// GREENS
const FEELING_GREEN = "#57cb75";
const LIGHT_GREEN = "#d5f2dc";

// PURPLES
const PLUM = "#9b4d86";

// BLUES
const ELECTRIC_BLUE = "#1274e3";
const DARK_SKY_BLUE = "#4a90e2";
const LIGHT_SKY_BLUE = "#479DFF";
const LIGHT_BLUE = "#daecff";
const ICE_BLUE = "#f7fbff";
const GREENY_BLUE = "#53b9b2";
const PAINFUL_BLUE = "#00c8ff";
const VOICE_CALL_CONTAINER = "#273546";

// GRAYSCALE
const WHITE = "#ffffff";
const BLACK = "#000000";
const SLATE_GREY = "#676d75";
const WARM_GREY = "#9b9b9b";
const PINKISH_GREY = "#c2c2c2";
const SILVER = "#dadfe2";
const LIGHT_GREY1 = "#e8e8e8";
const LIGHT_GREY2 = "#f2f2f2";
const LIGHT_GREY3 = "#f5f8fc";
const LIGHT_GREY4 = "#f8f8f8";
const LIGHT_GREY5 = "#ebebeb";
const LIGHT_GREY6 = "#f5f5f5";
const LIGHT_GREY7 = "#fbfbfb";
const IRON_GREY = "#8495a8";
const CONCRETE_GREY = "#e1e8ef";
const GUNMETAL_GREY = "#8d9bad";
const BLUISH_GREY = "#536479";
const DARKER_GREY = "#1c2126";

// Redesign
// DARK BLUES
// const DB100 = "#17222F";
const DB90 = "#273546";
const DB80 = "#3C4C60";
const DB70 = "#536479";
const DB60 = "#6F7F93";
const DB50 = "#8D9BAD";
const DB40 = "#B0BBC8";
const DB30 = "#D4DBE2";
const DB20 = "#E1E8EF";
const DB10 = "#E8EFF7";
const DB5 = "#F5F8FC";
// BLUES
const B100 = "#5096E8";
const B90 = "#61A0EA";
const B80 = "#73abed";
const B70 = "#84b5ee";
const B60 = "#96C0F1";
const B50 = "#a7caf3";
const B40 = "#B9D5F6";
const B30 = "#CADFF8";
const B20 = "#DCEAFA";
// const B15 = "#E4EFFB";
const B10 = "#edf4fc";
// ORANGES
// const O100 = "#E88950";
// const O90 = "#E78A51";
const O80 = "#eca073";
// const O70 = "#EEAC84";
const O60 = "#F1B896";
const O50 = "#F3C4A7";
const O40 = "#F5CFB9";
const O30 = "#F8DBCA";
const O20 = "#FAE7DC";
const O10 = "#fcf3ed";
// TEALS
// const T100 = "#60C2B5";
const T90 = "#70c8bc";
// const T80 = "#7FCEC3";
// const T70 = "#90D4CB";
const T60 = "#9FDAD2";
const T50 = "#AFE0DA";
const T40 = "#BFE6E1";
const T30 = "#CFECE8";
const T20 = "#DFF2F0";
const T10 = "#eff9f7";
// PURPLES
const P100 = "#7460C2";
// const P90 = "#8270C8";
const P80 = "#8f7fce";
// const P70 = "#9D90D4";
const P60 = "#AB9FDA";
// const P50 = "#B9AFE0";
const P40 = "#C7BFE6";
const P30 = "#D5CFEC";
const P20 = "#e3dff2";
const P10 = "#f1eff9";
// YELLOWS
const YTEXT = "#E7BD2F";
const Y100 = "#E8C550";
const Y90 = "#eaca61";
// const Y80 = "#ECD073";
// const Y70 = "#EED684";
const Y60 = "#F1DC96";
const Y50 = "#F3E2A7";
const Y40 = "#F5E7B9";
const Y30 = "#F8EDCA";
const Y20 = "#FAF3DC";
const Y10 = "#fcf9ed";
// GREENS
const G100 = "#60C286";
// const G90 = "#70C892";
const G80 = "#7FCE9E";
const G70 = "#90D4AA";
const G60 = "#9FDAB6";
const G50 = "#AFE0C2";
const G40 = "#BFE6CE";
const G30 = "#CFECDA";
const G20 = "#DFF2E6";
const G10 = "#EFF9F3";
// REDS
const R100 = "#E86550";
// const R90 = "#EA7461";
// const R80 = "#EC8373";
// const R70 = "#EE9384";
const R60 = "#F1A296";
// const R50 = "#F3B2A7";
const R40 = "#F5C1B9";
const R30 = "#F8D1CA";
const R20 = "#FAE0DC";
const R10 = "#FCEFED";
// GREYS
const NEW_GREY = "#A3AAB3";
const LIGHTEST_GREY = "#F8DBCA";
const DISABLED_GREY = "#F0F0F0";

// AVATAR COLORS
const COLOR_SWATCHES = [SALMON, "#EE508A", "#F5C674", FEELING_GREEN, "#53BBB4", PLUM, "#52E1FF", "#2F6DCA", "#4E53A4"];

const PAIN_LOCATION_COLORS = {
  knee: "#8A3CAF",
  hand: "#E9A727",
  back: "#3CAFAF",
  exclusion: "#F0757C",
  hip: DARK_GREY_BLUE,
  shoulder: "#8A0035",
  neck: "#2D6CC0",
};

// FONT WEIGHT
const FONT_WEIGHT_LIGHT = "300";
const FONT_WEIGHT_REGULAR = "400";
const FONT_WEIGHT_MEDIUM = "500";
const FONT_WEIGHT_BOLD = "700";

// BREAKPOINTS
const BREAKPOINT = "767";
const BREAKPOINT_MOBILE = "480";
const BREAKPOINT_LARGE = "992";
const BREAKPOINT_LIB = "550";

const theme: DefaultTheme = {
  colors: {
    primary: {
      base: DARK_GREY_BLUE,
      light: GREY_BLUE,
      lighter: LIGHT_GREY_BLUE,
      ice: ICE_BLUE,
      error: ERROR,
      lightError: R20,
    },
    secondary: {
      base: ELECTRIC_BLUE,
      light: DARK_SKY_BLUE,
      lighter: LIGHT_SKY_BLUE,
    },
    pastels: {
      green: LIGHT_GREEN,
      red: LIGHT_RED,
      orange: LIGHT_ORANGE,
      blue: LIGHT_BLUE,
    },
    redesign: {
      b10: B10,
      b20: B20,
      b30: B30,
      b40: B40,
      b50: B50,
      b60: B60,
      b70: B70,
      b80: B80,
      b90: B90,
      b100: B100,
      db5: DB5,
      db10: DB10,
      db20: DB20,
      db30: DB30,
      db40: DB40,
      db50: DB50,
      db60: DB60,
      db70: DB70,
      db80: DB80,
      db90: DB90,
      g10: G10,
      g20: G20,
      g30: G30,
      g40: G40,
      g60: G60,
      g100: G100,
      o10: O10,
      o20: O20,
      o30: O30,
      o40: O40,
      o60: O60,
      p10: P10,
      p20: P20,
      p30: P30,
      p40: P40,
      p60: P60,
      p100: P100,
      r10: R10,
      r20: R20,
      r30: R30,
      r40: R40,
      r60: R60,
      r100: R100,
      t10: T10,
      t20: T20,
      t30: T30,
      t40: T40,
      t60: T60,
      y10: Y10,
      y20: Y20,
      y30: Y30,
      y40: Y40,
      y60: Y60,
      disabled_grey: DISABLED_GREY,
    },
    exercise: {
      background: O10,
      accent: O80,
    },
    functionality: {
      background: Y10,
      accent: Y90,
    },
    lesson: {
      background: T30,
      accent: T90,
    },
    report: {
      background: P10,
      accent: P80,
    },
    payment: {
      background: B10,
      accent: B80,
    },
    update_payment_method: {
      background: B10,
      accent: B80,
    },
    weekly_report_and_health: {
      background: P20,
      accent: DB80,
    },
    green: FEELING_GREEN,
    orange: ORANGE,
    brightOrange: BRIGHT_ORANGE,
    white: WHITE,
    black: BLACK,
    tomato: TOMATO,
    salmon: SALMON,
    painful_blue: PAINFUL_BLUE,
    confirmation_green: G20,
    activity: {
      lesson: PLUM,
      report: GREENY_BLUE,
      functionality: ORANGE,
      exercise: SALMON,
      payment: DARK_SKY_BLUE,
      update_payment_method: DARK_SKY_BLUE,
    },
    graph: {
      goal: G80,
      pain: P80,
      function: Y100,
      functionText: YTEXT,
    },
    greens: {
      g70: G70,
    },
    greys: {
      dark: SLATE_GREY,
      darker: DARKER_GREY,
      pinkish: PINKISH_GREY,
      silver: SILVER,
      light1: LIGHT_GREY1,
      light2: LIGHT_GREY2,
      light3: LIGHT_GREY3,
      light4: LIGHT_GREY4,
      light5: LIGHT_GREY5,
      light6: LIGHT_GREY6,
      light7: LIGHT_GREY7,
      warm: WARM_GREY,
      slate: SLATE_GREY,
      iron: IRON_GREY,
      concrete: CONCRETE_GREY,
      gunmetal: GUNMETAL_GREY,
      bluish: BLUISH_GREY,
      newGrey: NEW_GREY,
      lightestGrey: LIGHTEST_GREY,
    },
    avatar_colors: COLOR_SWATCHES,
    voice_call_container: VOICE_CALL_CONTAINER,
    broadcast: {
      background: T30,
      hover: T20,
      accent: T90,
    },
    pain_location_colors: PAIN_LOCATION_COLORS,
    table_border_highlight: B80,
    system_suggestions_section: {
      background: T10,
      header_background: T20,
      divider: T50,
    },
    treatmentSwitching: {
      current: G30,
      previous: O30,
      current_bg: LIGHT_GREY4,
      previous_bg: O10,
    },
  },
  components: {
    calendar: {
      bg: WHITE,
      btn_bg: B100,
      btn_bg_hover: B90,
      btn_color: WHITE,
      daygrid_bg_today: B10,
      timegrid_bg_today: WHITE,
      col_header_bg: "none",
      col_header_bg_today: B100,
      col_header_color: BLACK,
      col_header_color_today: WHITE,
      toolbar_title_color: DB70,
      appointments: {
        digital_kick_off: B30,
        digital_kick_off_border: B50,
        digital_kick_off_availability: B10,
        physical_kick_off: O30,
        physical_kick_off_border: O50,
        physical_kick_off_availability: O10,
        digital_follow_up: G30,
        digital_follow_up_border: G50,
        digital_follow_up_availability: G10,
        physical_follow_up: Y30,
        physical_follow_up_border: Y50,
        physical_follow_up_availability: Y10,
        group: P20,
        group_border: P40,
        phone: DB30,
        phone_border: DB50,
        phone_availability: DB10,
        blocked: R40,
        blocked_border: R60,
        canceled: DB5,
        canceled_border: DB20,
        default: B20,
        default_border: B40,
      },
    },
  },
  spacing: {
    S_5: "5px",
    S_10: "10px",
    S_15: "15px",
    S_20: "20px",
    S_25: "25px",
    S_30: "30px",
    S_40: "40px",
    S_45: "45px",
    S_50: "50px",
    S_60: "60px",
    S_70: "70px",
    S_80: "80px",
    S_120: "120px",
    S_180: "180px",
  },
  borderRadius: {
    basic: "8px",
    circular: "50%",
    library: "12px",
  },
  font: {
    display1: {
      "line-height": "56px",
      "font-size": "48px",
      "font-weight": FONT_WEIGHT_MEDIUM,
    },
    display2: {
      "line-height": "48px",
      "font-size": "40px",
      "font-weight": FONT_WEIGHT_MEDIUM,
    },
    header1: {
      "line-height": "36px",
      "font-size": "30px",
      "font-weight": FONT_WEIGHT_MEDIUM,
    },
    header2: {
      "line-height": "32px",
      "font-size": "24px",
      "font-weight": FONT_WEIGHT_REGULAR,
    },
    header3: {
      "line-height": "30px",
      "font-size": "21px",
      "font-weight": FONT_WEIGHT_MEDIUM,
    },
    header4: {
      "line-height": "26px",
      "font-size": "18px",
      "font-weight": FONT_WEIGHT_REGULAR,
    },
    header5: {
      "line-height": "26px",
      "font-size": "16px",
      "font-weight": FONT_WEIGHT_REGULAR,
    },
    body1: {
      "line-height": "25px",
      "font-size": "18px",
      "font-weight": FONT_WEIGHT_LIGHT,
    },
    body2: {
      "line-height": "23px",
      "font-size": "16px",
      "font-weight": FONT_WEIGHT_LIGHT,
    },
    link1: {
      "line-height": "26px",
      "font-size": "18px",
      "font-weight": FONT_WEIGHT_MEDIUM,
    },
    link2: {
      "line-height": "26px",
      "font-size": "16px",
      "font-weight": FONT_WEIGHT_MEDIUM,
    },
    link3: {
      "line-height": "26px",
      "font-size": "14px",
      "font-weight": FONT_WEIGHT_MEDIUM,
    },
    caption: {
      "line-height": "22px",
      "font-size": "14px",
      "font-weight": FONT_WEIGHT_LIGHT,
    },
    caption2: {
      "line-height": "20px",
      "font-size": "12px",
      "font-weight": FONT_WEIGHT_LIGHT,
    },
    caption3: {
      "line-height": "18px",
      "font-size": "10px",
      "font-weight": FONT_WEIGHT_LIGHT,
    },
    info: {
      "line-height": "26px",
      "font-size": "16px",
      "font-weight": FONT_WEIGHT_LIGHT,
      color: SLATE_GREY,
    },
    app_version: {
      color: DB80,
      "font-size": "12px",
      "font-weight": FONT_WEIGHT_REGULAR,
      "letter-spacing": "0.25px",
      "line-height": "14px",
    },
    sub_menu: {
      link: {
        "line-height": "20px",
        "font-size": "14px",
        "font-weight": FONT_WEIGHT_MEDIUM,
      },
      title: {
        "line-height": "32px",
        "font-size": "14px",
        "font-weight": FONT_WEIGHT_MEDIUM,
      },
    },
    modal: {
      title: {
        "line-height": "46px",
        "font-size": "18px",
        "font-weight": FONT_WEIGHT_REGULAR,
        color: DARK_GREY_BLUE,
      },
    },
    table: {
      sort: {
        "line-height": "40px",
        "font-size": "14px",
        "font-weight": FONT_WEIGHT_MEDIUM,
        color: WARM_GREY,
      },
      header: {
        "line-height": "48px",
        "font-size": "16px",
        "font-weight": FONT_WEIGHT_REGULAR,
        color: SLATE_GREY,
      },
      row: {
        "line-height": "48px",
        "font-size": "14px",
        "font-weight": FONT_WEIGHT_REGULAR,
        color: SLATE_GREY,
      },
    },
    small_caps_title: {
      "line-height": "24px",
      "font-size": "20px",
      "font-weight": FONT_WEIGHT_BOLD,
      "letter-spacing": "1px",
      "font-variant": "small-caps",
    },
    button_text: {
      "line-height": "20px",
      "font-size": "14px",
      "font-weight": FONT_WEIGHT_BOLD,
      "letter-spacing": "1px",
      "text-transform": "uppercase",
    },
    section_title: {
      "line-height": "20px",
      "font-size": "14px",
      "font-weight": FONT_WEIGHT_MEDIUM,
      "letter-spacing": "1.25px",
      "text-transform": "uppercase",
      color: DB60,
    },
    autosave_label: {
      "line-height": "19px",
      "font-size": "16px",
      "font-weight": FONT_WEIGHT_REGULAR,
      color: DB60,
    },
    input: {
      label: {
        "font-size": "14px",
        "font-weight": FONT_WEIGHT_REGULAR,
      },
      label_standalone: {
        "font-size": "16px",
        "font-weight": FONT_WEIGHT_REGULAR,
        "line-height": "18px",
      },
      field: {
        "font-size": "16px",
        "line-height": "22px",
        "font-weight": FONT_WEIGHT_REGULAR,
      },
      error: {
        "font-size": "11px",
        "line-height": "15px",
        "font-weight": FONT_WEIGHT_REGULAR,
      },
    },
    message: {
      therapist_body: {
        "font-size": "16px",
        "line-height": "24px",
        "font-weight": FONT_WEIGHT_REGULAR,
        "letter-spacing": "0.5px",
      },
      patient_body: {
        "font-size": "16px",
        "line-height": "24px",
        "font-weight": FONT_WEIGHT_REGULAR,
        "letter-spacing": "0.5px",
      },
      date: {
        "font-size": "14px",
        "line-height": "20px",
        "letter-spacing": "0.5px",
      },
    },
    system_suggestions_section: {
      header: {
        color: DARK_GREY_BLUE,
        "font-size": "12px",
        "font-weight": FONT_WEIGHT_BOLD,
        "letter-spacing": "0.5px",
        "line-height": "20px",
        "text-transform": "uppercase",
      },
      label: {
        color: DB80,
        "font-size": "12px",
        "font-weight": FONT_WEIGHT_BOLD,
        "letter-spacing": "0.5px",
      },
      body: {
        color: DB80,
        "font-size": "12px",
        "font-weight": FONT_WEIGHT_REGULAR,
        "letter-spacing": "0.5px",
        "line-height": "20px",
        "white-space": "pre-line",
      },
    },
  },
  fontWeight: {
    light: FONT_WEIGHT_LIGHT,
    regular: FONT_WEIGHT_REGULAR,
    medium: FONT_WEIGHT_MEDIUM,
    bold: FONT_WEIGHT_BOLD,
  },
  breakpoint: BREAKPOINT,
  breakpointMobile: BREAKPOINT_MOBILE,
  breakpointLarge: BREAKPOINT_LARGE,
  breakpointLib: BREAKPOINT_LIB,
  belowBreakpoint: `@media screen and (max-width: ${BREAKPOINT}px)`,
  aboveBreakpoint: `@media screen and (min-width: ${BREAKPOINT}px)`,
  belowMobileBreakpoint: `@media screen and (max-width: ${BREAKPOINT_MOBILE}px)`,
  aboveMobileBreakpoint: `@media screen and (min-width: ${BREAKPOINT_MOBILE}px)`,
  belowLargeBreakpoint: `@media screen and (max-width: ${BREAKPOINT_LARGE}px)`,
  aboveLargeBreakpoint: `@media screen and (min-width: ${BREAKPOINT_LARGE}px)`,
  belowLibBreakpoint: `@media screen and (max-width: ${BREAKPOINT_LIB}px)`,
  aboveLibBreakpoint: `@media screen and (min-width: ${BREAKPOINT_LIB}px)`,
  containerWidth: 1020,
  videoCallWidthMaximised: 480,
  videoCallWidthMinimised: 240,
  zIndex: {
    bioPreview: 1002,
    videoCall: 990,
    videoCallMobile: 1010,
    modal: 997,
    headerMobile: 1001,
    header: 1000,
    headerDropdown: 999,
    headerDropdownOverlay: 998,
    belowMessage: 850,
    messageInput: 900,
    aboveMessage: 950,
    tooltip: 980,
    info: 500,
    overlay: 9999,
  },
};

export default theme;
