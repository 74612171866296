import type React from "react";
import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useLocation, useMatch, useNavigate } from "react-router-dom";
import styled from "styled-components";

import { BankIdLogo } from "assets";
import { PrimaryButton } from "shared/atoms/Button";
import Notification from "shared/atoms/Notification";
import { AnalyticsPages, AnalyticsService } from "utils/analytics";
import { useDevice } from "utils/hooks";
import { useStatuspage, useStatuspageBankId } from "utils/statuspage/hooks";
import { removeStorageValue } from "utils/storage";

import { BankIdDisturbancePopup } from "../components/BankID";
import { LoginContainer } from "../components/LoginContainer";
import { LoginInfoContainer } from "../components/LoginInfoContainer";
import { Container, LoginWrapper } from "../components/styledComponents";
import getSignedOutParams from "../utils/getSignedOutParams";

const Bankid: React.VFC = () => {
  const { t } = useTranslation();
  const match = useMatch("*");
  const navigate = useNavigate();
  const location = useLocation();
  const { signedOutError } = getSignedOutParams(location);
  const [showBankIdDisturbancePopup, setShowBankIdDisturbancePopup] = useState(false);
  const { blockBankIdLogin } = useStatuspageBankId();
  const { blockLogin } = useStatuspage();

  const { isMobileOrTablet } = useDevice();

  useEffect(() => {
    if (match) {
      AnalyticsService.viewedPage(AnalyticsPages.LOGIN.BANK_ID, match.pathname);
    }
  }, [match?.pathname]);

  const onSignIn = () => {
    setShowBankIdDisturbancePopup(blockBankIdLogin);

    if (!blockBankIdLogin) {
      const url = isMobileOrTablet ? "open-mobile-app" : "scan-qr";
      navigate({ pathname: url, search: location.search });
    }
  };

  useEffect(() => {
    // Cleanup to remove stored personal numbers
    // TODO: Remove this and the key after a while
    removeStorageValue("login-remember-pn");
  }, []);

  return (
    <LoginWrapper>
      <LoginContainer>
        <LoginInfoContainer title={t("login.bankid.title")} />

        <Container>
          <StyledPrimaryButton disabled={blockLogin} onClick={onSignIn}>
            {t("bankid.start_new")} <BankIdLogo />
          </StyledPrimaryButton>

          {signedOutError && (
            <Notification m="30px 0 0 0" type="danger">
              {signedOutError === "session_expired" ? t("errors.session_expired") : signedOutError}
            </Notification>
          )}
        </Container>

        {showBankIdDisturbancePopup && <BankIdDisturbancePopup setOpen={setShowBankIdDisturbancePopup} />}
      </LoginContainer>
    </LoginWrapper>
  );
};

export default Bankid;

const StyledPrimaryButton = styled(PrimaryButton)`
  margin-top: 90px;

  svg {
    height: auto;
    width: auto;
    margin-left: 8px;
  }
`;
