import type { ComponentType, ForwardRefExoticComponent, PropsWithoutRef, RefAttributes } from "react";
import { forwardRef } from "react";

import getDisplayName from "./getDisplayName";

const withDefaultProps = <Props, Type = unknown>(
  Component: ComponentType<Props>,
  defaults: Partial<Props>
): ForwardRefExoticComponent<PropsWithoutRef<Props> & RefAttributes<Type>> => {
  const WrappedComponent = forwardRef<Type, Props>((props, ref) => {
    return <Component {...defaults} {...props} ref={ref} />;
  });

  WrappedComponent.displayName = `WithDefaultProps(${getDisplayName(Component)})`;
  return WrappedComponent;
};

export default withDefaultProps;
