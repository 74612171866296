import type React from "react";
import { useState } from "react";

import styled from "styled-components";

import { CrossIcon, HelpIcon } from "assets";
import Hidden from "shared/atoms/Hidden";
import Tooltip from "shared/molecules/Tooltip";

interface Props {
  readonly children: React.ReactNode | HTMLElement;
  readonly label: string;
}

const SectionHelp: React.VFC<Props> = ({ children, label }) => {
  const [helpVisible, setHelpVisible] = useState(false);

  return (
    <HelpButton
      onClick={e => {
        setHelpVisible(!helpVisible);
        e.stopPropagation();
      }}
    >
      <HelpIcon />
      <Hidden type="belowMobile">
        <Tooltip requestClose={() => setHelpVisible(false)} isOpen={helpVisible} offset="side-right">
          {children}
        </Tooltip>
      </Hidden>

      <Hidden type="aboveMobile">
        <HelpContainerMobile $isOpen={helpVisible} onClick={e => e.stopPropagation()}>
          <HelpIcon />
          <CloseButton data-testid="close-help" onClick={() => setHelpVisible(false)}>
            <CrossIcon />
          </CloseButton>
          <HelpLabel>{label}</HelpLabel>
          {children}
        </HelpContainerMobile>
      </Hidden>
    </HelpButton>
  );
};

const CloseButton = styled.div`
  float: right;
  padding: 3px;
  cursor: pointer;
`;

const HelpButton = styled.div`
  position: relative;
  cursor: pointer;
`;

const HelpLabel = styled.div`
  margin-top: ${props => props.theme.spacing.S_15};
  margin-bottom: ${props => props.theme.spacing.S_10};
  ${props => props.theme.font.section_title};
`;

const HelpContainerMobile = styled.div<{ $isOpen: boolean }>`
  display: ${props => (props.$isOpen ? "block" : "none")};
  position: fixed;
  top: 0px;
  bottom: 0;
  left: 0;
  right: 0;
  padding: ${props => props.theme.spacing.S_30};
  padding-top: 100px;

  z-index: ${props => props.theme.zIndex.tooltip};
  background: rgba(23, 34, 47, 0.5);

  :after {
    content: "";
    position: fixed;
    top: 85px;
    bottom: ${props => props.theme.spacing.S_15};
    left: ${props => props.theme.spacing.S_15};
    right: ${props => props.theme.spacing.S_15};
    background: #fff;
    border-radius: ${props => props.theme.borderRadius.basic};
    z-index: -1;
  }
`;

export { SectionHelp };
