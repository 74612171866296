import type { VFC } from "react";

import styled from "styled-components";

import useLocalizedDate from "utils/date";

type DateSpanProps = {
  dataTestId?: string;
  fromDate: Date;
  toDate: Date;
};

const DateSpan: VFC<DateSpanProps> = ({ dataTestId, fromDate, toDate }) => {
  const { format, formats } = useLocalizedDate();
  const dateSpan = `${fromDate.getDate()} - ${format(toDate, formats.WRITTEN_LOCALIZED_DATE_MONTH_YEAR_dMMMyyyy)}`;

  return <DateSpanContainer data-testid={dataTestId}>{dateSpan}</DateSpanContainer>;
};

export default DateSpan;

const DateSpanContainer = styled.div`
  background-color: #fff;
  border: 2px solid ${props => props.theme.colors.greys.silver};
  color: ${props => props.theme.colors.greys.dark};
  min-width: 120px;
  padding: 8px 14px;
  text-align: center;
`;
