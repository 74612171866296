import type { VFC } from "react";

import styled from "styled-components";

export type ChartTableData = {
  label: string;
  value?: null | number | string;
};

type ChartTableProps = {
  dataTestId?: string;
  head: ChartTableData[];
  body: ChartTableData[];
};

const ChartTable: VFC<ChartTableProps> = ({ dataTestId, head, body }) => (
  <Container data-testid={dataTestId}>
    <Table>
      <thead>
        <Rows data={head} />
      </thead>
      <tbody>
        <Rows data={body} />
      </tbody>
    </Table>
  </Container>
);

export default ChartTable;

type RowsProps = {
  data: ChartTableData[];
};

const Rows: VFC<RowsProps> = ({ data }) => (
  <>
    {data.map(({ label, value }) => (
      <tr key={label}>
        <td>{label}</td>
        <ValueCell>{value}</ValueCell>
      </tr>
    ))}
  </>
);

const Container = styled.div`
  background-color: ${props => props.theme.colors.redesign.b10};
  border-radius: ${props => props.theme.borderRadius.basic};
  font-size: 14px;
  line-height: 20px;
  min-width: 245px;
  padding: 12px;
`;

const Table = styled.table`
  width: 100%;

  & > thead td {
    color: ${props => props.theme.colors.redesign.db80};
    font-weight: 600;
    padding: 0 0 8px;

    &:last-of-type {
      color: ${props => props.theme.colors.primary.base};
    }
  }

  & > tbody td {
    color: ${props => props.theme.colors.primary.base};
    font-weight: 300;
    padding: 8px 0 0;

    &:last-of-type {
      font-weight: 500;
      padding: 8px 0 0 8px;
    }
  }
`;

const ValueCell = styled.td`
  text-align: right;
`;
