import type { AxiosResponse } from "axios";

import { AuthClient } from "api";
import { getAuthTokens } from "utils/cookie";

const signOut = (): Promise<AxiosResponse<{ success: boolean }>> =>
  AuthClient.post("/sign_out", {
    refresh_token: getAuthTokens()?.refreshToken,
    id_token: getAuthTokens()?.idToken,
  });

export default signOut;
