import { z } from "zod";

import { BookingIntervalResponseSchema } from "./BookingInterval";

const PaymentMethodSchema = z.enum(["none", "bank_card", "on_site", "exemption_card", "free"]).nullable();
const PurposeSchema = z.enum(["discharge_call", "follow_up", "general", "kick_off"]);
const StateSchema = z.enum(["canceled", "completed", "no_show", "pending", "rescheduled"]).nullable();
const ProfessionSchema = z.enum([
  "occupational_therapist",
  "physical_therapist",
  "speech_therapist",
  "wellness_therapist",
]);

const HealthCareProfessionalSchema = z.object({
  id: z.number(),
  full_name: z.string(),
  profession: ProfessionSchema,
});

export const NewAppointmentSchema = z.object({
  id: z.number(),
  medium: z.enum(["face_to_face", "phone", "video"]),
  purpose: PurposeSchema,
  start_time: z.string(),
  end_time: z.string(),
  state: StateSchema,
  note: z.string().nullable().optional(),
  health_care_provider_service_id: z.number().nullable(),
  health_care_provider_service_name: z.string().nullable(),
  health_care_provider_service_mode: z.enum(["digital", "physical"]).nullable(),
  capacity: z.number().nullable(),
  cents: z.number().nullable(),
  currency: z.string().nullable(),
  payment_state: z.enum(["exempted", "paid", "unpaid"]).nullable(),
  payment_method: PaymentMethodSchema,
  health_care_professional: HealthCareProfessionalSchema,
  health_care_professional_id: z.number(),
  health_care_professional_full_name: z.string(),
  participants: z
    .object({
      id: z.number(),
      full_name: z.string().nullable(),
      phone_number: z.string().nullable(),
    })
    .array(),
});

export const AppointmentsResponseSchema = z.object({
  appointments: NewAppointmentSchema.array(),
  booking_intervals: BookingIntervalResponseSchema.array(),
});

export type PaymentMethod = z.infer<typeof PaymentMethodSchema>;
export type Purpose = z.infer<typeof PurposeSchema>;
export type State = z.infer<typeof StateSchema>;
export type Profession = z.infer<typeof ProfessionSchema>;
export type NewAppointment = z.infer<typeof NewAppointmentSchema>;
export type AppointmentsResponse = z.infer<typeof AppointmentsResponseSchema>;
