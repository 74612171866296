import type React from "react";

import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { animated, useSpring } from "react-spring";
import styled from "styled-components";

import { PrimaryButton, SecondaryButton } from "shared/atoms/Button";
import Hidden from "shared/atoms/Hidden";
import Notification from "shared/atoms/Notification";
import Tooltip from "shared/molecules/Tooltip";

interface Props {
  text: string;
  visible: boolean;
  close: () => void;
  onConfirm: () => void;
  error?: string | null;
  setError: (value: React.SetStateAction<string | null>) => void;
  dataTestId: string;
}

const ConfirmPopup: React.VFC<Props> = ({ text, close, visible, onConfirm, error, setError, dataTestId }) => {
  const { t } = useTranslation();
  const { handleSubmit } = useForm();

  const { animDisplay, transform } = useSpring({
    animDisplay: visible ? 1 : 0,
    transform: visible ? "translateX(0%)" : "translateX(100%)",
  });

  const onSubmit = async () => {
    try {
      onConfirm();
    } catch {
      setError(t("errors.generic"));
    }
  };

  const formComponent = () => {
    return (
      <FormContainer data-testid={`${dataTestId}_form`} onSubmit={handleSubmit(onSubmit)}>
        <TextContainer>
          {error ? <Notification type="danger">{error}</Notification> : <Text>{text}</Text>}
        </TextContainer>
        <ButtonContainer>
          <Hidden type="belowTablet">
            {error ? (
              <StyledPrimaryButton type="button" onClick={close}>
                {t("buttons.ok")}
              </StyledPrimaryButton>
            ) : (
              <>
                <SecondaryButton data-testid={`${dataTestId}-close-button`} type="button" onClick={close}>
                  {t("buttons.no")}
                </SecondaryButton>
                <StyledPrimaryButton data-testid={`${dataTestId}-button`} type="submit">
                  {t("buttons.yes")}
                </StyledPrimaryButton>
              </>
            )}
          </Hidden>
          <Hidden type="aboveTablet">
            <MobileButton type="button" onClick={close}>
              {error ? t("buttons.ok") : t("buttons.no")}
            </MobileButton>
            {!error && <UpperMobileButton type="submit">{t("buttons.yes")}</UpperMobileButton>}
          </Hidden>
        </ButtonContainer>
      </FormContainer>
    );
  };

  return (
    <>
      <Hidden type="belowTablet">
        <Tooltip requestClose={close} isOpen={visible} offset="top-right">
          {formComponent()}
        </Tooltip>
      </Hidden>
      <Hidden type="aboveTablet">
        <Wrapper style={{ transform, display: animDisplay.interpolate(displ => (displ === 0 ? "none" : "flex")) }}>
          {formComponent()}
        </Wrapper>
      </Hidden>
    </>
  );
};

export default ConfirmPopup;

const Wrapper = styled(animated.div)`
  padding: 0;
  width: auto !important;
  display: flex;
  flex: 1;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-left-width: 0 !important;
  z-index: ${props => props.theme.zIndex.aboveMessage};
  background-color: ${props => props.theme.colors.greys.light2};
  tr & {
    padding: 0;
  }
`;

const FormContainer = styled.form`
  display: flex;
  flex: 1;
  flex-direction: column;

  ${props => props.theme.belowBreakpoint} {
    flex-direction: row;
    height: 100%;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex: 0;
  min-width: 330px;
  ${props => props.theme.belowBreakpoint} {
    min-width: unset;
    flex: 1;
    align-items: center;
    padding: 0 ${props => props.theme.spacing.S_15};
  }
`;

const MobileButton = styled.button`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  width: 70px;
  background: transparent;
  color: ${props => props.theme.colors.primary.base};
  font-weight: ${props => props.theme.fontWeight.regular};
  border-radius: 0;
  border-width: 0;
  outline: none;
  &:hover {
    background: ${props => props.theme.colors.greys.light5};
    color: ${props => props.theme.colors.primary.base};
  }
  &:active {
    background: ${props => props.theme.colors.greys.light5};
    color: ${props => props.theme.colors.primary.base};
    border: none;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: ${props => props.theme.spacing.S_10} 0;

  ${props => props.theme.belowBreakpoint} {
    margin: 0;
    justify-content: unset;
    box-sizing: border-box;
    height: 100%;
    flex-direction: column-reverse;
    border-radius: 0;
    border: 0;
    border-left: 1;
    border-style: solid;
    border-color: ${props => props.theme.colors.greys.silver};
  }
`;

const UpperMobileButton = styled(MobileButton)`
  ${props => props.theme.belowBreakpoint} {
    margin: 0;
    border-bottom: 1px;
    border-style: solid;
    border-color: ${props => props.theme.colors.greys.silver};
  }
`;

const StyledPrimaryButton = styled(PrimaryButton)`
  margin-left: ${props => props.theme.spacing.S_10};
`;

const Text = styled.span`
  ${props => props.theme.font.info};
`;
