import type { VFC } from "react";
import { useEffect } from "react";

import { Route, Routes, useMatch, useNavigate } from "react-router-dom";

import { useProfileContext } from "contexts/ProfileContext";
import Container from "shared/atoms/Container";
import usePageContentOffsetTop from "shared/styles/usePageContentOffsetTop";
import { AnalyticsPages, AnalyticsService } from "utils/analytics";

import MonthlyReport from "./views/MonthlyReport";

const MonthlyReportRoutes: VFC = () => {
  const match = useMatch("*");
  const offsetTop = usePageContentOffsetTop();
  const { profile } = useProfileContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (!profile?.therapist_profile?.feature_flags?.includes("SHOW_METRICS_PAGE")) {
      navigate("/dashboard", { replace: true });
    }
  }, [profile]);

  useEffect(() => {
    if (match?.pathname) {
      AnalyticsService.viewedPage(AnalyticsPages.MONTHLY_REPORT, match.pathname);
    }
  }, [match]);

  return (
    <Routes>
      <Route
        path="/"
        element={
          <Container $pageContentOffsetTop={offsetTop}>
            <MonthlyReport />
          </Container>
        }
      />
    </Routes>
  );
};

export default MonthlyReportRoutes;
