import type React from "react";

import { ReactQueryDevtools } from "react-query/devtools";
import { Navigate, BrowserRouter as ReactRouter, Route, Routes } from "react-router-dom";

import CalendarRoutes from "routes/calendar";
import DashboardRoutes from "routes/dashboard";
import LibraryRoutes from "routes/library";
import LoginRoutes from "routes/login";
import MaintenanceRoutes from "routes/maintenance";
import MessagesRoutes from "routes/messages";
import MonthlyReportRoutes from "routes/monthly-report";
import NotificationsRoute from "routes/notifications";
import OnboardingRoutes from "routes/onboarding";
import PatientsRoutes from "routes/patients";
import SettingsRoutes from "routes/settings";
import SignupRoutes from "routes/signup";
import VideoCallRoute from "routes/videocall";
import VoiceCallRoute from "routes/voicecall";
import ProtectedRoute from "shared/ProtectedRoute";
import ContextProviders from "utils/contexts/ContextProviders";
import { isCypressRunning } from "utils/misc/isCypressRunning";
import { useStatuspageMaintenance } from "utils/statuspage/hooks";

const Router: React.VFC = () => {
  useStatuspageMaintenance();

  return (
    <ReactRouter>
      <ContextProviders>
        <Routes>
          <Route
            path="dashboard/*"
            element={
              <ProtectedRoute path="/dashboard">
                <DashboardRoutes />
              </ProtectedRoute>
            }
          />
          <Route
            path="patients/*"
            element={
              <ProtectedRoute path="/patients">
                <PatientsRoutes />
              </ProtectedRoute>
            }
          />
          <Route
            path="calendar/*"
            element={
              <ProtectedRoute path="/calendar">
                <CalendarRoutes />
              </ProtectedRoute>
            }
          />
          <Route
            path="messages/*"
            element={
              <ProtectedRoute path="/messages">
                <MessagesRoutes />
              </ProtectedRoute>
            }
          />
          <Route
            path="settings/*"
            element={
              <ProtectedRoute path="/settings">
                <SettingsRoutes />
              </ProtectedRoute>
            }
          />
          <Route
            path="monthly-report/*"
            element={
              <ProtectedRoute path="/monthly-report">
                <MonthlyReportRoutes />
              </ProtectedRoute>
            }
          />
          <Route
            path="library/*"
            element={
              <ProtectedRoute path="/library">
                <LibraryRoutes />
              </ProtectedRoute>
            }
          />
          <Route
            path="onboarding/*"
            element={
              <ProtectedRoute path="/onboarding">
                <OnboardingRoutes />
              </ProtectedRoute>
            }
          />

          <Route path="signup/*" element={<SignupRoutes />} />
          <Route path="login/*" element={<LoginRoutes />} />
          <Route path="maintenance/*" element={<MaintenanceRoutes />} />
          <Route path="*" element={<Navigate to="dashboard" />} />
        </Routes>
        <VideoCallRoute />
        <VoiceCallRoute />
        <NotificationsRoute />
        {!isCypressRunning() && <ReactQueryDevtools />}
      </ContextProviders>
    </ReactRouter>
  );
};

export default Router;
