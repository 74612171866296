import type React from "react";

import { useTranslation } from "react-i18next";

import { Data, DataWrapper, InfoGrid, Label, Progress, ProgressBar, TreatmentPaneWrapper } from "../../helpers";
import TreatmentDropdown from "../../TreatmentDropdown";

interface Props {
  adherence: number;
  week: number;
  started: string;
  isCurrentTreatment: boolean;
}

const TreatmentPane: React.VFC<Props> = ({ adherence, week, isCurrentTreatment, started }) => {
  const { t } = useTranslation();

  return (
    <TreatmentPaneWrapper $isCurrentTreatment={isCurrentTreatment}>
      <TreatmentDropdown />
      <InfoGrid>
        <>
          <Label>{t("patients.header_info.treatment_started")}</Label>
          <Data>{started}</Data>
        </>
        <>
          <Label>{t("patients.header_info.adherence")}</Label>
          <DataWrapper>
            <ProgressBar>
              <Progress $value={adherence} />
            </ProgressBar>
            <Data>{adherence}%</Data>
          </DataWrapper>
        </>
        <>
          <Label>{t("patients.header_info.treatment_week")}</Label>
          <Data>{week}</Data>
        </>
      </InfoGrid>
    </TreatmentPaneWrapper>
  );
};

export default TreatmentPane;
