import type React from "react";

import styled from "styled-components";

import Row from "shared/atoms/Row";

const StyledHeader = styled.tr`
  height: 48px;

  &:last-child > td {
    border-bottom-style: none;
  }
`;

const TH = styled.th`
  max-width: 1px;
  border-bottom: 1px solid ${props => props.theme.colors.greys.silver};
  padding: 0 ${props => props.theme.spacing.S_20};
  ${props => props.theme.belowMobileBreakpoint} {
    padding: 0 12px;
  }
`;

const Title = styled.h6`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${props => props.theme.font.table.header};
  margin: 0 ${props => props.theme.spacing.S_20} 0 0;
  ${props => props.theme.belowMobileBreakpoint} {
    margin: 0 ${props => props.theme.spacing.S_10} 0 0;
  }
`;

const Notifications = styled.h6`
  ${props => props.theme.font.header2};
  color: ${props => props.theme.colors.salmon};
  margin: 0;
`;

interface Props {
  colSpan?: number;
  title: string;
  notifications?: number;
  showZeroNotifications?: boolean;
  children?: React.ReactNode;
  justify?: "flex-start" | "flex-end" | "center" | "space-between" | "space-around";
}

const TableHeader: React.VFC<Props> = ({
  colSpan = 1,
  title,
  notifications = 0,
  showZeroNotifications = false,
  children = null,
  justify = undefined,
}) => {
  return (
    <StyledHeader>
      <TH colSpan={colSpan}>
        <Row align="center" justify={justify}>
          <Title>{title}</Title>
          {(showZeroNotifications || notifications > 0) && (
            <Notifications data-testid="notifications">{notifications}</Notifications>
          )}
          {children}
        </Row>
      </TH>
    </StyledHeader>
  );
};

export default TableHeader;
