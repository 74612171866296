import type React from "react";
import { useState } from "react";

import { useTranslation } from "react-i18next";
import styled from "styled-components";

import type { Goal } from "api/models/Goal";
import { useSavedInputsContext } from "contexts/SavedInputsContext";
import { TextButton } from "shared/atoms/Button";
import Notification from "shared/atoms/Notification";
import { deleteGoal } from "shared/molecules/PatientGoal/queries/setGoal";

import { Paragraph, ParagraphContent, ParagraphTitle } from "./Goal";

const StyledTextButton = styled(TextButton)`
  color: ${props => props.theme.colors.primary.error};
  justify-content: flex-start;
  height: 39px;
  padding: 0;
  font-weight: 500;
`;

const ErrorWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface Props {
  goal?: Partial<Goal>;
  patient: { id: number; most_painful_location?: { location: string } };
  patientId: string;
  setGoal: (goal: undefined | Partial<Goal>) => void;
  canDeleteGoal: boolean;
  notSet: string;
  success: boolean;
  isPhysicalFunctionAvailable: () => boolean;
  isFeelingAvailable: () => boolean;
  isPainAvailable: () => boolean;
  error?: Error | null;
}

const NonEditGoal: React.VFC<Props> = ({
  goal,
  patient,
  notSet,
  canDeleteGoal,
  patientId,
  setGoal,
  success,
  error,
  isPhysicalFunctionAvailable,
  isFeelingAvailable,
  isPainAvailable,
}) => {
  const { setSavedGoals } = useSavedInputsContext();
  const { t } = useTranslation();
  const [deleteError, setDeleteError] = useState<boolean>(false);

  const onDelete = async () => {
    if (goal?.id !== undefined) {
      const currentGoal = goal;
      try {
        setGoal(undefined);
        await deleteGoal(Number(patientId), goal.id);
        setDeleteError(false);
        setSavedGoals((goals: Record<string, Partial<Goal>>) => ({
          ...goals,
          [patient?.id]: undefined,
        }));
      } catch {
        setGoal(currentGoal);
        setDeleteError(true);
      }
    }
  };
  if (error || deleteError) {
    return (
      <ErrorWrapper>
        <Notification type="danger">{t("errors.generic")}</Notification>
      </ErrorWrapper>
    );
  }

  return (
    <>
      <Paragraph>
        <ParagraphTitle>{t("dashboard.tables.calls.goal.description")}</ParagraphTitle>
        <ParagraphContent data-testid="patient-goal-description">{goal?.free_text ?? notSet}</ParagraphContent>
      </Paragraph>
      {isPainAvailable() && (
        <Paragraph>
          <ParagraphTitle>{t("dashboard.tables.calls.goal.pain")}</ParagraphTitle>
          <ParagraphContent data-testid="patient-goal-pain">{goal?.pain ?? notSet}</ParagraphContent>
        </Paragraph>
      )}
      {isPhysicalFunctionAvailable() && (
        <Paragraph>
          <ParagraphTitle>{t("dashboard.tables.calls.goal.function")}</ParagraphTitle>
          <ParagraphContent data-testid="patient-goal-functionality">{goal?.functionality ?? notSet}</ParagraphContent>
        </Paragraph>
      )}
      {isFeelingAvailable() && (
        <Paragraph>
          <ParagraphTitle>{t("dashboard.tables.calls.goal.feeling")}</ParagraphTitle>
          <ParagraphContent data-testid="patient-goal-pain">{goal?.feeling ?? notSet}</ParagraphContent>
        </Paragraph>
      )}
      {success && <Notification type="success">{t("dashboard.tables.calls.goal.success")}</Notification>}
      {canDeleteGoal && (
        <StyledTextButton data-testid="delete-goal-button" type="button" onClick={onDelete}>
          {t("buttons.delete_goal")}
        </StyledTextButton>
      )}
    </>
  );
};

export default NonEditGoal;
