import type React from "react";
import { useState } from "react";

import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { CrossIcon, SidebarCollapseIcon } from "assets";
import { useAppConfigContext } from "contexts/AppConfigContext";
import { useProfileContext } from "contexts/ProfileContext";
import Row from "shared/atoms/Row";
import DropInCallPane from "shared/NavBar/components/DropInCallPane";
import DropInCallIndicator from "shared/NavBar/components/DropInCallPane/DropInCallIndicator";
import Hamburger from "shared/NavBar/components/Hamburger";
import Logo from "shared/NavBar/components/Logo";
import Menu from "shared/NavBar/components/Menu";

import Extras from "./components/Extras";

export const NAVBAR_WIDTH = 240;
export const NAVBAR_WIDTH_COLLAPSED = 56;
export const NAVBAR_HEIGHT = 56;

const NavBar: React.VFC<Record<string, never>> = () => {
  const [isOpenSideBar, setOpenSideBar] = useState(false);
  const { profile } = useProfileContext();
  const { navBarCollapsed, setNavBarCollapsed } = useAppConfigContext();
  const { t } = useTranslation();
  const showDropInCallPane = profile?.therapist_profile?.feature_flags?.includes("DROP_IN_CALLS_PUSH");

  const renderCollapseButtons = () => {
    return (
      <>
        {navBarCollapsed ? (
          <SidePanelCollapseWrapper $collapsed={navBarCollapsed} onClick={() => setNavBarCollapsed(false)}>
            <SidebarExpandIcon />
          </SidePanelCollapseWrapper>
        ) : (
          <SidePanelCollapseWrapper $collapsed={navBarCollapsed} onClick={() => setNavBarCollapsed(true)}>
            <CollapseLabel>{t("navbar.collapse")}</CollapseLabel>
            <SidebarCollapseIcon />
          </SidePanelCollapseWrapper>
        )}
      </>
    );
  };

  return (
    <Container $collapsed={navBarCollapsed}>
      {isOpenSideBar && (
        <Overlay
          onClick={event => {
            event.stopPropagation();
            setOpenSideBar(false);
          }}
        />
      )}
      <TopBar data-testid="top-bar">
        <TopBarItem>
          <IconButton
            onClick={event => {
              event.stopPropagation();
              setOpenSideBar(true);
            }}
          >
            <Hamburger open={isOpenSideBar} />
          </IconButton>
        </TopBarItem>
        <DropInCallIndicator
          onClick={event => {
            event.stopPropagation();
            setOpenSideBar(true);
          }}
        />
      </TopBar>
      <SideBar $collapsed={navBarCollapsed} $show={isOpenSideBar} data-testid="side-bar">
        <CloseButton
          onClick={event => {
            event.stopPropagation();
            setOpenSideBar(!isOpenSideBar);
          }}
        >
          <CrossIcon />
        </CloseButton>
        <Logo fullLogo={!navBarCollapsed} />
        {profile?.state === "ready" && (
          <MenuWrapper
            onClick={() => {
              setOpenSideBar(false);
            }}
          >
            <Menu />
          </MenuWrapper>
        )}
        {showDropInCallPane && <DropInCallPane collapsed={navBarCollapsed} />}
        <Extras collapsed={navBarCollapsed} isOpenSideBar={isOpenSideBar} setOpenSideBar={setOpenSideBar} />
        {renderCollapseButtons()}
      </SideBar>
    </Container>
  );
};

export default NavBar;

const Container = styled.div<{ $collapsed: boolean }>`
  ${props => props.theme.aboveBreakpoint} {
    margin-right: ${props => (props.$collapsed ? NAVBAR_WIDTH_COLLAPSED : NAVBAR_WIDTH)}px;
  }
`;

const SideBar = styled(Row)<{ $collapsed: boolean; $show: boolean }>`
  background-color: ${props => props.theme.colors.white};
  z-index: 99;
  display: flex;
  flex-direction: column;
  box-shadow: 1px 0px 12px rgba(56, 74, 95, 0.1);
  width: ${props => (props.$collapsed ? NAVBAR_WIDTH_COLLAPSED : NAVBAR_WIDTH)}px;
  flex-shrink: 0;
  box-sizing: border-box;
  transition: 0.2s;
  height: 100%;
  position: fixed;

  ${props => props.theme.belowBreakpoint} {
    left: -280px;
    left: ${props => props.$show && "0px"};
  }
`;

const TopBar = styled(Row)`
  ${props => props.theme.aboveBreakpoint} {
    display: none;
  }
  background-color: ${props => props.theme.colors.white};
  position: fixed;
  z-index: 99;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: ${NAVBAR_HEIGHT - 16}px;
  min-width: ${NAVBAR_HEIGHT - 16}px;
  margin: 8px;
  border-radius: 35px;
  box-shadow: 0px 10px 20px rgba(141, 155, 173, 0.3);
`;

const TopBarItem = styled(Row)`
  background-color: ${props => props.theme.colors.white};
  z-index: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: ${NAVBAR_HEIGHT - 8}px;
  min-width: ${NAVBAR_HEIGHT - 8}px;
  border-radius: 35px;
`;

const CloseButton = styled.div`
  position: absolute;
  top: 24px;
  right: 16px;
  ${props => props.theme.aboveBreakpoint} {
    display: none;
  }
`;

const Overlay = styled.div`
  position: fixed;
  z-index: 98;
  background: rgba(56, 74, 95, 0.35);
  backdrop-filter: blur(2px);
  width: 100%;
  height: 100%;
`;

const IconButton = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  &:hover {
    opacity: 0.7;
  }
`;

const SidePanelCollapseWrapper = styled.div<{ $collapsed: boolean }>`
  cursor: pointer;
  display: flex;
  position: sticky;
  z-index: 99;
  bottom: 0;
  padding-right: ${props => (props.$collapsed ? "16px" : "12px")};
  ${props => props.theme.belowBreakpoint} {
    display: none;
  }
  background-color: ${props => props.theme.colors.redesign.b10};
  height: 32px;
  line-height: 32px;
  justify-content: end;
  align-items: center;
  font-weight: 500;
`;

const CollapseLabel = styled.div`
  font-size: 12px;
  margin-right: 4px;
  color: ${props => props.theme.colors.redesign.b100};
`;

const SidebarExpandIcon = styled(SidebarCollapseIcon)`
  margin-left: 12px;
  transform: rotate(180deg);
`;

const MenuWrapper = styled.div`
  height: 100%;
`;
