import type { ChangeEvent } from "react";
import type React from "react";

import { Chip } from "@material-ui/core";
import { createFilterOptions } from "@material-ui/lab";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import MUIAutocomplete from "shared/atoms/inputs/MUIAutocomplete";
import { getFieldOptionKey } from "utils/medicalNotes";

interface Props {
  readonly error?: string;
  readonly label?: string;
  readonly name: string;
  readonly disabled?: boolean;
  readonly required?: boolean;
  readonly options: string[];
  // eslint-disable-next-line @typescript-eslint/ban-types
  readonly onChange?: (event: ChangeEvent<{}>) => void;
}

const MUIMultiselectAutocomplete: React.VFC<Props> = ({
  disabled,
  error,
  label,
  name,
  options,
  required,
  onChange,
}) => {
  const { setValue } = useFormContext();
  const { t } = useTranslation();

  return (
    <MUIAutocomplete
      disableCloseOnSelect
      disabled={disabled}
      error={error}
      label={label}
      name={name}
      options={options}
      required={required}
      onChange={(event, value) => {
        setValue(name, value, {
          shouldDirty: true,
          shouldValidate: true,
        });
        if (onChange) onChange(event);
      }}
      filterOptions={createFilterOptions({
        stringify: (option: string) => {
          // FIXME: type translation
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          return t(getFieldOptionKey(name, option)) as string;
        },
      })}
      // FIXME: type translation
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      renderOption={option => <MenuItem label={t(getFieldOptionKey(name, option))} name={name} option={option} />}
      renderTag={(valueArray, getTagProps) =>
        valueArray.map((value, i) => (
          <SelectedItem
            key={value}
            // FIXME: type translation
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            label={t(getFieldOptionKey(name, value))}
            name={name}
            option={value}
            tagProps={getTagProps({ index: i })}
          />
        ))
      }
    />
  );
};

interface MenuItemProps {
  readonly className?: string;
  readonly label: string;
  readonly name: string;
  readonly option: string;
}

interface SelectedItemProp {
  readonly label: string;
  readonly name: string;
  readonly option: string;
  readonly tagProps: Record<string, unknown>;
}

export const MenuItem: React.VFC<MenuItemProps> = ({ className, label, name, option }): JSX.Element => (
  <ItemContainer className={className}>
    <DescriptionContainer
      data-testid={`multiselect-dropdown-${name}-option-${option}`}
      onClick={e => e.preventDefault()}
    >
      {label}
    </DescriptionContainer>
  </ItemContainer>
);

export const SelectedItem: React.VFC<SelectedItemProp> = ({ label, name, option, tagProps }) => (
  <StyledChip
    variant="outlined"
    {...tagProps}
    label={
      <ItemContainer>
        <DescriptionContainer data-testid={`multiselect-dropdown-${name}-selected-option-${option}`}>
          {label}
        </DescriptionContainer>
      </ItemContainer>
    }
  />
);

const StyledChip = styled(Chip)`
  &.MuiChip-root {
    border-radius: ${props => props.theme.borderRadius.basic};
    margin: ${props => props.theme.spacing.S_5};
    margin-left: 0;
    border: none;
    background-color: ${props => props.theme.colors.greys.light2};

    .MuiChip-label {
      padding-left: ${props => props.theme.spacing.S_5};
      padding-right: ${props => props.theme.spacing.S_5};
    }
    .MuiChip-deleteIcon {
      pointer-events: all;
    }
  }
`;

const ItemContainer = styled.div`
  align-items: center;
  display: flex;
  font-size: ${props => props.theme.font.input.field["font-size"]};
  height: 30px;
`;

const DescriptionContainer = styled.div`
  padding: 2px 4px;
  white-space: nowrap;
  ${props => props.theme.belowBreakpoint} {
    white-space: normal;
  }
`;

export default MUIMultiselectAutocomplete;
