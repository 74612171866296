import type { ReactNode, VFC } from "react";

import styled from "styled-components";

type ChartBoxProps = {
  children: ReactNode;
  title: string;
};

const ChartBox: VFC<ChartBoxProps> = ({ children, title }) => (
  <ChartContainer>
    <ChartContainerTitle>{title}</ChartContainerTitle>
    {children}
  </ChartContainer>
);

export default ChartBox;

const ChartContainer = styled.div`
  background-color: #fff;
  border-radius: ${props => props.theme.borderRadius.basic};
  margin-bottom: 12px;
  padding: 16px;
`;

const ChartContainerTitle = styled.div`
  color: #676d75;
  font-size: 1rem;
  margin-bottom: 24px;
`;
