import type React from "react";
import { useContext } from "react";

import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { useGetMedicalReferrals } from "api/hooks/useGetMedicalReferrals";
import Notification from "shared/atoms/Notification";
import Spinner from "shared/atoms/Spinner";
import { CurrentPatientContext } from "utils/contexts";
import useLocalizedDate from "utils/date";

import ReferralNotes from "./ReferralNotes/ReferralNotes";
import ReferralValidBox from "./ReferralValidBox";

const ReferralsHandler: React.VFC = () => {
  const { patient } = useContext(CurrentPatientContext);
  const getMedicalReferralsQuery = useGetMedicalReferrals(patient.id);
  const { t } = useTranslation();
  const { add, format } = useLocalizedDate();

  if (getMedicalReferralsQuery.isLoading) {
    return (
      <Container data-testid="referral-loading">
        <Spinner />
      </Container>
    );
  }

  if (getMedicalReferralsQuery.isError) {
    return (
      <Container data-testid="referral-error">
        <Notification type="danger">
          <span>{t("errors.generic")}</span>
        </Notification>
      </Container>
    );
  }

  const hasReferralSigned =
    getMedicalReferralsQuery.data &&
    getMedicalReferralsQuery.data.length > 0 &&
    getMedicalReferralsQuery.data[0].date_of_physician_signature;

  if (hasReferralSigned) {
    const signedDate = getMedicalReferralsQuery.data[0].date_of_physician_signature;
    const durationDays = getMedicalReferralsQuery.data[0].duration_days;
    const expirationDate = add(new Date(signedDate), {
      days: durationDays,
    });
    const expirationDateFormatted = format(expirationDate, "yyyy-MM-dd");
    return (
      <Container data-testid="referral-valid-box">
        <ReferralValidBox referralExpiryDate={expirationDateFormatted} />
      </Container>
    );
  }

  return (
    <Container data-testid="referral-notes">
      <ReferralNotes onDone={getMedicalReferralsQuery.refetch} />
    </Container>
  );
};

const Container = styled.div`
  margin-bottom: ${props => props.theme.spacing.S_20};
  ${props => props.theme.belowBreakpoint} {
    margin: ${props => props.theme.spacing.S_10};
  }
`;

export default ReferralsHandler;
