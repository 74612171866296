import type React from "react";

import { useTranslation } from "react-i18next";
import styled from "styled-components";
import invariant from "ts-invariant";

import { usePutTherapistMutation } from "api/hooks/usePutTherapistMutation";
import { SolidInfoIcon } from "assets";
import { useProfileContext } from "contexts/ProfileContext";
import Notification from "shared/atoms/Notification";
import { Switch, SwitchLabel, SwitchWrapper } from "shared/atoms/Switch";

const AvailableToSubstituteSwitch: React.VFC = () => {
  const { profile, setProfile } = useProfileContext();
  const { t } = useTranslation();
  const putTherapistMutation = usePutTherapistMutation();

  invariant(profile);

  const isAvailableToSubstitute = Boolean(profile.therapist_profile.available_to_substitute);
  const isEnabled = isAvailableToSubstitute;

  const onSubmit = (availableToSubstitute: boolean) => {
    const therapistId = profile.id;
    const data = {
      therapist_profile: {
        available_to_substitute: availableToSubstitute,
      },
    };

    // Optimistically update the profile for immediate UI feedback.
    // Then onSuccess we update the profile with "real" server state.
    setProfile({
      ...profile,
      therapist_profile: {
        ...profile.therapist_profile,
        ...data.therapist_profile,
      },
    });

    const variables = { data, therapistId };
    putTherapistMutation.mutate(variables, {
      onSuccess(response) {
        setProfile(response);
      },
    });
  };

  return (
    <Container data-testid="available-to-substitute">
      <>
        <Wrapper>
          <Header data-testid="available-to-substitute-header">
            {t("therapist_settings.available_to_substitute.title")}
          </Header>
          <Tooltip>
            <SolidInfoIcon />
            <TooltipText>{t("therapist_settings.available_to_substitute.description")}</TooltipText>
          </Tooltip>
        </Wrapper>
      </>
      <SwitchWrapper>
        <Switch
          data-testid="available-to-substitute-switch"
          id="available-to-substitute-switch"
          type="checkbox"
          checked={isEnabled}
          onChange={() => {
            onSubmit(!isAvailableToSubstitute);
          }}
        />
        <SwitchLabel htmlFor="available-to-substitute-switch" />
      </SwitchWrapper>

      {putTherapistMutation.isError && (
        <Notification m="10px 0" type="danger">
          {t("errors.generic")}
        </Notification>
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: "row";
`;

const Header = styled.h5`
  ${props => ({ ...props.theme.font.header4 })}
  color: ${props => props.theme.colors.black};
  margin-right: ${props => props.theme.spacing.S_10};
`;

const Tooltip = styled.div`
  position: relative;
  display: inline-block;
  margin-top: ${props => props.theme.spacing.S_30};

  &:hover > div {
    visibility: visible;
  }
`;

const TooltipText = styled.div`
  visibility: hidden;
  ${props => ({ ...props.theme.font.caption })}
  width: 300px;
  background-color: ${props => props.theme.colors.greys.silver};
  /* background-color: #c0c0c0; */
  color: black;
  border-radius: 6px;
  padding: ${props => props.theme.spacing.S_10};
  white-space: normal;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;

  ${props => props.theme.belowMobileBreakpoint} {
    bottom: 110%;
    margin-left: -160px;
  }
`;

export default AvailableToSubstituteSwitch;
