import type { ConfigMarket, MedicalNoteConfigVersion } from "../types";

import DE from "./DE/2020-01-01.json";
import FR from "./FR/2020-01-01.json";
import GB1 from "./GB/2020-01-01.json";
import GB2 from "./GB/2022-09-29.json";
import GB3 from "./GB/2023-05-02.json";
import GB4 from "./GB/2024-07-03.json";
import GLOBAL from "./GLOBAL/2020-01-01.json";
import SE1 from "./SE/2020-01-01.json";
import SE2 from "./SE/2022-06-29.json";
import SE3 from "./SE/2022-09-01.json";
import SE4 from "./SE/2023-05-24.json";
import SE5 from "./SE/2023-08-31.json";
import SE6 from "./SE/2023-09-15.json";
import US from "./US/2020-01-01.json";

// versions of configs by market
const MedicalNoteConfigs: { [K in ConfigMarket]?: MedicalNoteConfigVersion[] } = {
  DE: [DE] as MedicalNoteConfigVersion[],
  FR: [FR] as MedicalNoteConfigVersion[],
  SE: [SE6, SE5, SE4, SE3, SE2, SE1] as MedicalNoteConfigVersion[],
  GB: [GB4, GB3, GB2, GB1] as MedicalNoteConfigVersion[],
  US: [US] as MedicalNoteConfigVersion[],
  GLOBAL: [GLOBAL] as MedicalNoteConfigVersion[],
};

export default MedicalNoteConfigs;
