import type React from "react";

import styled from "styled-components";

import { CancelIcon, CheckIcon, SolidInfoIcon, WarningIcon } from "assets";

import Card from "../Card";

const Icon = styled.svg`
  min-width: 30px;
  width: 30px;
  height: 20px;
`;

interface ContainerProps {
  readonly margin: string;
}

const Container = styled(Card)<ContainerProps>`
  box-sizing: border-box;
  min-height: 50px;
  margin: ${props => props.margin};
  padding: ${props => props.theme.spacing.S_10};
  justify-content: space-between;
  ${props => ({ ...props.theme.font.body1 })};
  background-color: ${props => props.theme.colors.pastels.blue};
`;

const TextWrapper = styled.div`
  flex-grow: 1;
  margin-left: ${props => props.theme.spacing.S_10};
`;

const Warning = styled(Container)`
  background-color: ${props => props.theme.colors.pastels.orange};
`;
const Danger = styled(Container)`
  background-color: ${props => props.theme.colors.pastels.red};
`;
const Success = styled(Container)`
  background-color: ${props => props.theme.colors.pastels.green};
`;

interface NotificationProps {
  readonly type?: "saved" | "success" | "warning" | "danger" | "info";
  readonly children: React.ReactNode;
  readonly m?: string;
  readonly className?: string;
  readonly align?: string;
}

const Notification: React.VFC<NotificationProps> = ({
  children,
  className,
  m = "0",
  type = "info",
  align = "center",
}) => {
  switch (type) {
    case "warning":
      return (
        <Warning data-testid="notification-warning" margin={m} className={className} align={align}>
          <Icon>
            <WarningIcon />
          </Icon>
          <TextWrapper>{children}</TextWrapper>
        </Warning>
      );
    case "danger":
      return (
        <Danger data-testid="notification-danger" margin={m} className={className} align={align}>
          <Icon>
            <CancelIcon />
          </Icon>
          <TextWrapper>{children}</TextWrapper>
        </Danger>
      );
    case "saved":
    case "success":
      return (
        <Success data-testid={`notification-${type}`} margin={m} className={className} align={align}>
          <Icon>
            <CheckIcon />
          </Icon>
          <TextWrapper>{children}</TextWrapper>
        </Success>
      );
    case "info":
    default:
      return (
        <Container data-testid="notification" margin={m} className={className} align={align}>
          <SolidInfoIcon />
          <TextWrapper>{children}</TextWrapper>
        </Container>
      );
  }
};

export default Notification;
