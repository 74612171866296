import type React from "react";
import { useState } from "react";

import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import invariant from "ts-invariant";

import type { HcpServiceManager } from "api/schemas/HcpService";
import { useProfileContext } from "contexts/ProfileContext";
import { convertMinutesToTime, generateArrayWithMinutesStep } from "routes/calendar/helpers/settingsHelpers";
import { PrimaryButton } from "shared/atoms/Button";
import { MUIDropdown } from "shared/atoms/inputs";
import useLocalizedDate from "utils/date";
import useLocale from "utils/date/useLocale";

import { END_TIME, START_TIME } from "../../../helpers/misc";
import AppointmentInterval from "../../AppointmentInterval";
import { Input, Wrapper } from "../../Forms/styles";
import { convertDateStringToObject, formDateToJSDates, generateOptions } from "../../helpers";
import { BOOKING_SCREENS } from "../AdminBookingForm";
import { Container } from "../misc";

import { Form } from "./CreatePatient";

interface Props {
  service?: HcpServiceManager;
  personnel: number | null;
  startTime?: string;
  setPersonnel: (personnel: number) => void;
  setPersonnelName: (personnelName: string) => void;
  setStartTime: (startTime: string) => void;
  setEndTime: (startTime: string) => void;
}

export const SelectDateAndPersonnel: React.VFC<Props> = ({
  service,
  personnel,
  startTime,
  setPersonnel,
  setPersonnelName,
  setStartTime,
  setEndTime,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const locale = useLocale();
  const { format } = useLocalizedDate();
  const [selectedStartTime] = useState<number>();
  const { profile } = useProfileContext();

  invariant(profile);

  const personnelOptions = service?.health_care_professionals
    ? generateOptions({
        array: service.health_care_professionals,
        labelKey: "full_name",
        valueKey: "id",
      })
    : [];

  const form = useForm<{ date: string; end_time: number; start_time: number; personnel: string }>({
    mode: "onSubmit",
    reValidateMode: "onChange",
    defaultValues: {
      date: new Date().toISOString().slice(0, 10),
      start_time: startTime ? convertDateStringToObject(startTime).startTime : undefined,
      end_time: startTime
        ? (convertDateStringToObject(startTime).startTime ?? 0) + (service?.duration ?? 0)
        : undefined,
      personnel: personnel ? personnel.toString() : undefined,
    },
  });

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = form;

  const onSubmit = handleSubmit(formData => {
    const { startDate, endDate } = formDateToJSDates({
      date: formData.date,
      startTime: formData.start_time,
      endTime: formData.end_time,
    });
    const startDateISOStr = startDate.toISOString();
    const endDateISOStr = endDate.toISOString();

    const selectedPersonnel = service?.health_care_professionals?.find(
      professional => professional.id === parseInt(formData.personnel, 10)
    );

    setPersonnel(parseInt(formData.personnel, 10));
    if (selectedPersonnel) setPersonnelName(selectedPersonnel.full_name);
    setStartTime(startDateISOStr);
    setEndTime(endDateISOStr);
    navigate(`../${BOOKING_SCREENS.SELECT_CONTACT_DETAILS}`);
  });

  const options = generateArrayWithMinutesStep(START_TIME, END_TIME, 5).map(option => {
    return { value: option, label: convertMinutesToTime(option) };
  });

  return (
    <Container>
      <FormProvider {...form}>
        <Form onSubmit={onSubmit} style={{ display: "flex", flexDirection: "column" }}>
          <Input className="date-and-time">
            <Controller
              name="date"
              control={control}
              render={({ field: { onChange, value } }) => (
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
                  <Wrapper>
                    <KeyboardDatePicker
                      autoOk
                      name="date"
                      id="date-picker"
                      variant="inline"
                      inputVariant="outlined"
                      margin="none"
                      format="yyyy-MM-dd"
                      minDate={new Date()}
                      value={value}
                      onChange={input => onChange(input ? format(input, "Y-MM-dd") : input)}
                      style={{ width: "100%" }}
                    />
                  </Wrapper>
                </MuiPickersUtilsProvider>
              )}
            />
          </Input>

          <Input>
            <AppointmentInterval
              options={options}
              setValue={setValue}
              getValues={getValues}
              selectedStartTime={selectedStartTime}
              duration={service?.duration}
              errors={errors}
            />
          </Input>

          <Input>
            <MUIDropdown
              label={t("booking.form.personnel")}
              name="personnel"
              options={personnelOptions}
              error={errors?.personnel && t("errors.string.empty")}
              required
            />
          </Input>

          <PrimaryButton style={{ alignSelf: "center" }}>{t("booking.buttons.continue")}</PrimaryButton>
        </Form>
      </FormProvider>
    </Container>
  );
};
