import type { VFC } from "react";

import { useTranslation } from "react-i18next";
import { Bar, BarChart, CartesianGrid, LabelList, Legend, ResponsiveContainer, XAxis, YAxis } from "recharts";
import styled from "styled-components";

import ChartCaption from "../ChartCaption";
import { chartMargin } from "../ChartStyles";
import type { ChartTableData } from "../ChartTable";
import ChartTable from "../ChartTable";
import CustomBarLabel from "../CustomBarLabel";
import CustomBarShape from "../CustomBarShape";
import CustomLegend from "../CustomLegend";

import type { PatientsChartData } from "./PatientsChartData";

type PatientsChartProps = {
  chartData: PatientsChartData[];
  patientNumbers: [ChartTableData[], ChartTableData[]];
};

type ValueAccessorProps = {
  payload: PatientsChartData;
};

const PatientsChart: VFC<PatientsChartProps> = ({ chartData, patientNumbers }) => {
  const { t } = useTranslation();

  return (
    <>
      {/* FIXME: type translation */}
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <ChartCaption>{t("monthly_report.your_patients.chart_caption")}</ChartCaption>
      <Content>
        <ChartContainer data-testid="patients-chart">
          <ResponsiveContainer width="100%" height={400}>
            <BarChart data={chartData} layout="vertical" margin={chartMargin}>
              <CartesianGrid strokeDasharray="4" horizontal={false} />
              <XAxis tickCount={8} type="number" />
              <YAxis dataKey="name" type="category" />
              <Legend content={<CustomLegend />} verticalAlign="top" />
              <Bar
                dataKey="active"
                fill="#57cb75"
                isAnimationActive={false}
                // FIXME: type translation
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                name={t("monthly_report.your_patients.active")}
                shape={<CustomBarShape />}
                stackId="1"
              >
                <LabelList
                  content={<CustomBarLabel />}
                  valueAccessor={({ payload }: ValueAccessorProps) => ({
                    amount: payload.active,
                  })}
                />
              </Bar>
              <Bar
                dataKey="inactive"
                fill="#fa8d29"
                isAnimationActive={false}
                // FIXME: type translation
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                name={t("monthly_report.your_patients.inactive")}
                shape={<CustomBarShape />}
                stackId="1"
              >
                <LabelList
                  content={<CustomBarLabel />}
                  valueAccessor={({ payload }: ValueAccessorProps) => ({
                    amount: payload.inactive,
                  })}
                />
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </ChartContainer>
        <ChartTableContainer>
          <ChartTable
            dataTestId="patients-chart-patient-numbers-table"
            head={patientNumbers[0]}
            body={patientNumbers[1]}
          />
        </ChartTableContainer>
      </Content>
    </>
  );
};

export default PatientsChart;

const Content = styled.div`
  display: flex;
  gap: 24px;

  ${props => props.theme.belowBreakpoint} {
    flex-direction: column;
  }
`;

const ChartContainer = styled.div`
  flex: 1;
  min-height: 300px;
`;

const ChartTableContainer = styled.div`
  ${props => props.theme.belowBreakpoint} {
    flex: 1;
  }
`;
