import type React from "react";
import { useContext, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import invariant from "ts-invariant";

import { getPatientCareEventsQueryKey, useGetPatientCareEventsQuery } from "api/hooks/useGetPatientCareEventsQuery";
import { getPTCareEventsQueryKey } from "api/hooks/useGetPTCareEventsQuery";
import type { CareEvent } from "api/models/CareEvent";
import { useProfileContext } from "contexts/ProfileContext";
import Pagination from "shared/atoms/Pagination";
import { Table, TableFooter, TableHeader, TableMissingContent } from "shared/molecules/Table";
import { CurrentPatientContext } from "utils/contexts";

import MedicalNote from "./components/MedicalNote";

const LIMIT = 5;

interface Props {
  $isFullWidthOnMobile?: boolean;
}

const SignNotesTable: React.VFC<Props> = ({ $isFullWidthOnMobile = false }) => {
  const { updateIsCreatable, activeCareEventNote } = useContext(CurrentPatientContext);
  const patientId = Number(useParams<Record<string, string>>().patientId);
  const { data: careEvents = [], isLoading, isSuccess } = useGetPatientCareEventsQuery({ patientId, signed: false });
  const { t } = useTranslation();
  const { profile } = useProfileContext();
  invariant(profile, "Profile context not set");
  const queryClient = useQueryClient();

  useEffect(() => {
    updateIsCreatable();
  }, [patientId]);

  const activeCareEvent = careEvents.find(ce => ce.id === activeCareEventNote);
  const totalLength = careEvents.length;
  const index = Math.min(totalLength - 1, Math.max(0, careEvents.indexOf(activeCareEvent as CareEvent)));

  useEffect(() => {
    const initialOffset = index - (index % LIMIT);
    setOffset(initialOffset);
  }, [index]);

  const [offset, setOffset] = useState(0);
  const careEventSubset = careEvents.slice(offset, offset + LIMIT);
  const hasNextPage = totalLength > offset + LIMIT;
  const hasPreviousPage = !!offset;

  // Cleanup. Invalidation to guarantee server state sync
  useEffect(
    () => () => {
      queryClient.invalidateQueries(getPatientCareEventsQueryKey({ patientId, signed: false }));
      queryClient.invalidateQueries(getPTCareEventsQueryKey({ userId: profile.id }));
    },
    [queryClient, patientId, profile]
  );

  return (
    <TableContainer>
      <Table overflow="visible" $isFullWidthOnMobile={$isFullWidthOnMobile}>
        <thead>
          <TableHeader
            colSpan={4}
            title={totalLength ? t("dashboard.tables.notes.title") : t("dashboard.tables.notes.no_notes")}
            notifications={totalLength}
          />
        </thead>
        <tbody>
          <TableMissingContent loading={isLoading} />
          {isSuccess && careEventSubset.map(careEvent => <MedicalNote {...careEvent} key={careEvent.id} />)}
        </tbody>
        {totalLength > LIMIT && (
          <tfoot>
            <TableFooter colSpan={4} addTopBorder>
              <Pagination
                totalCount={totalLength}
                first={offset + 1}
                last={Math.min(offset + LIMIT, totalLength)}
                pageInfo={{ hasNextPage, hasPreviousPage }}
                onPageChange={goTo => (goTo === "next" ? setOffset(offset + LIMIT) : setOffset(offset - LIMIT))}
              />
            </TableFooter>
          </tfoot>
        )}
      </Table>
    </TableContainer>
  );
};

const TableContainer = styled.div`
  margin: 32px 0;
`;

export default SignNotesTable;
