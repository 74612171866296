import sanitizeHtml from "sanitize-html";

const parseHTML = (dirty: string): ReturnType<typeof sanitizeHtml> => {
  return sanitizeHtml(dirty, {
    allowedTags: ["b", "i", "br", "ul", "li"],
    selfClosing: ["br"],
  });
};

export default parseHTML;
