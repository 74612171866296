import type { VFC } from "react";
import { useCallback, useMemo } from "react";

import { addMonths, differenceInCalendarMonths } from "date-fns";
import { useTranslation } from "react-i18next";
import { useMatch, useNavigate } from "react-router-dom";
import styled from "styled-components";
import invariant from "ts-invariant";

import { ChevronLeftIcon, ChevronRightIcon, MonthlyReportIcon } from "assets";
import { useProfileContext } from "contexts/ProfileContext";
import useLocalizedDate from "utils/date";
import { useQueryParams } from "utils/hooks";
import useYesNo from "utils/react/hooks/useYesNo";
import capitalize from "utils/string/capitalize";

import Button from "../components/Button";
import ButtonGroup from "../components/ButtonGroup";
import ChartStyles from "../components/ChartStyles";
import EarningsChartContainer from "../components/EarningsChart/EarningsChartContainer";
import PatientsChartContainer from "../components/PatientsChart/PatientsChartContainer";
import Rating, { getRatingChange } from "../components/Rating";
import WorkChartContainer from "../components/WorkChart/WorkChartContainer";
import { createYearMonthParams, getJsMonth, getYear } from "../utils/year-month-params";

const MonthlyReport: VFC = () => {
  const navigate = useNavigate();
  const match = useMatch("*");
  const params = useQueryParams();
  const { profile } = useProfileContext();
  const { t } = useTranslation();
  const { format, formats } = useLocalizedDate();

  const showPatientsChart = false; // Needs new API endpoint
  const showWorkChart = false; // Needs new API endpoint
  const showEarningsChart = true;

  invariant(profile);

  const currentRating = 0; // TODO Load from API
  const previousRating = 0; // TODO Load from API

  const jsMonth = getJsMonth(params);
  const year = getYear(params);
  const date = useMemo(() => new Date(year, jsMonth, 1), [year, jsMonth]);
  const isNextButtonDisabled = differenceInCalendarMonths(date, new Date()) >= 0;

  const navigateToPreviousMonth = useCallback(() => {
    navigateToMonth(addMonths(date, -1));
  }, [date]);

  const navigateToNextMonth = useCallback(() => {
    navigateToMonth(addMonths(date, 1));
  }, [date]);

  const navigateToMonth = (nextDate: Date) => {
    const query = createYearMonthParams(nextDate);
    const nextPath = `${match?.pathname}${query}`;

    navigate(nextPath);
  };

  return (
    <Container>
      <Header>
        <MonthlyReportIcon />
        <div>{t("monthly_report.page_title")}</div>
        <YearAndMonth>{capitalize(format(date, formats.WRITTEN_LOCALIZED_MONTH_YEAR_MMMMyyyy))}</YearAndMonth>
        <ButtonGroup>
          <Button $size="large" onClick={navigateToPreviousMonth}>
            <ChevronLeftIcon />
          </Button>
          <Button $size="large" disabled={isNextButtonDisabled} onClick={navigateToNextMonth}>
            <ChevronRightIcon />
          </Button>
        </ButtonGroup>
      </Header>

      <UserSummary>
        <Name>
          {profile.first_name} {profile.last_name}
        </Name>
        <AcceptingNewPatients>
          {t("monthly_report.accepting_new_patients")}:{" "}
          <span>{useYesNo(profile.therapist_profile.available_for_patients)}</span>
        </AcceptingNewPatients>
        {Boolean(currentRating && previousRating) && (
          <AverageRating>
            {/* FIXME: type translation */}
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            {t("monthly_report.average_rating")}{" "}
            <Rating change={getRatingChange(previousRating, currentRating)}>{currentRating}</Rating>
          </AverageRating>
        )}
      </UserSummary>

      {showPatientsChart && <PatientsChartContainer />}
      {showWorkChart && <WorkChartContainer />}
      {showEarningsChart && <EarningsChartContainer date={date} therapistId={profile.id} />}

      <ChartStyles />
    </Container>
  );
};

export default MonthlyReport;

const Container = styled.div`
  box-sizing: border-box;
  margin: 0 auto;
  max-width: 1020px;
  padding: 20px;
  width: 100%;
`;

const Header = styled.div`
  align-items: center;
  border-bottom: 1px solid #d4dbe2;
  color: ${props => props.theme.colors.redesign.db80};
  display: flex;
  font-size: 1.125rem;
  font-weight: 500;
  gap: 20px;
  margin-bottom: 12px;
  padding: 0 0 12px;
`;

const YearAndMonth = styled.div`
  color: ${props => props.theme.colors.primary.base};
  margin-left: auto;
  margin-right: 4px;
`;

const UserSummary = styled.div`
  align-items: center;
  color: ${props => props.theme.colors.redesign.db80};
  display: flex;
  font-size: 0.875rem;
  gap: 32px;
  margin-bottom: 12px;
  padding: 12px 0;

  ${props => props.theme.aboveMobileBreakpoint} {
    padding: 12px 16px;
  }
`;

const Name = styled.div`
  font-size: 1rem;
  font-weight: 500;
`;

const AcceptingNewPatients = styled.div`
  margin-left: auto;

  & > span {
    font-weight: 500;
  }
`;

const AverageRating = styled.div`
  align-items: center;
  display: flex;

  & > :first-child {
    margin-left: 8px;
  }
`;
