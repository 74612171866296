import type { AxiosResponse } from "axios";

import { isNewAuthSession } from "..";

import newSignOut from "./newSignOut";
import { refreshTokenIfNeeded } from "./refreshToken";
import oldSignOut from "./signOut";

const newOrOldSignOut = (): Promise<AxiosResponse<{ success: boolean }>> =>
  isNewAuthSession() ? newSignOut() : oldSignOut();

export { newOrOldSignOut as signOut, refreshTokenIfNeeded };
