import type React from "react";
import { useState } from "react";

import { useTranslation } from "react-i18next";
import styled, { keyframes } from "styled-components";

import { CloseIcon } from "assets";
import { PrimaryButton, SecondaryButton } from "shared/atoms/Button";
import Notification from "shared/atoms/Notification";

import type { ExtendedNotification as ExtendedNotificationProps } from "../../types";

const ExtendedNotification: React.VFC<ExtendedNotificationProps> = ({
  onClick,
  onClose,
  type = "info",
  message,
  buttonLabel,
}) => {
  const { t } = useTranslation();
  const [hide, setHide] = useState(false);
  const showButton = onClick && buttonLabel;

  const close = () => {
    setHide(true);
  };

  const onAnimationEnd = () => {
    if (hide && onClose) {
      onClose();
    }
  };

  return (
    <NotificationContainer $hide={hide} onAnimationEnd={onAnimationEnd}>
      <Notification type={type} data-testid="notification">
        <FlexContainer>
          <NotificationText>{message}</NotificationText>
          {showButton ? (
            <ButtonWrapper>
              <SmallSecondaryButton onClick={close} data-testid="close-button">
                {t("buttons.close")}
              </SmallSecondaryButton>
              <SmallPrimaryButton onClick={onClick} data-testid="cta-button">
                {buttonLabel}
              </SmallPrimaryButton>
            </ButtonWrapper>
          ) : (
            <>
              <IconButton onClick={close} data-testid="close-icon">
                <CloseIcon width={16} height={16} />
              </IconButton>
            </>
          )}
        </FlexContainer>
      </Notification>
    </NotificationContainer>
  );
};

export default ExtendedNotification;

const appear = keyframes`
  from {
    transform: translateY(-100px);
    opacity: 0;
  }

  to {
    transform: translateY(0px);
    opacity: 1;
  }
`;

const disappear = keyframes`
  from {
    transform: translateY(0px);
    opacity: 1;
  }

  to {
    transform: translateY(-100px);
    opacity: 0;
  }
`;

const NotificationContainer = styled.div<{ $hide: boolean }>`
  pointer-events: auto;
  min-width: 400px;
  max-width: ${props => props.theme.breakpoint}px;
  margin: ${props => props.theme.spacing.S_10} ${props => props.theme.spacing.S_10} ${props => props.theme.spacing.S_10}
    0;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: ${props => props.theme.borderRadius.basic};

  ${props => props.theme.belowBreakpoint} {
    min-width: 0;
    margin: 0;
  }

  animation: ${props => (props.$hide ? disappear : appear)} 0.3s ease;
`;

const FlexContainer = styled.div`
  display: flex;
`;

const NotificationText = styled.div`
  flex-grow: 1;
  align-self: center;
`;

const ButtonWrapper = styled.div`
  ${props => props.theme.belowBreakpoint} {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 6px;
  }
`;

const IconButton = styled.div`
  align-self: stretch;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0 8px 0 10px;
`;

const SmallPrimaryButton = styled(PrimaryButton)`
  height: 32px;
  font-size: 12px;
  border-radius: 16px;
  font-weight: normal;
  min-width: 100px;
  padding: ${props => props.theme.spacing.S_15} ${props => props.theme.spacing.S_20};
`;

const SmallSecondaryButton = styled(SecondaryButton)`
  height: 32px;
  font-size: 12px;
  border-radius: 16px;
  font-weight: normal;
  min-width: 100px;
  padding: ${props => props.theme.spacing.S_15} ${props => props.theme.spacing.S_20};
  margin: 0 ${props => props.theme.spacing.S_10};
`;
