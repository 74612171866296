import type React from "react";
import { useState } from "react";

import { animated, useSpring } from "react-spring";
import styled, { css } from "styled-components";

import ShowMore from "shared/atoms/ShowMore";
import useHeight from "utils/hooks/useHeight";

interface Props {
  readonly defaultOpen?: boolean;
  readonly print?: boolean;
  readonly header: React.ReactNode;
  readonly content: React.ReactNode;
  readonly buttonLabel?: string;
  readonly secondary?: boolean;
}

const Container = styled.div<Pick<Props, "secondary">>`
  border: 1px solid ${props => props.theme.colors.greys.silver};
  border-bottom: none;
  overflow: hidden;
  @media print {
    height: initial;
  }
  box-sizing: border-box;
  background: ${props => props.theme.colors.white};

  &:first-child {
    border-top-left-radius: ${props => props.theme.borderRadius.basic};
    border-top-right-radius: ${props => props.theme.borderRadius.basic};
  }
  &:last-child {
    border-bottom-left-radius: ${props => props.theme.borderRadius.basic};
    border-bottom-right-radius: ${props => props.theme.borderRadius.basic};
    border-bottom: 1px solid ${props => props.theme.colors.greys.silver};
  }
  ${({ secondary }) =>
    secondary &&
    css`
      position: relative;
      border-top: 1px solid ${props => props.theme.colors.greys.silver};
      border-bottom: 1px solid ${props => props.theme.colors.greys.silver};
      border-left: none;
      border-right: none;
      @media print {
        height: initial;
      }
      box-sizing: border-box;
      padding: ${props => props.theme.spacing.S_10} ${props => props.theme.spacing.S_20};
      background: ${props => props.theme.colors.greys.light4};

      &:first-child {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
      &:last-child {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom: none;
      }
    `}
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  justify-content: space-between;
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;
  padding: 0 16px;
`;

const Content = styled(animated.div)`
  overflow: hidden;
`;

const InnerContent = styled.div`
  padding: 20px 0 0;
`;

const PrintContent = styled(Content)`
  padding: ${props => props.theme.spacing.S_20} 0;
  display: none;
  @media print {
    display: block;
  }
`;

const Wrapper = styled.div`
  flex-shrink: 0;
`;

const AccordionItem: React.VFC<Props> = ({
  header,
  content,
  buttonLabel,
  print = false,
  defaultOpen = false,
  secondary = false,
}) => {
  const [open, setOpen] = useState(defaultOpen);
  const [heightRef, heightMax] = useHeight<HTMLDivElement>();
  const { animateVisibility, maxHeight } = useSpring({
    animateVisibility: open ? 1 : 0,
    maxHeight: open ? heightMax : 0,
  });

  return (
    <Container data-testid="accordion-item" secondary={secondary}>
      <Header data-testid="accordion-show-more" onClick={() => setOpen(!open)}>
        {header}
        <Wrapper>
          <ShowMore hideLabel="belowTablet" text={buttonLabel} onClick={() => setOpen(!open)} animated open={open} />
        </Wrapper>
      </Header>
      <Content
        style={{
          maxHeight,
          visibility: animateVisibility.interpolate(v => (v === 0 ? "hidden" : "visible")),
        }}
      >
        <InnerContent data-testid="accordion-content" ref={heightRef}>
          {content}
        </InnerContent>
      </Content>
      {print && <PrintContent>{content}</PrintContent>}
    </Container>
  );
};
export default AccordionItem;
