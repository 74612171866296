import type { VFC } from "react";

import { useTranslation } from "react-i18next";
import {
  // Bar,
  // BarChart,
  // CartesianGrid,
  Cell,
  // LabelList,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  // Tooltip,
  // XAxis,
  // YAxis,
} from "recharts";
import styled from "styled-components";

import ChartCaption from "../ChartCaption";
// import { chartMargin } from "../ChartStyles";
import type { ChartTableData } from "../ChartTable";
import ChartTable from "../ChartTable";
import CustomLegend from "../CustomLegend";

import type { PieChartData } from "./EarningsChartContainer";
import type { EarningsChartData } from "./EarningsChartData";
// import CustomBarShape from "../CustomBarShape";

type EarningsChartProps = {
  chartData: (EarningsChartData | undefined)[];
  monthlyEarnings: [ChartTableData[], ChartTableData[]];
  signedCareEvents?: [ChartTableData[], ChartTableData[]];
  unsignedCareEvents?: [ChartTableData[], ChartTableData[]];
  pieChartData?: PieChartData[] | undefined;
};

const EarningsChart: VFC<EarningsChartProps> = ({
  // chartData,
  monthlyEarnings,
  signedCareEvents,
  unsignedCareEvents,
  pieChartData,
}) => {
  const { t } = useTranslation();
  // const theme = useTheme();

  return (
    <Content>
      <ChartTableContainer>
        {signedCareEvents && (
          <ChartTable
            dataTestId="earnings-chart-signed-care-events-table"
            head={signedCareEvents[0]}
            body={signedCareEvents[1]}
          />
        )}
        {unsignedCareEvents && (
          <ChartTable
            dataTestId="earnings-chart-unsigned-care-events-table"
            head={unsignedCareEvents[0]}
            body={unsignedCareEvents[1]}
          />
        )}
        <ChartTable
          dataTestId="earnings-chart-monthly-earnings-table"
          head={monthlyEarnings[0]}
          body={monthlyEarnings[1]}
        />
      </ChartTableContainer>
      {/* TODO: Use bar charts when SE Ops give the green light */}
      <ChartContainer data-testid="earnings-chart">
        <ChartCaption>{t("monthly_report.your_earnings.chart_caption")}</ChartCaption>
        <ResponsiveContainer width="100%" height={400}>
          <PieChart>
            <Legend content={<CustomLegend />} verticalAlign="top" />
            <Pie
              data={pieChartData}
              dataKey="value"
              nameKey="name"
              cx="50%"
              cy="50%"
              label={({ value }) => {
                if (value > 0) {
                  return value;
                }
                return undefined;
              }}
              labelLine={false}
              isAnimationActive={false}
            >
              {pieChartData?.map((data, index) => (
                <Cell key={`cell-${data.name}`} fill={data.color} stroke={index === 0 ? "none" : "#fff"} />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>

        {/* TODO: Use bar charts when SE Ops give the green light */}
        {/* <ResponsiveContainer width="100%" height={600}> */}
        {/*  <BarChart data={chartData} layout="horizontal" margin={chartMargin}> */}
        {/*    <CartesianGrid strokeDasharray="4" vertical={false} /> */}
        {/*    <XAxis dataKey="label" type="category" /> */}
        {/*    <YAxis tickCount={8} type="number" /> */}
        {/*    <Tooltip */}
        {/*      cursor={{ stroke: theme.colors.redesign.db10, fill: theme.colors.redesign.db10, opacity: 0.5 }} */}
        {/*      contentStyle={{ borderRadius: theme.borderRadius.basic }} */}
        {/*    /> */}
        {/*    <Legend content={<CustomLegend />} verticalAlign="top" /> */}
        {/*    <Bar */}
        {/*      dataKey="careEvent" */}
        {/*      fill="#5096e8" */}
        {/*      isAnimationActive={false} */}
        {/*      name={t("monthly_report.your_earnings.care_event")} */}
        {/*      shape={<CustomBarShape />} */}
        {/*      stackId="1" */}
        {/*    /> */}
        {/*    <Bar */}
        {/*      dataKey="enrollmentBonus" */}
        {/*      fill="#70c8bc" */}
        {/*      isAnimationActive={false} */}
        {/*      name={t("monthly_report.your_earnings.enrollment_bonus")} */}
        {/*      shape={<CustomBarShape />} */}
        {/*      stackId="1" */}
        {/*    /> */}
        {/*    <Bar */}
        {/*      dataKey="selfCareBonus" */}
        {/*      fill="#eca073" */}
        {/*      isAnimationActive={false} */}
        {/*      name={t("monthly_report.your_earnings.selfcare_bonus")} */}
        {/*      shape={<CustomBarShape />} */}
        {/*      stackId="1" */}
        {/*    > */}
        {/*      <LabelList position="top" /> */}
        {/*    </Bar> */}
        {/*  </BarChart> */}
        {/* </ResponsiveContainer> */}
      </ChartContainer>
    </Content>
  );
};

export default EarningsChart;

const Content = styled.div`
  display: flex;
  gap: 24px;

  ${props => props.theme.belowBreakpoint} {
    flex-direction: column;
  }
`;

const ChartTableContainer = styled.div`
  & > :not(:first-child) {
    margin-top: 12px;
  }

  ${props => props.theme.belowBreakpoint} {
    flex: 1;
  }
`;

const ChartContainer = styled.div`
  flex: 1;
  min-height: 300px;
`;
