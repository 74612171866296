import type React from "react";
import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import styled from "styled-components";

import type { HcpServiceManager } from "api/schemas/HcpService";
import { PrimaryButton, TextButton } from "shared/atoms/Button";
import Notification from "shared/atoms/Notification";
import Spinner from "shared/atoms/Spinner";

interface Props {
  formState: string;
  formDisabled: boolean;
  service: HcpServiceManager | undefined;
  error: string | null;
  setFormDisabled: (value: boolean) => void;
  deleteService: () => void;
  resetChanges: () => void;
}

type ButtonsState = "default" | "service" | "edit" | "confirm_delete";

export const ServiceFormFooter: React.VFC<Props> = ({
  formState,
  formDisabled,
  service,
  error,
  setFormDisabled,
  deleteService,
  resetChanges,
}) => {
  const { t } = useTranslation();
  const [buttonsState, setButtonsState] = useState<ButtonsState>("default");
  const [deletion, setDeletion] = useState(false);

  useEffect(() => {
    if (service) {
      setButtonsState("service");
    } else {
      setButtonsState("default");
    }
    setDeletion(false);
  }, [service]);

  useEffect(() => {
    if (!formDisabled) {
      if (service) {
        setButtonsState("edit");
      } else setButtonsState("default");
    } else {
      setButtonsState("service");
    }
  }, [formDisabled, formState]);

  // form states - controlled by api outcomes
  const formFooter = () => {
    switch (formState) {
      case "saving": {
        return <Spinner style={{ width: 50, height: 50 }} />;
      }
      case "success": {
        return (
          // do not show success message on successful deletion
          !deletion && (
            <Notification m="0px" type="success">
              {service ? t("booking.services.successfully_updated") : t("booking.services.successfully_created")}
            </Notification>
          )
        );
      }
      default:
        return <Buttons>{renderButtons()}</Buttons>;
    }
  };

  // buttons states - controlled by buttons clicks
  const renderButtons = () => {
    switch (buttonsState) {
      case "confirm_delete": {
        return (
          <>
            <Notification m="0 0 40px 0" type="warning">
              {t("booking.services.confirm_deletion")}
            </Notification>
            <Buttons>
              <PrimaryButton
                id="confirm-delete-service"
                data-testid="confirm-delete-service"
                type="button"
                onClick={() => {
                  deleteService();
                  setDeletion(true);
                }}
              >
                {t("buttons.confirm")}
              </PrimaryButton>
              <StyledTextButton
                id="cancel-delete"
                data-testid="cancel-delete"
                type="button"
                onClick={() => setButtonsState("service")}
              >
                {t("buttons.cancel")}
              </StyledTextButton>
            </Buttons>
          </>
        );
      }
      case "service": {
        return (
          <>
            <PrimaryButton
              id="edit-service"
              data-testid="edit-service"
              type="button"
              onClick={event => {
                event.preventDefault();
                setFormDisabled(false);
              }}
            >
              {t("buttons.edit")}
            </PrimaryButton>
            <StyledTextButton
              id="detele-service"
              data-testid="etele-service"
              type="button"
              onClick={() => setButtonsState("confirm_delete")}
            >
              {t("buttons.delete")}
            </StyledTextButton>
          </>
        );
      }
      case "edit": {
        return (
          <>
            <PrimaryButton id="save-edit" data-testid="save-edit" type="submit">
              {t("buttons.save")}
            </PrimaryButton>
            <StyledTextButton id="cancel-edit" data-testid="cancel-edit" type="button" onClick={() => resetChanges()}>
              {t("buttons.cancel")}
            </StyledTextButton>
          </>
        );
      }
      default:
        return (
          <PrimaryButton id="save-service" data-testid="save-service" type="submit">
            {t("buttons.save")}
          </PrimaryButton>
        );
    }
  };

  return (
    <Container>
      {error && (
        <Notification m="0 0 40px 0" type="danger">
          {error}
        </Notification>
      )}
      {formFooter()}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 40px 0;
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledTextButton = styled(TextButton)`
  color: ${props => props.theme.colors.redesign.b100};
  margin-top: 20px;
`;
