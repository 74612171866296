import Cookies from "js-cookie";
import { z } from "zod";

const legacyHeadersSchema = z.object({
  accessToken: z.string(),
  tokenType: z.string(),
  uid: z.string(),
  client: z.string(),
});

type LegacyHeaders = z.infer<typeof legacyHeadersSchema>;

interface AuthTokens {
  idToken: string;
  refreshToken: string;
}

// expire number in days
const EXPIRE = 14;

// Private method
const setAuthCookies = (entries: { [key: string]: string | LegacyHeaders }) => {
  // indicating if the cookie transmission requires a secure protocol (https)
  const secure = process.env.REACT_APP_SECURE_COOKIES === "true";

  Object.entries(entries).forEach(entry => {
    const [key, value] = entry;

    Cookies.set(key, value, { expires: EXPIRE, secure });
  });
};

const setNewAuthCookies = (tokens: AuthTokens): void => {
  setAuthCookies({
    rja_auth_type: "new",
    rja_auth_id_token: tokens.idToken,
    rja_auth_refresh_token: tokens.refreshToken,
  });
};

const setLegacyAuthCookies = (headers: LegacyHeaders): void => {
  const result = legacyHeadersSchema.safeParse(headers);
  if (!result.success) {
    throw new Error("Missing value to set cookies");
  }

  setAuthCookies({
    rja_auth_type: "legacy",
    rja_auth_headers: headers,
  });
};

export default setLegacyAuthCookies;
export { setNewAuthCookies };
