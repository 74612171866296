import styled from "styled-components";

import { PrimaryButton } from "shared/atoms/Button";
import Search from "shared/molecules/Search";
import { TableCell } from "shared/molecules/Table";

import ButtonGroup from "../../../monthly-report/components/ButtonGroup";

const Title = styled.span`
  ${props => props.theme.font.header2};
  color: ${props => props.theme.colors.primary.base};
  flex-grow: 1;
  margin: auto;
`;
const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: ${props => props.theme.spacing.S_20} 0;
  ${props => props.theme.belowBreakpoint} {
    display: block;
    text-align: center;
  }
`;
const StyledSearch = styled(Search)`
  flex-grow: 2;
  ${props => props.theme.belowBreakpoint} {
    width: 100%;
  }
`;
const DurationText = styled.span`
  flex-grow: 1;
  line-height: 1px;
`;
const CellContainer = styled.div`
  display: flex;
  align-items: center;
`;
const ViewLink = styled.a`
  display: inline-flex;
  flex-grow: 2;
  border-radius: 28px;
  outline: none;
  cursor: pointer;
  padding: ${props => props.theme.spacing.S_15} ${props => props.theme.spacing.S_40};
  box-sizing: border-box;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-around;
  min-width: 86px;
  max-width: 86px;
  height: 35px;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
  ${props => props.theme.font.button_text};
  background-color: ${props => props.theme.colors.redesign.b100};
  color: ${props => props.theme.colors.white};
  ${props => props.theme.font.button_text};
  ${props => props.theme.aboveBreakpoint} {
    margin: auto 0 auto auto;
  }
  text-decoration: none;
`;
const StyledTableCell = styled(TableCell)`
  font-weight: 400;
  color: ${props => props.theme.colors.greys.slate};
`;

const Dot = styled.div<{ $deleted: boolean }>`
  width: 8px;
  height: 8px;
  background-color: ${props => (props.$deleted ? props.theme.colors.salmon : props.theme.colors.green)};
  margin-right: 8px;
  border-radius: 50%;
`;

const StyledPrimaryButton = styled(PrimaryButton)`
  margin-left: 20px;
  min-width: 100px;
  padding: 0 24px;
  height: 48px;
`;

const Container = styled.div`
  display: flex;
  ${props => props.theme.belowBreakpoint} {
    justify-content: center;
  }
`;

const StyledButtonGroup = styled(ButtonGroup)`
  justify-content: flex-end;

  ${props => props.theme.belowBreakpoint} {
    margin-top: 20px;
  }
`;

const Counter = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
  color: ${props => props.theme.colors.greys.slate};
`;

export {
  Title,
  HeaderContainer,
  StyledSearch,
  DurationText,
  CellContainer,
  ViewLink,
  StyledTableCell,
  Dot,
  StyledPrimaryButton,
  Container,
  StyledButtonGroup,
  Counter,
};
