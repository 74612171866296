import type { ReactNode, VFC } from "react";

import styled from "styled-components";

import { ArrowDownIcon, ArrowRightIcon, ArrowUpIcon } from "assets";

const RED = "red";
const GREEN = "green";
const BLUE = "blue";

type RatingColor = typeof RED | typeof GREEN | typeof BLUE;

const DECREASE = "decrease";
const INCREASE = "increase";
const NEUTRAL = "neutral";

type RatingChange = typeof DECREASE | typeof INCREASE | typeof NEUTRAL;

type RatingProps = {
  change: RatingChange;
  children: ReactNode;
};

const Rating: VFC<RatingProps> = ({ children, change }) => {
  const Icon = getIcon(change);
  const color = getColor(change);

  return (
    <Pill $color={color}>
      <Icon />
      {children}
    </Pill>
  );
};

const getIcon = (change: RatingChange) => {
  switch (change) {
    case DECREASE:
      return ArrowDownIcon;
    case INCREASE:
      return ArrowUpIcon;
    case NEUTRAL:
    default:
      return ArrowRightIcon;
  }
};

const getColor = (change: RatingChange): RatingColor => {
  switch (change) {
    case DECREASE:
      return RED;
    case INCREASE:
      return GREEN;
    case NEUTRAL:
    default:
      return BLUE;
  }
};

export const getRatingChange = (previous: number, current: number): RatingChange => {
  if (previous > current) {
    return DECREASE;
  }
  if (previous < current) {
    return INCREASE;
  }
  return NEUTRAL;
};

export default Rating;

type PillProps = {
  $color: RatingColor;
};

// TODO The red color is missing
const Pill = styled.div<PillProps>`
  ${props => props.$color === RED && "background-color: #73abed;"}
  ${props => props.$color === GREEN && "background-color: #7fce9e;"}
  ${props => props.$color === BLUE && "background-color: #73abed;"}

  border-radius: ${props => props.theme.borderRadius.basic};
  color: #fff;
  padding: 2px 8px;

  & > :first-child {
    margin-right: 8px;
  }
`;
