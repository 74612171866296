import { useEffect } from "react";

import { faCalendarCheck, faClock, faUser } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled, { useTheme } from "styled-components";
import invariant from "ts-invariant";

import type { Form, SelectedDate } from "contexts/CalendarContext";
import { useCalendarContext } from "contexts/CalendarContext";
import { useProfileContext } from "contexts/ProfileContext";
import Spinner from "shared/atoms/Spinner";
import { userIsAdmin } from "utils/profile/profileHelper";

import type { SetSelectArgs } from "../Calendar/Calendar";

import { AvailabilityForm } from "./AvailabilityForm";
import { BookAppointmentForm } from "./BookAppointmentForm";
import { Header } from "./Header";

interface Props {
  children?: React.ReactNode;
  defaultServiceID: number | undefined;
  selectedDate?: SelectedDate | null;
  onCloseBookingForm: () => void;
  refetchAppointments?: () => void;
  setSelect?: (args: SetSelectArgs) => void;
}

export const FormsContainer: React.VFC<Props> = ({
  children,
  defaultServiceID,
  selectedDate,
  onCloseBookingForm,
  refetchAppointments,
  setSelect,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const theme = useTheme();
  const { profile } = useProfileContext();
  const {
    activeForm,
    setActiveForm,
    refreshForm,
    setRefreshForm,
    selectedAppointment,
    selectedBookingInterval,
    selectedPatient,
  } = useCalendarContext();

  invariant(profile);

  const isAdmin = userIsAdmin(profile);

  useEffect(() => {
    return () => {
      setActiveForm(null);
    };
  }, []);

  useEffect(() => {
    if (refreshForm) {
      setTimeout(() => {
        setRefreshForm(false);
      }, 200);
    }
  }, [refreshForm]);

  const onClick = (form: Form) => {
    setActiveForm(form);

    if (form === "create_patient") {
      navigate("book/create_patient");
      return;
    }

    navigate("/calendar");
  };

  const onClose = () => {
    onCloseBookingForm();
    navigate("/calendar");
  };

  if (!activeForm) {
    return <></>;
  }

  const renderForm = () => {
    switch (activeForm) {
      case "availability":
        return (
          <AvailabilityForm
            selectedBookingInterval={selectedBookingInterval}
            selectedDate={selectedDate}
            onClose={onCloseBookingForm}
            refetchAppointments={refetchAppointments}
            setSelect={setSelect}
          />
        );

      case "book_appointment":
        return (
          <BookAppointmentForm
            defaultServiceID={defaultServiceID}
            event={selectedAppointment}
            selectedDate={selectedDate}
            selectedPatient={selectedPatient}
            onClose={onCloseBookingForm}
            refetchAppointments={refetchAppointments}
            setSelect={setSelect}
          />
        );

      case "create_patient":
        return <></>;
      default:
        return <></>;
    }
  };

  return (
    <Container>
      <Header header={t("booking.forms.header")} onClose={onClose} />

      <FormSelector>
        <FormOption $active={activeForm === "book_appointment"} onClick={() => onClick("book_appointment")}>
          <StyledFontAwesomeIcon icon={faCalendarCheck} color={theme.colors.redesign.b100} />
          {t("booking.forms.book_appointment")}
        </FormOption>

        {isAdmin && (
          <FormOption
            $active={activeForm === "create_patient"}
            onClick={() => {
              onClick("create_patient");
            }}
          >
            <StyledFontAwesomeIcon icon={faUser} color={theme.colors.redesign.b100} />
            {t("booking.forms.create_patient")}
          </FormOption>
        )}

        <FormOption $active={activeForm === "availability"} onClick={() => onClick("availability")}>
          <StyledFontAwesomeIcon icon={faClock} color={theme.colors.redesign.b100} />
          {t("booking.forms.availability")}
        </FormOption>
      </FormSelector>

      {!refreshForm ? (
        <FormContainer>
          {renderForm()}
          {children}
        </FormContainer>
      ) : (
        <Spinner />
      )}
    </Container>
  );
};

const Container = styled.section`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 400px;
  background: #fff;
  position: sticky;
  top: 0;

  ${props => props.theme.belowBreakpoint} {
    height: auto;
    width: auto;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
    z-index: 999;
  }
`;

const FormSelector = styled.div`
  display: flex;
  margin-bottom: 32px;
  padding: 0 16px;
`;

const FormOption = styled.div<{ $active: boolean }>`
  display: flex;
  padding: 4px 6px;
  color: ${({ theme, $active }) => ($active ? theme.colors.redesign.b100 : theme.colors.redesign.db90)};
  background: ${({ theme, $active }) => ($active ? theme.colors.redesign.b10 : theme.colors.white)};
  border-radius: 4px;
  cursor: pointer;
  pointer-events: ${({ $active }) => ($active ? "none" : "auto")};

  &:not(:last-of-type) {
    margin-right: 8px;
  }

  svg {
    path {
      fill: ${({ theme, $active }) => ($active ? theme.colors.redesign.b100 : theme.colors.redesign.db50)};
    }
  }

  &:hover {
    color: ${({ theme }) => theme.colors.redesign.b100};

    svg {
      path {
        fill: ${({ theme }) => theme.colors.redesign.b100};
      }
    }
  }
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  height: 16px;
  width: 16px;
  margin-right: 8px;
`;

const FormContainer = styled.div`
  display: flex;
  flex: 1;
  overflow: auto;

  ${props => props.theme.belowBreakpoint} {
    overflow: initial;
  }
`;
