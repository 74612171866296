import { useEffect, useState } from "react";

import { faCircleMinus, faCirclePlus } from "@fortawesome/pro-regular-svg-icons";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { useGetUserCourseLibraryExercisesQuery } from "api/hooks/useGetUserCourseLibraryExercises";
import type { UserCourseLibraryExercise, UserCourseLibraryExercises } from "api/schemas/UserCourse";
import { CloseIcon, SearchIcon } from "assets";
import { PrimaryButton } from "shared/atoms/Button";
import Hidden from "shared/atoms/Hidden";
import IconButton from "shared/atoms/IconButton";
import Notification from "shared/atoms/Notification";
import Spinner from "shared/atoms/Spinner";
import Search from "shared/molecules/Search";

import DropDown from "./DropDown";
import ExercisePickerItem from "./ExercisePickerItem";
import ExercisePreviewPopup from "./ExercisePreviewPopup";
import IconLink from "./IconLink";
import RadioButtons from "./RadioButtons";
import { OuterMargin, SmallPrimaryButton, TopBar, TopLeft, TopRight } from "./SharedStyledComponents";

type TypeOption = "all" | "favourites" | "added" | "new";
type SortOption = "a-z" | "z-a";

const getUniqueJoints = (exercises: UserCourseLibraryExercises) =>
  [...exercises].reduce<string[]>((prev, curr) => {
    const currentJoints = curr.target_joints;
    const newJoints = currentJoints.filter(j => prev.indexOf(j) === -1);
    return [...prev, ...newJoints];
  }, []);

const alphaSort = (selectedSort: SortOption) => (a: UserCourseLibraryExercise, b: UserCourseLibraryExercise) => {
  if (selectedSort === "z-a") {
    return a.levels[0].title < b.levels[0].title ? 1 : -1;
  }
  return a.levels[0].title < b.levels[0].title ? -1 : 1;
};

const searchExercise = (search: string) => {
  const normalizedSearch = search.toLowerCase();
  return (exercise: UserCourseLibraryExercise) => {
    return exercise.levels.some(
      level =>
        level.title.toLowerCase().includes(normalizedSearch) ||
        (level.variation_title && level.variation_title.toLowerCase().includes(normalizedSearch))
    );
  };
};

const existsInSelectedExercises = (selectedExercises: UserCourseLibraryExercise[], item: UserCourseLibraryExercise) =>
  !!selectedExercises.find(e => e.exercise_id === item.exercise_id);

const filterExercises =
  (
    selectedExercises: UserCourseLibraryExercise[],
    previewJoint: string,
    selectedTypeOption: TypeOption,
    initialSelectedIds: number[]
  ) =>
  (item: UserCourseLibraryExercise) => {
    const added = initialSelectedIds.includes(item.exercise_id);
    const hasSelectedJoint = previewJoint === "all" || item.target_joints.find(j => j.indexOf(previewJoint) !== -1);

    if ((selectedTypeOption === "added" && !added) || (selectedTypeOption === "new" && added) || !hasSelectedJoint) {
      return false;
    }
    return true;
  };

const ExercisePicker = ({
  onClose,
  onAddExercises,
  initialSelectedIds = [],
}: {
  onAddExercises: (exercises: UserCourseLibraryExercises) => void;
  onClose: () => void;
  initialSelectedIds?: number[];
}) => {
  const { t } = useTranslation();
  const [search, setSearch] = useState("");
  const [selectedTypeOption, setSelectedTypeOption] = useState<TypeOption>("all");
  const [jointOptions, setJointOptions] = useState([]);
  const [previewJoint, setPreviewJoint] = useState("all");
  const [selectedSort, setSelectedSort] = useState<SortOption>("a-z");
  const [selectedExercises, setSelectedExercises] = useState<UserCourseLibraryExercises>([]);
  const [previewExercise, setPreviewExercise] = useState<UserCourseLibraryExercise>();
  const [filteredExercises, setFilteredExercises] = useState<UserCourseLibraryExercises>([]);
  const { register, setValue } = useForm<{ search: string }>();

  const {
    refetch: refetchExercises,
    data: allExercises,
    error: isError,
    isLoading,
  } = useGetUserCourseLibraryExercisesQuery();

  useEffect(() => {
    refetchExercises();
  }, []);

  // set initial selection
  useEffect(() => {
    if (!selectedExercises.length && allExercises && initialSelectedIds.length) {
      const exercises = [...allExercises].filter(item => initialSelectedIds.includes(item.exercise_id));
      if (exercises.length) {
        setSelectedExercises(exercises);
      }
    }
  }, [allExercises]);

  // genereate joints options
  useEffect(() => {
    if (allExercises?.length) {
      const availableJoints = getUniqueJoints(allExercises).map(i => ({
        // FIXME: type translation
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        label: t(`patients.pain_location.${i}.0`),
        value: i,
      }));
      // FIXME: type translation
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setJointOptions([{ label: t("patients.pain_location.ALL"), value: "all" }, ...availableJoints]);
    }
  }, [allExercises]);

  // generate filtered exercises
  useEffect(() => {
    if (!allExercises?.length) {
      setFilteredExercises([]);
    }
    if (allExercises?.length) {
      const fe = [...allExercises]
        .filter(searchExercise(search))
        .filter(filterExercises(selectedExercises, previewJoint, selectedTypeOption, initialSelectedIds))
        .sort(alphaSort(selectedSort));
      setFilteredExercises(fe);
    }
  }, [allExercises, previewJoint, search, selectedTypeOption, initialSelectedIds, selectedSort]);

  const typeOptions: { label: string; value: TypeOption }[] = [
    { label: t("usercourse.add.all"), value: "all" },
    { label: t("usercourse.add.added"), value: "added" },
    { label: t("usercourse.add.new"), value: "new" },
  ];

  const sortOptions: { label: string; value: SortOption }[] = [
    { label: t("usercourse.add.a-z"), value: "a-z" },
    { label: t("usercourse.add.z-a"), value: "z-a" },
  ];

  const markAll = () => {
    if (allExercises) {
      setSelectedExercises(state => {
        const newExercises = filteredExercises.filter(
          filteredExercise =>
            !selectedExercises.find(selectedExercise => selectedExercise.exercise_id === filteredExercise.exercise_id)
        );
        return [...state, ...newExercises];
      });
    }
  };

  const unmarkAll = () => {
    if (allExercises) {
      setSelectedExercises(state => {
        const newState = state.filter(se => !filteredExercises.find(ve => ve.exercise_id === se.exercise_id));
        return [...newState];
      });
    }
  };

  if (isError) {
    return (
      <LoadingContainer>
        <CenterText>
          <Notification type="danger" m="0 0 5px 0">
            {t("errors.generic")}
          </Notification>
          <PrimaryButton
            onClick={() => {
              onClose();
            }}
          >
            {t("buttons.close")}
          </PrimaryButton>
        </CenterText>
      </LoadingContainer>
    );
  }

  return (
    <>
      <Root>
        <HeaderRow>
          <OuterMargin>
            <TopBar>
              <TopLeft>
                <Title>{t("usercourse.add_exercise")}</Title>
              </TopLeft>
              <TopRight>
                <SmallPrimaryButton
                  onClick={() => {
                    onAddExercises(selectedExercises);
                  }}
                >
                  {t("buttons.add")}
                </SmallPrimaryButton>
                <IconButton
                  label=""
                  onClick={() => {
                    onClose();
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </TopRight>
            </TopBar>
          </OuterMargin>
        </HeaderRow>
        <OuterMargin>
          <Library>
            <TopBar>
              <TopLeft>
                <RadioButtons
                  value={selectedTypeOption}
                  options={typeOptions}
                  onChange={v => {
                    setSelectedTypeOption(v);
                  }}
                />
              </TopLeft>
              <TopRight>
                <Search
                  wrapperStyle={{ flexGrow: 1 }}
                  inputStyle={{ maxHeight: "38px", borderRadius: 0, borderWidth: "1px" }}
                  {...register("search")}
                  name="exerciseSearch"
                  reset={() => {
                    setValue("search", "");
                    setSearch("");
                  }}
                  icon={SearchIcon}
                  placeholder={t("usercourse.search_exercise")}
                  search={search}
                  onChange={async e => {
                    setSearch(e.target.value);
                  }}
                />
              </TopRight>
            </TopBar>
            <TopBar>
              <Hidden type="belowTablet">
                <TopLeft>
                  <div>{`${selectedExercises.length} ${t("usercourse.exerciselist.exercises")}`}</div>
                  <IconLink icon={faCirclePlus} title={t("usercourse.select_all")} onClick={markAll} />
                  <IconLink icon={faCircleMinus} title={t("usercourse.deselect_all")} onClick={unmarkAll} />
                </TopLeft>
              </Hidden>
              <Hidden type="aboveTablet">
                <TopLeft>
                  <Col>
                    <div>{`${selectedExercises.length} ${t("usercourse.exerciselist.exercises")}`}</div>
                    <Row>
                      <IconLink icon={faCirclePlus} title={t("usercourse.select_all")} onClick={markAll} />
                      <IconLink icon={faCircleMinus} title={t("usercourse.deselect_all")} onClick={unmarkAll} />
                    </Row>
                  </Col>
                </TopLeft>
              </Hidden>
              <TopRight>
                <Column>
                  <DropDown
                    options={jointOptions}
                    value={previewJoint}
                    onChange={v => {
                      setPreviewJoint(v);
                    }}
                  />
                </Column>
                <Column>
                  <DropDown
                    options={sortOptions}
                    value={selectedSort}
                    onChange={v => {
                      setSelectedSort(v);
                    }}
                  />
                </Column>
              </TopRight>
            </TopBar>

            <SearchResults>
              {isLoading && (
                <FullWidth>
                  <Spinner />
                </FullWidth>
              )}

              {!isLoading && allExercises?.length === 0 && <NoResultsBox>{t("usercourse.no_result")}</NoResultsBox>}

              {filteredExercises.map(item => {
                const checked = existsInSelectedExercises(selectedExercises, item);

                return (
                  <ExercisePickerItem
                    key={`exercisepickeritem_${item.exercise_id}`}
                    item={item}
                    checked={checked}
                    onChecked={() => {
                      if (checked) {
                        setSelectedExercises(state => state.filter(i => i.exercise_id !== item.exercise_id));
                      } else {
                        setSelectedExercises(state => [...state, item]);
                      }
                    }}
                    onClick={() => {
                      setPreviewExercise(item);
                    }}
                  />
                );
              })}
            </SearchResults>
          </Library>
        </OuterMargin>
      </Root>
      {previewExercise && (
        <ExercisePreviewPopup
          exercise={previewExercise}
          onAddExercise={() => {
            setSelectedExercises(state => [...state, previewExercise]);
            setPreviewExercise(undefined);
          }}
          showAddButton={!existsInSelectedExercises(selectedExercises, previewExercise)}
          onClose={() => {
            setPreviewExercise(undefined);
          }}
        />
      )}
    </>
  );
};

export default ExercisePicker;

const Library = styled.div`
  padding: "16px 0";
`;

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const SearchResults = styled.div`
  margin-top: ${props => props.theme.spacing.S_20};
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const CenterText = styled.div`
  text-align: center;
`;

const FullWidth = styled.div`
  width: 100%;
`;

const NoResultsBox = styled.div`
  width: 100%;
  text-align: center;
  font-size: 16px;
  padding: 20px;
  background-color: #f8f8f8;
`;

const Title = styled.span`
  font-size: 22px;
  font-weight: 600;
`;

const HeaderRow = styled.div`
  background-color: #fff;
  padding: 16px 0 12px 0;
  margin-bottom: 20px;
`;

const Root = styled.div`
  background-color: #f8f8f8;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;
