import type { VFC } from "react";
import { useMemo } from "react";

import { addMonths } from "date-fns";
import type { TFunction } from "i18next";
import { useTranslation } from "react-i18next";

import { useGetTherapistEarnings } from "api/hooks/useGetTherapistEarnings";
import type { TherapistEarnings } from "api/models/TherapistEarnings";
import Spinner from "shared/atoms/Spinner";
import useLocalizedDate from "utils/date";
import useLocale from "utils/date/useLocale";
import isFiniteNumber from "utils/number/is-finite-number";
import capitalize from "utils/string/capitalize";

import Center from "../Center";
import ChartBox from "../ChartBox";
import type { ChartTableData } from "../ChartTable";

import EarningsChart from "./EarningsChart";
import type { EarningsChartData } from "./EarningsChartData";

type EarningsChartContainerProps = {
  date: Date;
  therapistId: number;
};

const EarningsChartContainer: VFC<EarningsChartContainerProps> = ({ date, therapistId }) => {
  const { t } = useTranslation();
  const locale = useLocale();
  const { parseISO, format, formats } = useLocalizedDate();

  const formatDate = (value: string) =>
    capitalize(format(parseISO(value), formats.WRITTEN_LOCALIZED_SHORT_MONTH_YEAR_MMMyyyy));
  const formatNumber = (value: unknown) => (isFiniteNumber(value) ? value.toLocaleString(locale.code) : undefined);

  const toEarningsChartData = createToEarningsChartData(formatDate);
  const toEarningsPieChartData = createToEarningsPieChartData(formatDate, t);
  const toMonthlyEarnings = createToMonthlyEarnings(formatDate, formatNumber, t);

  const earnings = useGetTherapistEarnings({ date, therapistId });
  const earningsMinusOne = useGetTherapistEarnings({ date: addMonths(date, -1), therapistId });
  const earningsMinusTwo = useGetTherapistEarnings({ date: addMonths(date, -2), therapistId });

  const error = earnings.error || earningsMinusOne.error || earningsMinusTwo.error;
  const isLoading = earnings.isLoading || earningsMinusOne.isLoading || earningsMinusTwo.isLoading;

  // TODO: Use this one later
  // const chartData = useMemo(
  //   () => [
  //     toEarningsChartData(earningsMinusTwo.data),
  //     toEarningsChartData(earningsMinusOne.data),
  //     toEarningsChartData(earnings.data),
  //   ],
  //   [earnings.data, earningsMinusOne.data, earningsMinusTwo.data]
  // );

  const chartData = useMemo(() => [toEarningsChartData(earnings.data)], [earnings.data]);
  const pieChartData = useMemo(() => toEarningsPieChartData(earnings.data), [earnings.data]);

  const monthlyEarnings: [ChartTableData[], ChartTableData[]] = useMemo(
    () => toMonthlyEarnings(earnings.data),
    [earnings.data]
  );

  // const signedCareEvents: [ChartTableData[], ChartTableData[]] = [
  //   [{ label: "Signed care events (dummy)", value: 49 }],
  //   [
  //     { label: "Discharge", value: 11 },
  //     { label: "Monthly", value: 1 },
  //     { label: "Weekly", value: 1 },
  //     { label: "Appointment", value: 1 },
  //     { label: "Chat", value: 1 },
  //   ],
  // ];

  // const unsignedCareEvents: [ChartTableData[], ChartTableData[]] = [
  //   [{ label: " Unsigned care events (dummy)", value: 14 }],
  //   [
  //     { label: "Discharge", value: 11 },
  //     { label: "Monthly", value: 1 },
  //     { label: "Weekly", value: 1 },
  //     { label: "Appointment", value: 1 },
  //     { label: "Chat", value: 1 },
  //   ],
  // ];

  return (
    <ChartBox title={t("monthly_report.your_earnings.title")}>
      {(error || isLoading) && (
        <Center>
          {isLoading && <Spinner />}
          {error && t("errors.generic")}
        </Center>
      )}
      {!isLoading && !error && (
        <EarningsChart
          chartData={chartData}
          monthlyEarnings={monthlyEarnings}
          pieChartData={pieChartData}
          // signedCareEvents={signedCareEvents}
          // unsignedCareEvents={unsignedCareEvents}
        />
      )}
    </ChartBox>
  );
};

export default EarningsChartContainer;

type FormatDateFunction = (date: string) => string;
type FormatNumberFunction = (value: number) => string | undefined;

const createToEarningsChartData =
  (formatDate: FormatDateFunction) =>
  (data?: TherapistEarnings): EarningsChartData | undefined => {
    if (data) {
      const label = formatDate(data.invoice_period);
      const { care_events_income, currency, enrollment_income, selfcare_bonus_income } = data;

      return {
        currency,
        careEvent: care_events_income,
        enrollmentBonus: enrollment_income,
        selfCareBonus: selfcare_bonus_income,
        label,
      };
    }
    return undefined;
  };

// TODO: Delete when SE Ops give the green light to switch to bars.
export type PieChartData = {
  name: string;
  value?: null | number | string;
  color: string;
};

const createToEarningsPieChartData =
  (formatDate: FormatDateFunction, t: TFunction) =>
  (data?: TherapistEarnings): PieChartData[] | undefined => {
    if (data) {
      const careEvents = t("monthly_report.your_earnings.care_event");
      const enrollments = t("monthly_report.your_earnings.enrollment_bonus");
      const selfCare = t("monthly_report.your_earnings.selfcare_bonus");

      const { care_events_income, enrollment_income, selfcare_bonus_income } = data;

      return [
        {
          color: "#5096e8",
          name: careEvents,
          value: care_events_income,
        },
        {
          color: "#70c8bc",
          name: enrollments,
          value: enrollment_income,
        },
        {
          color: "#eca073",
          name: selfCare,
          value: selfcare_bonus_income,
        },
      ];
    }
    return undefined;
  };

const createToMonthlyEarnings =
  (formatDate: FormatDateFunction, formatNumber: FormatNumberFunction, t: TFunction) =>
  (data?: TherapistEarnings): [ChartTableData[], ChartTableData[]] => {
    if (data) {
      const date = formatDate(data.invoice_period);
      const title = t("monthly_report.your_earnings.monthly_earnings.title", { date });
      const earnings = t("monthly_report.your_earnings.monthly_earnings.earnings");
      const careEvents = t("monthly_report.your_earnings.monthly_earnings.no_of_care_events");
      const enrollments = t("monthly_report.your_earnings.monthly_earnings.no_of_enrollments");
      const six_week_bonuses = t("dashboard.tables.earnings.rows.six_week_bonuses");
      const treatments = t("monthly_report.your_earnings.monthly_earnings.no_of_treatments");
      const selfCare = t("dashboard.tables.earnings.rows.selfcare_bonus_income");

      const displayTreatmentIncome = data.treatment_income > 0;

      return [
        [{ label: title }],
        [
          { label: earnings, value: formatNumber(data.total_income) },
          { label: careEvents, value: formatNumber(data.number_of_care_events) },
          { label: enrollments, value: formatNumber(data.number_of_enrollments) },
          { label: six_week_bonuses, value: formatNumber(data.number_of_six_week_bonuses) },
          ...(displayTreatmentIncome ? [{ label: treatments, value: formatNumber(data.treatment_income) }] : []),
          { label: selfCare, value: formatNumber(data.number_of_selfcare_bonus) },
        ],
      ];
    }
    return [[], []];
  };
