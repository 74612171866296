import type React from "react";

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

import { isNewAuthEnabled } from "utils/auth";
import { getResetPasswordLink } from "utils/misc/getResetPasswordLink";

interface Props {
  email?: string;
}

export const ForgotPasswordLink: React.VFC<Props> = ({ email }) => {
  const { t } = useTranslation();

  if (isNewAuthEnabled()) {
    return (
      <ForgotPasswordAnchorTag href={getResetPasswordLink(email)} target="_blank" rel="noreferrer">
        {t("login.reset_password")}
      </ForgotPasswordAnchorTag>
    );
  }

  return (
    <LegacyForgotPasswordLink to={`/login/reset-password/${email || ""}`}>
      {t("login.reset_password")}
    </LegacyForgotPasswordLink>
  );
};

const ForgotPasswordLinkStyle = css`
  align-self: flex-start;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  text-decoration: none;
  margin-top: 12px;
  color: ${({ theme }) => theme.colors.redesign.b100};
`;

const ForgotPasswordAnchorTag = styled.a`
  ${ForgotPasswordLinkStyle}
`;

const LegacyForgotPasswordLink = styled(Link)`
  ${ForgotPasswordLinkStyle}
`;
