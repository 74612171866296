import styled from "styled-components";

const Center = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 0 0 24px;
`;

export default Center;
