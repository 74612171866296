import styled from "styled-components";

const ButtonGroup = styled.div`
  display: flex;

  & > :not(:first-of-type) {
    border-bottom-left-radius: 0;
    border-left-width: 1px;
    border-top-left-radius: 0;
  }
  & > :not(:last-of-type) {
    border-bottom-right-radius: 0;
    border-right-width: 1px;
    border-top-right-radius: 0;
  }
`;

export default ButtonGroup;
