const AnalyticsEvents = {
  VIDEO_CALL: {
    INITIATED: "Video Call Initiated",
    PARTICIPANT_JOINED: "Video Call Participant Joined",
    ENDED: "Video Call Ended",
  },
  LANDING: {
    LOGIN: "Login Performed",
    PARTNER_LOGIN: "Login performed by partner",
  },
  MESSAGES: {
    SCHEDULE_CALL: "Scheduled a call",
    SEND_MESSAGE: "Chat message sent",
    SEND_BROADCAST_MESSAGE: "Broadcast message sent",
  },
  PATIENTS: {
    COMMENT_REPLY: "Comment message sent",
    CHANGE_LEVELS: "Changed level of exercise",
    REMOVE_EXERCISE: "Exercise removed",
    UPDATE_GOAL: "Updated the patient goal",
    END_TREATMENT: "Ended the treatment",
    CREATE_MEDICAL_NOTE: "Medical note created",
    SIGN_NOTE: "Medical note signed",
    SET_DIAGNOSIS: "Diagnosis set",
    CHECK_IN_CALL: "Check-in call",
  },
  QUICK_REPLIES: {
    USED_SHORTCUT_QUICK_REPLY: "Used shortcut quick reply",
    USED_FORM_QUICK_REPLY: "Used form quick reply",
    SAVED_QUICK_REPLY: "Saved quick reply",
    DELETED_QUICK_REPLY: "Deleted quick reply",
  },
  CLINIC_PARTNER: {
    UNCLAIMED_REFERRAL_VIEWED: "Unclaimed referral document viewed",
    PATIENT_PROFILE_VIEWED: "Partner viewed patient profile",
    PATIENT_JOURNAL_PDF_DOWNLOADED: "Partner downloaded patient journal as PDF",
    PATIENT_OVERVIEW_PDF_DOWNLOADED: "Partner downloaded patient overview as PDF",
  },
  DROP_IN_CALLS: {
    FEEDBACK_ANSWER: "Drop in call feedback",
    TOGGLE_AVAILABLE_ON_CLICKED: "Drop in call toggle available on clicked",
    TOGGLE_AVAILABLE_OFF_CLICKED: "Drop in call toggle available off clicked",
    SET_TOGGLE_ON: "Drop in call toggle set to on",
    SET_TOGGLE_OFF: "Drop in call toggle set to off",
    START_REVIEW_CLICKED: "Drop in call start review clicked",
    FINISH_REVIEW_CLICKED: "Drop in call finish review clicked",
    PATIENT_ASSIGNED: "Drop in call patient assigned",
    IN_REVIEW: "Drop in call patient in review",
    COMPLETED: "Drop in call patient completed",
    CANCELED: "Drop in call canceled",
    CLIENT_CANCELED: "Drop in call canceled by client",
    ERROR: "Drop in call error",
    BROWSER_UNLOAD: "Drop in call Browser unload",
    DISCONNECT: "Drop in call disconnect",
    CONNECT: "Drop in call connect",
    SUBSCRIBED: "Drop in call subscribed",
  },
  LEIF_GPT: {
    COMMENTS: {
      SUGGESTION_PRESENTED: "Smart quick reply presented to the user",
      SUGGESTION_DISMISSED: "Smart quick reply dismissed by the user",
      SUGGESTION_EDITED: "Smart quick reply edit button clicked",
      SUGGESTION_SUBMITTED: "Smart quick reply submitted without editing",
    },
    MESSAGES: {
      SUGGESTION_PRESENTED: "Smart message presented to the user",
      SUGGESTION_DISMISSED: "Smart message dismissed by the user",
      SUGGESTION_EDITED: "Smart message edit button clicked",
      SUGGESTION_SUBMITTED: "Smart message submitted without editing",
    },
  },
  BOOKING: {
    APPOINTMENT: {
      APPOINTMENT_DETAILS_OPENED: "Appointment details opened",
      MARKED_AS_COMPLETED: "Appointment marked as completed",
      MARKED_AS_NO_SHOW: "Appointment marked as no show",
    },
  },
};

export default AnalyticsEvents;
