import { useContext, useEffect, useState } from "react";

import { faClose } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import styled, { useTheme } from "styled-components";
import invariant from "ts-invariant";

import useGetPatientAvailableAilments from "api/hooks/useGetPatientAvailableAilments";
import { useGetPatientHealthJournalQuery } from "api/hooks/useGetPatientHealthJournalQuery";
import { usePostPatientSwitchTreatmentPrograms } from "api/hooks/usePostPatientSwitchTreatmentPrograms";
import { type Payload, PayloadSchema } from "api/schemas/patients/SwitchTreatmentPrograms";
import { useProfileContext } from "contexts/ProfileContext";
import {
  JointButton,
  JointsGroup,
} from "routes/calendar/components/AdminBookingForm/screens/HealthQuestionnaire/JointScreen";
import { CloseButton } from "routes/calendar/components/Forms/Header";
import type { AilmentItem } from "routes/calendar/helpers/getAilments";
import { getAilments } from "routes/calendar/helpers/getAilments";
import { BaseButton } from "shared/atoms/BaseButton";
import Spinner from "shared/atoms/Spinner";
import { CurrentPatientContext } from "utils/contexts";
import { isErrorOfType, reportError } from "utils/errorReporting";

import { hasLateralLocation } from "./hasLateralLocation";
import { HipKneeQuestions } from "./HipKneeQuestions";
import { LateralSwitch } from "./LateralSwitch";
import { OsteoporosisQuestions } from "./OsteoporosisQuestions";
import { ButtonGroup, ErrorMessage, Label, Question, StyledFormState } from "./styles";

const initialPayloadState: Payload = {
  ailment: "joint_pain",
  most_painful_joint: undefined,
  osteoporosis_diagnosed: undefined,
  osteoporosis_osteopenia_diagnosed: undefined,
  specific_joint_examined: undefined,
  specific_joint_oa_diagnosed: undefined,
};

export type FormError = {
  most_painful_joint?: boolean;
  osteoporosis_diagnosed?: boolean;
  osteoporosis_osteopenia_diagnosed?: boolean;
  specific_joint_examined?: boolean;
  specific_joint_oa_diagnosed?: boolean;
};

interface Props {
  onClose: () => void;
}

export const TreatmentSwitch: React.VFC<Props> = ({ onClose }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { profile } = useProfileContext();
  const { patient, refreshPatient } = useContext(CurrentPatientContext);
  const [formError, setFormError] = useState<FormError>({});
  const [ailmentsAndJoints, setAilmentsAndJoints] = useState<AilmentItem[]>([]);
  const [jointOrAilment, setJointOrAilment] = useState<AilmentItem | undefined>();
  const [payload, setPayload] = useState(initialPayloadState);
  const [showLateralLocationSelection, setShowLateralLocationSelection] = useState(false);

  const postSwitchTreatmentPrograms = usePostPatientSwitchTreatmentPrograms();

  invariant(patient);
  invariant(profile);
  const { data, isLoading } = useGetPatientAvailableAilments({ patientID: patient.id, therapistID: profile.id });
  const { data: healthJournal } = useGetPatientHealthJournalQuery(
    {
      patientId: patient.id,
    },
    { staleTime: 0 }
  );

  useEffect(() => {
    if (data) {
      const ailments = getAilments({
        availableAilments: data.available_ailments,
        availableJoints: data.available_joints,
      });
      setAilmentsAndJoints(ailments);
    }
  }, [data]);

  useEffect(() => {
    if (Object.keys(formError).length > 0) {
      const parsedPayload = PayloadSchema.safeParse(payload);
      if (!parsedPayload.success) {
        const errors = parsedPayload.error.issues.reduce((acc, issue) => {
          return { ...acc, [issue.path[0]]: true };
        }, {});
        setFormError(errors);
      } else {
        setFormError({});
      }
    }
  }, [payload]);

  const onAilmentOrJointClick = (ailmentOrJoint: AilmentItem) => {
    setFormError({});
    setPayload({ ...initialPayloadState, ailment: ailmentOrJoint.ailment });
    setJointOrAilment(ailmentOrJoint);
    if (ailmentOrJoint.hasLateralLocation) {
      setShowLateralLocationSelection(true);
    } else {
      setShowLateralLocationSelection(false);
      if (ailmentOrJoint.ailment === "joint_pain") {
        setPayload(prevState => ({ ...prevState, most_painful_joint: ailmentOrJoint.value }));
      }
    }
  };

  const updatePayload = (key: string, value: string | boolean) => {
    setPayload(prevState => ({ ...prevState, [key]: value }));
  };

  const onSwitchTreatment = () => {
    const parsedPayload = PayloadSchema.safeParse(payload);
    if (!parsedPayload.success) {
      const errors = parsedPayload.error.issues.reduce((acc, issue) => {
        return { ...acc, [issue.path[0]]: true };
      }, {});
      setFormError(errors);
      return;
    }

    try {
      postSwitchTreatmentPrograms.mutateAsync(
        {
          therapistID: profile.id,
          patientID: patient.id,
          data: payload,
        },
        {
          onError: e => {
            reportError("TreatmentSwitch.tsx", e);
          },
          onSuccess: () => {
            setFormError({});
            refreshPatient();
            setTimeout(() => {
              onClose();
            }, 3000);
          },
        }
      );
    } catch (e) {
      if (isErrorOfType(e)) {
        reportError("TreatmentSwitch.tsx", e);
      }
    }
  };

  return (
    <Container>
      <StyledCloseButton onClick={() => onClose()}>
        <FontAwesomeIcon icon={faClose} color={theme.colors.redesign.db90} style={{ height: "24px", width: "24px" }} />
      </StyledCloseButton>

      {healthJournal?.most_painful_location?.lateral_location &&
        healthJournal?.most_painful_location?.location &&
        hasLateralLocation(healthJournal.most_painful_location.location) && (
          <LateralSwitch
            patientID={patient.id}
            location={healthJournal.most_painful_location.location}
            lateralLocation={healthJournal.most_painful_location.lateral_location}
            onClose={onClose}
            refreshPatient={refreshPatient}
          />
        )}

      <Header>{t("switch_treatment.label")}</Header>

      {isLoading && <Spinner dataTestId="joints-spinner" />}

      {ailmentsAndJoints.length > 0 && (
        <StyledJointsGroup>
          {ailmentsAndJoints.map(ailmentAndJoint => {
            return (
              <JointButton
                key={ailmentAndJoint.value}
                onClick={() => onAilmentOrJointClick(ailmentAndJoint)}
                active={jointOrAilment?.value === ailmentAndJoint.value}
              >
                <span>{ailmentAndJoint.label}</span>
                {ailmentAndJoint.icon}
              </JointButton>
            );
          })}
        </StyledJointsGroup>
      )}

      {showLateralLocationSelection && (
        <Question>
          <Label>{t("patient.ONBOARDING.MOST_PAINFUL_JOINT", { joint: jointOrAilment?.value })}</Label>
          <ButtonGroup>
            <BaseButton
              onClick={() => updatePayload("most_painful_joint", `left_${jointOrAilment?.value}`)}
              text={t("patients.lateral_location.left")}
              variant="secondary"
              active={payload.most_painful_joint?.includes("left")}
            />
            <BaseButton
              onClick={() => updatePayload("most_painful_joint", `right_${jointOrAilment?.value}`)}
              text={t("patients.lateral_location.right")}
              variant="secondary"
              active={payload.most_painful_joint?.includes("right")}
            />
          </ButtonGroup>
          {formError?.most_painful_joint && <ErrorMessage>{t("form.validation.required")}</ErrorMessage>}
        </Question>
      )}

      {(jointOrAilment?.value === "hip" || jointOrAilment?.value === "knee") && (
        <HipKneeQuestions
          formError={formError}
          painJoint={jointOrAilment?.value}
          payload={payload}
          updatePayload={updatePayload}
        />
      )}

      {jointOrAilment?.ailment === "osteoporosis" && (
        <OsteoporosisQuestions formError={formError} payload={payload} updatePayload={updatePayload} />
      )}

      <BaseButton
        onClick={onSwitchTreatment}
        text={t("switch_treatment.label")}
        disabled={postSwitchTreatmentPrograms.isLoading}
      />

      <StyledFormState
        formState={postSwitchTreatmentPrograms.status}
        messages={{
          error: t("switch_treatment.error"),
          loading: t("switch_treatment.loading"),
          success: t("switch_treatment.success"),
        }}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 1px 8px 0px rgba(71, 134, 218, 0.15);
  width: 400px;
  padding: 0 20px 20px;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  overflow: auto;

  ${props => props.theme.belowBreakpoint} {
    width: 100vw;
  }
`;

const Header = styled.div`
  align-self: flex-start;
  font-size: 18px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.redesign.db90};
  margin: 36px 8px 16px;
`;

const StyledCloseButton = styled(CloseButton)`
  position: absolute;
  top: 32px;
  right: 36px;
`;

const StyledJointsGroup = styled(JointsGroup)`
  margin-bottom: 16px;
`;
