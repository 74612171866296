import type React from "react";
import { useEffect, useRef, useState } from "react";

import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import axios from "axios";
import { format } from "date-fns";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import invariant from "ts-invariant";

import { useDeleteBookingInterval } from "api/hooks/useDeleteBookingInterval";
import useGetHcpServices from "api/hooks/useGetHcpServices";
import useGetProfessionals from "api/hooks/useGetProfessionals";
import { usePostBookingInterval } from "api/hooks/usePostBookingInterval";
import { usePostManagerBookingInterval } from "api/hooks/usePostManagerBookingInterval";
import { usePutBookingInterval } from "api/hooks/usePutBookingInterval";
import type { Availability, BookingIntervalResponse, Recurrence } from "api/schemas/BookingInterval";
import type { HcpService } from "api/schemas/HcpService";
import { useCalendarContext } from "contexts/CalendarContext";
import type { SelectedDate } from "contexts/CalendarContext";
import { useProfileContext } from "contexts/ProfileContext";
import { generateAvailabilityOptions } from "routes/calendar/helpers";
import type { FormStates } from "routes/calendar/helpers/misc";
import { MUIDropdown } from "shared/atoms/inputs";
import useLocalizedDate from "utils/date";
import useLocale from "utils/date/useLocale";
import { reportError } from "utils/errorReporting";
import { userIsAdmin, userIsTherapist } from "utils/profile/profileHelper";

import AppointmentInterval from "../AppointmentInterval";
import type { SetSelectArgs } from "../Calendar/Calendar";
import { convertDateStringToObject, formDateToJSDates, generateOptions } from "../helpers";

import { FormFooter } from "./FormFooter";
import { getDefaultDate } from "./getDefaultDate";
import { Container, Form, Input, Wrapper } from "./styles";

export type FormData = {
  availability: Availability;
  date: string;
  end_time: number;
  personnel: number;
  recurrence: Recurrence;
  service_id: string;
  start_time: number;
};

interface Props {
  selectedBookingInterval: BookingIntervalResponse | null;
  selectedDate?: SelectedDate | null;
  onClose: (booked?: boolean) => void;
  refetchAppointments?: () => void;
  setSelect?: (args: SetSelectArgs) => void;
}

export const AvailabilityForm: React.VFC<Props> = ({
  selectedBookingInterval,
  selectedDate,
  onClose,
  refetchAppointments,
  setSelect,
}) => {
  const { t } = useTranslation();
  const { profile } = useProfileContext();
  const { isValid } = useLocalizedDate();
  const locale = useLocale();
  const { showMyAvailability, setShowMyAvailability } = useCalendarContext();

  const [editMode] = useState(!!selectedBookingInterval);
  const [selectedStartTime, setSelectedStartTime] = useState<number>();
  const [selectedEndTime, setSelectedEndTime] = useState<number>();
  const [formState, setFormState] = useState<FormStates>("default");
  const [error, setError] = useState<string | null>(null);
  const [duration, setDuration] = useState<number | undefined>(undefined);
  const showMyAvailabilityRef = useRef(showMyAvailability);

  invariant(profile);
  const isAdmin = userIsAdmin(profile);
  const isTherapist = userIsTherapist(profile);
  const isOnlyAdmin = isAdmin && !isTherapist;

  const deleteBookingInterval = useDeleteBookingInterval();
  const postBookingInterval = usePostBookingInterval();
  const putBookingInterval = usePutBookingInterval();
  const postManagerBookingInterval = usePostManagerBookingInterval();

  const { data: professionals = [] } = useGetProfessionals(profile.id, { enabled: isAdmin });

  const { data: hcpServices = [] } = useGetHcpServices(profile.id, { enabled: isTherapist });
  const serviceOptions = generateOptions({
    array: hcpServices.filter(service => service.bookable_by_patients),
    labelKey: "name",
    valueKey: "id",
  });
  // Add blocked time to the services if not editing existing
  if (!editMode) {
    serviceOptions.push({
      value: "unavailable",
      label: t("booking.calendar.event_title.blocked_time"),
    });
  }

  const personnelDefaultState = [{ label: `${profile?.first_name} ${profile?.last_name}`, value: profile.id }];
  const [personnelOptions, setPersonnelOptions] = useState(personnelDefaultState);

  const recurrenceOptions = [
    { label: t("booking.form.recurrence_options.none"), value: "none" },
    { label: t("booking.form.recurrence_options.weekly"), value: "weekly" },
  ];

  const form = useForm<FormData>({
    mode: "onSubmit",
    reValidateMode: "onChange",
    defaultValues: {
      date: getDefaultDate(selectedBookingInterval?.start_time),
      service_id: selectedBookingInterval?.health_care_service?.id?.toString() ?? "",
      personnel: personnelOptions[0]?.value,
      start_time: selectedBookingInterval?.start_time
        ? convertDateStringToObject(selectedBookingInterval.start_time).startTime
        : undefined,
      end_time: selectedBookingInterval?.end_time
        ? convertDateStringToObject(selectedBookingInterval.end_time).startTime
        : undefined,
      recurrence: selectedBookingInterval?.recurrence ?? "none",
    },
  });
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = form;

  const selectedService = watch("service_id");
  const hcpService = hcpServices.find(service => service.id === Number(selectedService));
  const isUnavailable = selectedService === "unavailable";

  const watchDate = watch("date");
  const watchStartTime = watch("start_time");
  const watchEndTime = watch("end_time");
  const selectedPersonnel = watch("personnel");

  useEffect(() => {
    if (watchDate) {
      if (setSelect) {
        const { startDate, endDate } = formDateToJSDates({
          date: watchDate,
          startTime: watchStartTime ?? 0,
          endTime: watchEndTime ?? 0,
        });
        setSelect({ start: startDate, end: endDate });
      }
    }
  }, [watchDate, watchStartTime, watchEndTime]);

  useEffect(() => {
    const dateStart = selectedDate && convertDateStringToObject(selectedDate.startStr);
    const dateEnd = selectedDate && convertDateStringToObject(selectedDate.endStr);

    if (dateStart?.startTime) {
      setSelectedStartTime(dateStart.startTime);
    }
    if (dateEnd?.startTime) {
      setSelectedEndTime(dateEnd.startTime);
    }
    if (dateStart?.date) {
      setValue("date", dateStart.date);
    }
  }, [selectedDate]);

  useEffect(() => {
    if (isUnavailable && professionals.length > 0) {
      setPersonnelOptions(
        professionals.map(professional => ({
          label: `${professional?.health_care_professional_full_name}`,
          value: professional.health_care_professional_id,
        }))
      );
    } else {
      setPersonnelOptions(personnelDefaultState);
    }
  }, [isUnavailable]);

  useEffect(() => {
    if (isTherapist && !showMyAvailability) {
      setShowMyAvailability(true);
    }

    return () => {
      setShowMyAvailability(showMyAvailabilityRef.current);
    };
  }, []);

  useEffect(() => {
    if (!hcpService) {
      return;
    }

    // Set default duration to service's duration
    setDuration(hcpService?.duration);
  }, [hcpService]);

  const onSetupCallback = (successMessage?: FormStates) => {
    return {
      onSuccess: () => {
        if (refetchAppointments) refetchAppointments();
        setFormState(successMessage ?? "success");
        if (onClose) setTimeout(() => onClose(true), 3000);
      },
      onError(e: unknown) {
        if (axios.isAxiosError(e)) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          setError(t(translateError(e.response?.data.error_code)));
        }
        setFormState("default");
        if (e instanceof Error || typeof e === "string") {
          reportError("AvailabilityForm.tsx", e);
        }
      },
    };
  };

  const onDelete = () => {
    if (!selectedBookingInterval) {
      return;
    }

    setFormState("saving");
    setError(null);

    deleteBookingInterval.mutateAsync(
      {
        bookingIntervalID: selectedBookingInterval.id,
        hcProfID: profile.id,
      },
      onSetupCallback()
    );
  };

  const onSubmit = handleSubmit((formData: FormData) => {
    try {
      const { startDate, endDate } = formDateToJSDates({
        date: formData.date,
        startTime: formData.start_time,
        endTime: formData.end_time,
      });
      const startDateISOStr = startDate.toISOString();
      const endDateISOStr = endDate.toISOString();

      setFormState("saving");
      setError(null);

      const data = {
        availability: isUnavailable ? "unavailable" : "available",
        end_time: endDateISOStr,
        note: "",
        recurrence: formData.recurrence,
        service_id: parseInt(formData.service_id, 10),
        start_time: startDateISOStr,
      };

      if (editMode && selectedBookingInterval) {
        putBookingInterval.mutateAsync(
          {
            bookingIntervalID: selectedBookingInterval.id,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            data,
            hcProfID: profile.id,
          },
          onSetupCallback()
        );
      } else {
        postBookingInterval.mutateAsync(
          {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            data,
            hcProfID: profile.id,
          },
          onSetupCallback()
        );
      }
    } catch (e) {
      if (e instanceof Error || typeof e === "string") {
        reportError("AvailabilityForm.tsx", e);
      }
    }
  });

  const onSubmitManager = handleSubmit((formData: FormData) => {
    try {
      const { startDate, endDate } = formDateToJSDates({
        date: formData.date,
        startTime: formData.start_time,
        endTime: formData.end_time,
      });
      const startDateISOStr = startDate.toISOString();
      const endDateISOStr = endDate.toISOString();

      setFormState("saving");
      setError(null);

      const data = {
        availability: "unavailable",
        end_time: endDateISOStr,
        note: "",
        recurrence: "none",
        service_id: parseInt(formData.service_id, 10),
        start_time: startDateISOStr,
      };

      postManagerBookingInterval.mutateAsync(
        {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          data,
          managerID: profile.id,
          hcProfID: formData.personnel,
        },
        onSetupCallback()
      );
    } catch (e) {
      if (e instanceof Error || typeof e === "string") {
        reportError("AvailabilityForm.tsx", e);
      }
    }
  });

  return (
    <Container>
      <FormProvider {...form}>
        <Form onSubmit={selectedPersonnel === profile.id ? onSubmit : onSubmitManager} data-testid="availability-form">
          <Input>
            <MUIDropdown
              label={t("booking.form.service")}
              name="service_id"
              options={serviceOptions}
              required
              error={errors.service_id && t("errors.field.required")}
            />
          </Input>

          <Input className="date-and-time">
            <Controller
              name="date"
              control={control}
              render={({ field: { onChange, value } }) => (
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
                  <Wrapper>
                    <KeyboardDatePicker
                      autoOk
                      name="date"
                      id="date-picker"
                      variant="inline"
                      inputVariant="outlined"
                      invalidDateMessage={t("errors.invalid_date")}
                      minDateMessage={t("errors.past_date")}
                      margin="none"
                      format="yyyy-MM-dd"
                      minDate={new Date()}
                      value={value}
                      onChange={input => {
                        if (input && isValid(input)) {
                          onChange(format(input, "Y-MM-dd"));
                        } else {
                          onChange(input);
                        }
                      }}
                      style={{ width: "100%" }}
                    />
                  </Wrapper>
                </MuiPickersUtilsProvider>
              )}
            />
          </Input>

          <Input>
            <AppointmentInterval
              options={generateAvailabilityOptions(hcpService as HcpService, 5)}
              setValue={setValue}
              getValues={getValues}
              duration={duration}
              selectedStartTime={selectedStartTime}
              selectedEndTime={selectedEndTime}
              errors={errors}
            />
          </Input>

          <Input>
            <MUIDropdown
              label={t("booking.form.personnel")}
              name="personnel"
              options={personnelOptions ?? []}
              disabled={!isAdmin}
              required
              error={errors.personnel && t("errors.field.required")}
            />
          </Input>

          <Input>
            <MUIDropdown
              label={t("booking.form.recurrence")}
              name="recurrence"
              options={recurrenceOptions ?? []}
              disabled={isOnlyAdmin}
              required
              error={errors.recurrence && t("errors.field.required")}
            />
          </Input>

          <FormFooter
            formState={formState}
            editMode={editMode}
            error={error}
            event={selectedBookingInterval}
            deleteAppointment={onDelete}
            text={{
              delete: t("booking.buttons.delete"),
              confirmDeletion: t("booking.form.confirm_deletion_booking_interval"),
              success: t("booking.success_booking_interval"),
              successDeletion: t("booking.success_deletion_booking_interval"),
            }}
          />
        </Form>
      </FormProvider>
    </Container>
  );
};
