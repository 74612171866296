import { useTranslation } from "react-i18next";

import type { PatientProfile } from "../insuranceHelper";

const formatInsuranceCompanyName = (name: string) => {
  return name.charAt(0).toUpperCase() + name.slice(1);
};

const usePrimaryInsuranceInfo = (patient: PatientProfile): string | null => {
  const { t } = useTranslation();
  const { insuranceData, providerGroupData } = patient || {};

  if (providerGroupData?.us_provider_group_clinic?.name) {
    return providerGroupData.us_provider_group_clinic.name;
  }

  if (insuranceData?.insurance_found && insuranceData?.data?.insurance_company_name) {
    const insuranceCompanyName = formatInsuranceCompanyName(insuranceData.data.insurance_company_name);
    // FIXME: type translation
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const insuranceStatus = t(`patients.header_info.insurance_status.${insuranceData.data.status}`);

    const ics = insuranceData.data.insurance_company_supported;
    let supported;
    if (ics !== undefined) {
      supported = ics ? t("patients.header_info.insurance_supported") : t("patients.header_info.insurance_unsupported");
    }
    return `${insuranceCompanyName} (${insuranceStatus}, ${supported})`;
  }

  return null;
};

export default usePrimaryInsuranceInfo;
