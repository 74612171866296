import type React from "react";

import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { CrossIcon } from "assets";
import Hidden from "shared/atoms/Hidden";
import Modal from "shared/atoms/Modal";
import Tooltip from "shared/molecules/Tooltip";

import InvitePatientForm from "./InvitePatientForm";

interface Props {
  readonly close: () => void;
  readonly visible: boolean;
}

const InvitePatient: React.VFC<Props> = ({ close, visible }) => {
  const { t } = useTranslation();

  return (
    <>
      <Hidden type="belowTablet">
        <Wrapper data-testid="invite-patient-desktop">
          <Tooltip requestClose={close} isOpen={visible} offset="bottom-right">
            <CloseBtn onClick={close} data-testid="close-button">
              <CrossIcon />
            </CloseBtn>
            <InvitePatientForm onClose={close} />
          </Tooltip>
        </Wrapper>
      </Hidden>
      <Hidden type="aboveTablet">
        <Modal title={t("patients.invite")} open={visible} close={close}>
          <InvitePatientForm onClose={close} />
        </Modal>
      </Hidden>
    </>
  );
};

export default InvitePatient;

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  position: relative;
  margin-top: 20px;
`;

const CloseBtn = styled.a`
  position: absolute;
  top: 24px;
  right: 25px;
  text-decoration: none;
  color: ${props => props.theme.colors.greys.dark};
  cursor: pointer;
`;
