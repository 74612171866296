import type { AxiosResponse } from "axios";

import { AuthClient } from "api";
import { setNewAuthCookies } from "utils/cookie";

interface FormData {
  readonly email: string;
  readonly password: string;
}

interface AuthenticationResponse {
  readonly success: boolean;
  readonly result: {
    readonly id_token: string;
    readonly refresh_token: string;
    readonly expires_in: number;
  };
}

const authenticateWithEmail = async (body: FormData): Promise<void> => {
  const response: AxiosResponse<AuthenticationResponse> = await AuthClient.post("/sign_in", {
    username: body.email,
    password: body.password,
  });

  setNewAuthCookies({
    idToken: response.data.result.id_token,
    refreshToken: response.data.result.refresh_token,
  });
};

export default authenticateWithEmail;
