import type React from "react";

import { faCircleCheck, faCircleInfo, faTriangleExclamation, faXmark } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled, { css } from "styled-components";
import type { DefaultTheme, FlattenInterpolation, ThemeProps } from "styled-components";

const iconStyles = {
  height: "22px",
  width: "22px",
  marginRight: "12px",
};

const getIcon = (icon: Variant) => {
  switch (icon) {
    case "danger":
      return <FontAwesomeIcon color="#FF5959" icon={faXmark} style={iconStyles} />;
    case "info":
      return <FontAwesomeIcon color="#1274E3" icon={faCircleInfo} style={iconStyles} />;
    case "success":
      return <FontAwesomeIcon color="#57cb75" icon={faCircleCheck} style={iconStyles} />;
    case "warning":
      return <FontAwesomeIcon color="#ECA073" icon={faTriangleExclamation} style={iconStyles} />;
    default:
      return null;
  }
};

type Variant = "danger" | "info" | "success" | "warning";
interface Props {
  margin?: string;
  variant?: Variant;
  children: React.ReactNode;
}

export const StatusIndicator: React.VFC<Props> = ({ margin = "0", variant = "info", children }) => {
  return (
    <Container margin={margin} variant={variant}>
      {getIcon(variant)}
      {children}
    </Container>
  );
};

const styles: Record<Variant, FlattenInterpolation<ThemeProps<DefaultTheme>>> = {
  danger: css`
    background: ${({ theme }) => theme.colors.pastels.red};
  `,
  info: css`
    background: ${({ theme }) => theme.colors.pastels.blue};
  `,
  success: css`
    background: ${({ theme }) => theme.colors.pastels.green};
  `,
  warning: css`
    background: ${({ theme }) => theme.colors.pastels.orange};
  `,
};

const Container = styled.div<{ margin: string; variant: Variant }>`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  min-height: 50px;
  margin: ${({ margin }) => margin};
  padding: 12px;
  color: ${({ theme }) => theme.colors.primary.base};
  border-radius: 8px;
  ${({ theme }) => ({ ...theme.font.body1 })};
  ${({ variant }) => styles[variant]}
`;
