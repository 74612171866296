import * as braze from "@braze/web-sdk";
import Cookies from "js-cookie";

import cacheClient from "cacheClient";
import { getRestHeaders, removeAuthCookies } from "utils/cookie";
import { isErrorOfType, reportError } from "utils/errorReporting";

import { signOut } from "./queries";

const AUTHENTICATION_METHOD = `/${process.env.REACT_APP_AUTHENTICATION_METHOD || "email"}`;
const LOGIN_URL = `/login${AUTHENTICATION_METHOD}`;

let signingOut = false;

const signOutUser = async (error?: string): Promise<void> => {
  const authHeaders = getRestHeaders();

  if (authHeaders && !signingOut) {
    try {
      signingOut = true;
      await signOut();
      removeAuthCookies();
      cacheClient.clear();
      braze.destroy();
      signingOut = false;

      // Don't redirect to login page if we are already there or on the failed result page
      if (window.location.pathname.includes("/login")) {
        return;
      }

      if (error) {
        const redirectUrlParam = `?redirectUrl=${window.location.pathname}`;
        const errorParam = `&error=${error}`;
        window.location.replace(LOGIN_URL + redirectUrlParam + errorParam);
      } else {
        window.location.replace(LOGIN_URL);
      }
    } catch (e) {
      if (isErrorOfType(e)) {
        reportError("signOutUser", e);
      }
    }
  }
};

// this exists so we can QA the new auth flow before turning it on for everyone.
const hasNewAuthEnabledCookie = (): boolean => !!Cookies.get("rja_new_auth_enabled");

const isNewAuthEnabled = (): boolean => process.env.REACT_APP_NEW_AUTH_ENABLED === "true" || hasNewAuthEnabledCookie();

const isNewAuthSession = (): boolean => isNewAuthEnabled() || Cookies.get("rja_auth_type") === "new";

export { LOGIN_URL, signOutUser, isNewAuthEnabled, isNewAuthSession };
