import isFiniteNumber from "utils/number/is-finite-number";

export const createYearMonthParams = (date: Date): string => `?year=${date.getFullYear()}&month=${date.getMonth() + 1}`;

export const getJsMonth = (params: URLSearchParams): number => {
  const param = params.get("month");
  const month = Number.parseInt(String(param), 10);
  const isValidMonth = isFiniteNumber(month) && month >= 1 && month <= 12;

  return isValidMonth ? month - 1 : new Date().getMonth();
};

export const getYear = (params: URLSearchParams): number => {
  const param = params.get("year");
  const year = Number.parseInt(String(param), 10);
  const isValidYear = isFiniteNumber(year) && year >= 2000 && year <= 3000;

  return isValidYear ? year : new Date().getFullYear();
};
