import type React from "react";

import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { useGetPatientCareEventsQuery } from "api/hooks/useGetPatientCareEventsQuery";
import type { CareEvent } from "api/models/CareEvent";
import { useProfileContext } from "contexts/ProfileContext";
import { Accordion } from "shared/atoms/Accordion";
import Notification from "shared/atoms/Notification";
import Spinner from "shared/atoms/Spinner";
import type { Market, MedicalNoteType } from "types";
import type { DateString } from "types/DateString";
import useLocalizedDate from "utils/date";
import { getMedicalNoteConfig } from "utils/medicalNotes";

import { isCareEvent } from "../../utils/typeCheckers";
import RecordDynamicNote from "../RecordDynamicNote";
import RecordNote from "../RecordNote";

const RecordNoteList: React.VFC = () => {
  const { t } = useTranslation();
  const { parseISO } = useLocalizedDate();
  const { profile } = useProfileContext();
  const patientId = Number(useParams().patientId);

  const { data, isLoading, error } = useGetPatientCareEventsQuery({ patientId, signed: true });

  const compareNotes = (note1: CareEvent, note2: CareEvent) => {
    if (note2.start_time === null) return 1;
    if (note1.start_time === null) return -1;
    const date1 = parseISO(note1.start_time);
    const date2 = parseISO(note2.start_time);
    if (date1 === date2) return 0;
    return date1 < date2 ? 1 : -1;
  };

  return (
    <>
      {!isLoading && !error && data?.length === 0 && (
        <span>{t("patients.medical_records.no_signed_medical_records")}</span>
      )}
      {!isLoading && !error && data && (
        <Accordion>
          {data.sort(compareNotes).map(record => {
            if (isCareEvent(record)) {
              if (record.medical_record_note === null)
                return (
                  <RecordDynamicNote
                    key={record.id}
                    record={record}
                    config={getMedicalNoteConfig(
                      profile?.market as Market,
                      record.label as MedicalNoteType,
                      record.signed_at as DateString
                    )}
                  />
                );
              return <RecordNote key={record.id} record={record} />;
            }
            return null;
          })}
        </Accordion>
      )}
      {isLoading && <Spinner dataTestId="loading" small />}
      {error && (
        <Notification type="danger">
          <span>{t("errors.generic")}</span>
        </Notification>
      )}
    </>
  );
};

export default RecordNoteList;
