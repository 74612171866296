import type React from "react";
import { useContext, useEffect, useState } from "react";

import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useMatch, useNavigate } from "react-router-dom";
import styled, { ThemeContext } from "styled-components";

import { FilterIcon, InvitePatientIcon } from "assets";
import Divider from "shared/atoms/Divider";
import FilterTag from "shared/atoms/FilterTag";
import IconButton from "shared/atoms/IconButton";
import VideoCallAwareContainer from "shared/atoms/VideoCallAwareContainer/VideoCallAwareContainer";
import usePageContentOffsetTop from "shared/styles/usePageContentOffsetTop";
import { AnalyticsPages, AnalyticsService } from "utils/analytics";
import { PatientsColumnsContext, PatientsFilterContext } from "utils/contexts";
import { useQueryParams, useWindowSize } from "utils/hooks";
import joinUrl from "utils/url/joinUrl";

import { InvitePatient, PatientsFilter, PatientsTable } from "../PatientsList";
import type { DataColumnsContextType } from "../PatientsList/dataColumns";
import { minTableWidth } from "../PatientsList/dataColumns";
import type { Filter, Filters } from "../PatientsList/PatientsFilter/useFilters";
import type { Sorting } from "../PatientsList/sorting";
import usePatients from "../PatientsList/usePatients";

const PatientsList: React.VFC = () => {
  const theme = useContext(ThemeContext);
  const { width } = useWindowSize();
  const { t } = useTranslation();
  const match = useMatch("*");
  const params = useQueryParams();
  const navigate = useNavigate();
  const { columns } = useContext<DataColumnsContextType>(PatientsColumnsContext);
  const { activeFilters, filterLabel, toggleFilter, setFilter } = useContext<Filters>(PatientsFilterContext);
  const offsetTop = usePageContentOffsetTop();

  const [inviteOpen, setInviteOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const [sort, setSort] = useState<Sorting>({
    descending: true,
    selectedColumn: columns[0],
  });

  const searchForm = useForm({
    defaultValues: {
      search: "",
    },
  });

  const searchValue = searchForm.watch("search");
  const patientsQuery = usePatients(searchValue, sort.selectedColumn, sort.descending);

  useEffect(() => {
    if (match) {
      AnalyticsService.viewedPage(AnalyticsPages.PATIENTS.PATIENTS_LIST, match.pathname);
    }
  }, [match?.pathname]);

  const breakpointMobile = Number.parseInt(theme.breakpointMobile, 10);

  const closeFilterTag = (key: Filter) => {
    switch (key) {
      case "primary_joint":
        setFilter("primary_joint", { active: false, selection: [] });
        break;
      default:
        toggleFilter(key);
    }
    if (params.get("filter") === key && match) {
      params.delete("filter");
      navigate(joinUrl(match?.pathname, params));
    }
  };
  const renderedTags = activeFilters.map((key: Filter) => {
    return <FilterTag key={key} close={() => closeFilterTag(key)} label={filterLabel(key)} />;
  });

  return (
    <StyledDiv $pageContentOffsetTop={offsetTop} data-testid="patients-list">
      <PageWrapper $pageContentOffsetTop={offsetTop}>
        <PatientsFilter open={isOpen} close={() => setIsOpen(false)} patientsQuery={patientsQuery} />
        <ListContainer $minTableWidth={minTableWidth(columns)}>
          <Container>
            <IconButtonWrapper>
              <IconButton
                dataTestId="expand-filters"
                label={t("patients.filters")}
                onClick={() => {
                  setIsOpen(!isOpen);
                }}
              >
                <FilterIcon />
              </IconButton>
            </IconButtonWrapper>
            {width >= breakpointMobile && <TagsContainer>{renderedTags}</TagsContainer>}
            <IconButtonWrapper $leftMargin>
              <IconButton
                label={t("patients.invite")}
                onClick={event => {
                  if (!inviteOpen) {
                    event.stopPropagation();
                    setInviteOpen(true);
                  }
                }}
                dataTestId="invite-patient"
              >
                <InvitePatientIcon />
              </IconButton>
              <InvitePatient close={() => setInviteOpen(false)} visible={inviteOpen} />
            </IconButtonWrapper>
          </Container>
          {width < breakpointMobile && (
            <TagsContainer $haveFilters={activeFilters.length > 0}>{renderedTags}</TagsContainer>
          )}
          <Divider />
          <PatientsTable searchForm={searchForm} sort={sort} onSortChange={setSort} patientsQuery={patientsQuery} />
        </ListContainer>
      </PageWrapper>
    </StyledDiv>
  );
};

type StyledDivProps = {
  $pageContentOffsetTop: number;
};

const StyledDiv = styled.div<StyledDivProps>`
  background-color: ${props => props.theme.colors.greys.light2};
  width: 100%;
  display: block;
  box-sizing: border-box;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  padding-top: ${props => props.$pageContentOffsetTop}px;
  height: 100vh;
`;

type PageWrapperProps = {
  $pageContentOffsetTop: number;
};

const PageWrapper = styled.div<PageWrapperProps>`
  display: flex;
  flex-direction: row;
`;

type IconButtonWrapperProps = {
  $leftMargin?: boolean;
};

const IconButtonWrapper = styled.div<IconButtonWrapperProps>`
  padding: ${props => props.theme.spacing.S_5} 0;
  margin-left: ${props => (props.$leftMargin ? "auto" : "0")};
  height: 32px;
  display: flex;
  align-items: center;
`;

type ListContainerProps = {
  $minTableWidth: number;
};

const ListContainer = styled(VideoCallAwareContainer)<ListContainerProps>`
  flex: 3;
  box-sizing: border-box;
  width: 100%;
  padding: ${props => props.theme.spacing.S_10} ${props => props.theme.spacing.S_15};
  max-width: ${props => Math.max(props.$minTableWidth, 900)}px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

type TagsContainerProps = {
  $haveFilters?: boolean;
};

const TagsContainer = styled.div<TagsContainerProps>`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  min-height: 42px;
  margin: 0 ${props => props.theme.spacing.S_15};
  ${props => props.theme.belowMobileBreakpoint} {
    min-height: ${props => (props.$haveFilters ? "42px" : "auto")};
    margin: 0 0 ${props => props.theme.spacing.S_10} 0;
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  margin: ${props => props.theme.spacing.S_10} 0;
  ${props => props.theme.belowMobileBreakpoint} {
    margin: ${props => props.theme.spacing.S_5} 0;
  }
  min-height: 42px;
`;

export default PatientsList;
