import type React from "react";

import useLocalizedDate from "utils/date";

const capitalizeFirstLetter = (stringToCapitalize: string) => {
  const [first, ...rest] = stringToCapitalize.split("");
  return [first.toUpperCase(), ...rest].join("");
};

interface Props {
  readonly dateString: string;
  readonly capitalize?: boolean;
}

const RelativeDatetime: React.VFC<Props> = ({ dateString, capitalize = false }) => {
  const { parseISO, isValid, formatRelative } = useLocalizedDate();
  const parsedDate = parseISO(dateString);
  if (!isValid(parsedDate)) return <></>;
  const formatted = formatRelative(parsedDate);
  return <>{capitalize ? capitalizeFirstLetter(formatted) : formatted}</>;
};

export default RelativeDatetime;
