import styled from "styled-components";

const ChartCaption = styled.div`
  color: ${props => props.theme.colors.redesign.db80};
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 40px;
`;

export default ChartCaption;
