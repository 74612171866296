import axios from "axios";
import type { NavigateFunction } from "react-router-dom";

import getUserData from "shared/ProtectedRoute/queries";
import { removeAuthCookies, setNewAuthCookies } from "utils/cookie";
import { isErrorOfType, reportError } from "utils/errorReporting";
import { userIsPatient } from "utils/profile/profileHelper";

import type { CheckResponse, LegacyProgressStatus, StartResponse } from "./api";
import { check, start } from "./api";

let getResultTimeout: NodeJS.Timeout;

export const abortBankID = () => {
  clearTimeout(getResultTimeout);
};

export type CustomStatus = LegacyProgressStatus | "no_user" | "patient_profile";
export type FlowType = "desktop" | "mobile" | "qr";

interface BaseArgs {
  confirmation_token?: string;
  flowType: FlowType;
  successUrl: string;
  navigate: NavigateFunction;
  onUpdate: (data: CheckResponse) => void;
}

interface CheckBankIDArgs extends BaseArgs {
  transactionId: string;
  onError?: (error: unknown) => void;
}

export const checkBankID = ({
  confirmation_token,
  flowType,
  successUrl,
  transactionId,
  navigate,
  onUpdate,
  onError,
}: CheckBankIDArgs) => {
  const getResult = () => {
    check({ confirmation_token, order_reference: transactionId })
      .then(async response => {
        if (typeof onUpdate === "function") {
          onUpdate(response.data);
        }

        switch (response.data.progress_status) {
          case "pending": {
            getResultTimeout = setTimeout(getResult, 1000);
            break;
          }

          case "complete": {
            setNewAuthCookies({
              idToken: response.data.result.id_token,
              refreshToken: response.data.result.refresh_token,
            });
            let userResponse;
            try {
              userResponse = await getUserData();
              if (userIsPatient(userResponse.data.data)) {
                removeAuthCookies();
                navigate(
                  { pathname: "../bankid/result-failed" },
                  {
                    state: {
                      error: "patient_profile",
                      flowType,
                    },
                  }
                );
                return;
              }
            } catch (error) {
              if (isErrorOfType(error)) {
                reportError("initBankID", error);
              }
              userResponse = null;
            }

            if (!userResponse) {
              removeAuthCookies();
              navigate(
                { pathname: "../bankid/result-failed" },
                {
                  state: {
                    error: "no_user",
                    flowType,
                  },
                }
              );
            } else {
              setTimeout(() => navigate(successUrl), 1000);
            }
            break;
          }

          case "failed": {
            navigate(
              { pathname: "../bankid/result-failed" },
              {
                state: {
                  error: response.data.legacy_progress_status,
                  flowType,
                },
              }
            );
            break;
          }
          default:
            reportError("checkBankID", new Error(`Unknown status code: ${response.data.progress_status}`));
        }
      })
      .catch(onError);
  };

  getResult();
};

interface InitBankIDArgs extends BaseArgs {
  onInitDone: (data: StartResponse) => void;
}

export const initBankID = ({
  confirmation_token,
  flowType,
  successUrl,
  navigate,
  onInitDone,
  onUpdate,
}: InitBankIDArgs) => {
  const handleError = (error: unknown) => {
    // Don't report error if the user took too long to scan the QR code
    if (axios.isAxiosError(error) && error?.response?.data?.legacy_progress_status !== "START_FAILED") {
      reportError("initBankID", error);
    }
    navigate(
      { pathname: "../bankid/result-failed" },
      {
        state: {
          error: axios.isAxiosError(error) ? error?.response?.data?.legacy_progress_status : error,
          flowType,
        },
      }
    );
  };

  start()
    .then(response => {
      if (typeof onInitDone === "function") {
        onInitDone(response.data);
      }

      checkBankID({
        confirmation_token,
        flowType,
        successUrl,
        transactionId: response.data.order_reference,
        navigate,
        onUpdate,
        onError: handleError,
      });
    })
    .catch(handleError);
};
