import { isNewAuthEnabled } from "utils/auth";

import { abortBankID, checkBankID, initBankID } from "./authenticateWithBankid";
import oldAuthenticateWithEmail from "./authenticateWithEmail";
import newAuthenticateWithEmail from "./newAuthenticateWithEmail";
import newPassword from "./newPassword";
import type { FormData } from "./newSignupWithEmail";
import newSignupWithEmail from "./newSignupWithEmail";
import resetPassword from "./resetPassword";

const newOrOldAuthenticateWithEmail = (arg: { email: string; password: string }): Promise<void> => {
  if (isNewAuthEnabled()) {
    return newAuthenticateWithEmail(arg);
  }

  if (process.env.REACT_APP_USE_RAILS_EMAIL_LOGIN === "true") {
    return oldAuthenticateWithEmail(arg);
  }

  return oldAuthenticateWithEmail(arg);
};

const newOrOldSignupWithEmail = (arg: FormData) => {
  if (isNewAuthEnabled()) {
    return newSignupWithEmail(arg);
  }

  return oldAuthenticateWithEmail(arg);
};

export {
  newOrOldSignupWithEmail as signupWithEmail,
  newOrOldAuthenticateWithEmail as authenticateWithEmail,
  resetPassword,
  newPassword,
  abortBankID,
  initBankID,
  checkBankID,
};
