import { UserRole } from "api/models/User";
import type { Market } from "types";
import { isTypeMarket } from "types";

export type UserProfile = {
  roles: UserRole[];
  market?: string;
};

const isUserMarket = (market: Market | Market[], profile: UserProfile): boolean => {
  if (!profile.market || !isTypeMarket(profile.market)) {
    return false;
  }
  let checkMarket = market;
  if (!Array.isArray(checkMarket)) {
    checkMarket = [market] as Market[];
  }
  return checkMarket.includes(profile.market);
};

const isUserOfType = (roles: UserRole[], profile?: UserProfile): boolean => {
  if (!profile || !Array.isArray(profile.roles) || !Array.isArray(roles)) {
    return false;
  }

  return roles.some(role => profile.roles.includes(role));
};

const userIsDoctor = (profile?: UserProfile): boolean => isUserOfType([UserRole.Doctor], profile);
const userIsTherapist = (profile?: UserProfile): boolean => isUserOfType([UserRole.Therapist], profile);
const userIsPartner = (profile?: UserProfile): boolean => isUserOfType([UserRole.Partner], profile);
const userIsPatient = (profile?: UserProfile): boolean => isUserOfType([UserRole.Patient], profile);
const userIsAdmin = (profile?: UserProfile): boolean => isUserOfType([UserRole.HCPAdmin], profile);

const publicPaths = ["/login", "/onboarding"];
const patientFacingPaths = ["/dashboard", "/patients"];

const doctorsOnlyPaths = patientFacingPaths;
const therapistsOnlyPaths = [
  ...patientFacingPaths,
  "/calendar",
  "/messages",
  "/settings",
  "/monthly-report",
  "/library",
];
const partnerOnlyPaths = patientFacingPaths;
const adminOnlyPaths = ["/calendar", "/settings"];

const isRouteAllowed = (profile: UserProfile, path: string): boolean => {
  if (path === "/" || publicPaths.some(p => path.startsWith(p))) {
    return true;
  }
  if (profile) {
    if (userIsDoctor(profile) && doctorsOnlyPaths.some(p => path.startsWith(p))) {
      return true;
    }
    if (userIsTherapist(profile) && therapistsOnlyPaths.some(p => path.startsWith(p))) {
      return true;
    }
    if (userIsPartner(profile) && partnerOnlyPaths.some(p => path.startsWith(p))) {
      return true;
    }
    if (userIsAdmin(profile) && adminOnlyPaths.some(p => path.startsWith(p))) {
      return true;
    }
  }
  return false;
};

export const initalRouteForProfile = (profile: UserProfile) => {
  if (profile) {
    if (userIsAdmin(profile)) {
      return "/calendar";
    }
  }
  return "/dashboard";
};

export {
  isRouteAllowed,
  isUserMarket,
  isUserOfType,
  userIsAdmin,
  userIsDoctor,
  userIsPartner,
  userIsPatient,
  userIsTherapist,
};
