import type { DragEvent, VFC } from "react";
import React, { useContext, useEffect, useState } from "react";

import type { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useTrail } from "react-spring";
import { ThemeContext } from "styled-components";

import { CalendarIcon, CommentsIcon, MessageIcon, SearchIcon, SmallBlueClock, StarYellow } from "assets";
import { PaymentMethodTag } from "routes/patients/PatientProfile/components/PatientHeader/PatientHeaderInfo/components/PaymentMethodTag";
import ActionLink from "shared/atoms/ActionLink";
import Pagination from "shared/atoms/Pagination";
import PremiumIcon from "shared/atoms/PremiumIcon";
import Tag from "shared/atoms/Tag";
import PatientStatus from "shared/molecules/PatientStatus";
import { Table, TableCell, TableMissingContent, TableRow, TableSort } from "shared/molecules/Table";
import { PatientsColumnsContext } from "utils/contexts";
import { useWindowSize } from "utils/hooks";
import isFiniteNumber from "utils/number/is-finite-number";
import { getStorageValue, setStorageValue } from "utils/storage";

import type { ColumnType, DataColumnsContextType } from "../dataColumns";
import { columnLabel } from "../dataColumns";
import DateLabel from "../DateLabel";
import JointLabel from "../JointLabel";
import type { Patient } from "../queries/fetchPatients";
import { TherapistAssignmentRole } from "../queries/TherapistAssignmentRole";
import type { Sorting } from "../sorting";
import type { UsePatientQuery } from "../usePatients";

import {
  AfterNameIcon,
  Container,
  CoverageWrapper,
  DateWrapper,
  DisplayAdherence,
  DisplayNumber,
  InnerWrapper,
  Name,
  NameWrapper,
  PreferredNameWrapper,
  PrimaryJointWrapper,
  SmallWrapper,
  StatusWrapper,
  StyledIcon,
  StyledSearch,
  TableContainer,
  TableLink,
  Wrapper,
} from "./helpers";

type ColumnProps = {
  patient: Patient;
  dragOver: boolean;
  dragDirection: string;
};

type ColumnIdentifier = {
  index: number;
  name: ColumnType;
};

export const NameColumn: VFC<ColumnProps> = ({ patient, dragOver, dragDirection }) => {
  const { width } = useWindowSize();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const theme = useContext(ThemeContext);

  // FE fix to hide low adherence notification for PTonDemand patients
  // https://jointacademy.atlassian.net/browse/CCC-1192
  const hideLowAdherenceNotification = patient.state === "in_selfcare";

  return (
    <NameWrapper dragOver={dragOver} dragDirection={dragDirection}>
      <InnerWrapper>
        {patient.prio && (
          // FIXME: type translation
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          <StyledIcon title={t("patients.tool_tip.prio")}>
            <StarYellow />
          </StyledIcon>
        )}
        {/* FIXME: type translation */}
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        {patient.newPatient && <Tag label={t("common.new.0")} />}
        <PremiumIcon premiumType={patient.premiumType} size="small" margin="0 4px 0 0" />
        <TableLink
          to={{
            pathname: `/patients/${patient.id}`,
          }}
          state={{ pathname, text: "patients" }}
          $minHeight="40px"
        >
          <Name deleted={patient.deleted || false}>{patient.name}</Name>
          {patient.therapistAssignmentRole === TherapistAssignmentRole.SubstituteTherapist && (
            <AfterNameIcon title={t("patients.tool_tip.substitute_therapist")}>
              <SmallBlueClock />
            </AfterNameIcon>
          )}
          {patient.paymentMethod && patient.paymentMethod === "exemption_card" && (
            <PaymentMethodTag paymentMethod={patient.paymentMethod} small style={{ marginLeft: "4px" }} />
          )}
        </TableLink>
        {patient.appointmentToSchedule && !patient.deleted && !hideLowAdherenceNotification && (
          // FIXME: type translation
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          <div title={t("patients.tool_tip.appointment_to_schedule")}>
            <ActionLink
              to={
                {
                  pathname: `/messages/${patient.id}`,
                  search: `?meeting_purpose=${patient.appointmentToSchedule}`,
                } as { pathname: string; search: string }
              }
              m="5px"
              dataTestId="calls-icon-link"
            >
              <CalendarIcon data-testid="calls-icon" />
            </ActionLink>
          </div>
        )}
        {patient.newComments && (
          // FIXME: type translation
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          <div title={t("patients.tool_tip.new_comments")}>
            <ActionLink
              to={{
                pathname: `/patients/${patient.id}/comments`,
                state: { pathname, text: "patients" },
              }}
              m="5px"
              dataTestId="comments-icon-link"
            >
              <CommentsIcon data-testid="comments-icon" />
            </ActionLink>
          </div>
        )}
        {patient.newMessages && (
          // FIXME: type translation
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          <div title={t("patients.tool_tip.new_messages")}>
            <ActionLink
              to={{
                pathname: `/messages/${patient.id}`,
              }}
              m="5px"
            >
              <MessageIcon data-testid="messages-icon" />
            </ActionLink>
          </div>
        )}
      </InnerWrapper>
      {width < parseInt(theme.breakpoint, 10) && (
        <TableLink
          to={{
            pathname: `/patients/${patient.id}`,
          }}
          state={{ pathname, text: "patients" }}
          $minHeight="40px"
        >
          <PatientStatus patientStatuses={patient.statuses} />
        </TableLink>
      )}
    </NameWrapper>
  );
};

const PreferredNameColumn: VFC<ColumnProps> = ({ patient, dragOver, dragDirection }) => {
  const { pathname } = useLocation();

  return (
    <PreferredNameWrapper dragOver={dragOver} dragDirection={dragDirection}>
      <TableLink
        to={{
          pathname: `/patients/${patient.id}`,
        }}
        state={{ pathname, text: "patients" }}
        $minHeight="40px"
      >
        <Name deleted={false} data-testid="preferred-name-column">
          {patient.preferredName}
        </Name>
      </TableLink>
    </PreferredNameWrapper>
  );
};

const CoverageColumn: VFC<ColumnProps> = ({ patient, dragOver, dragDirection }) => {
  const { pathname } = useLocation();

  return (
    <CoverageWrapper dragOver={dragOver} dragDirection={dragDirection}>
      <TableLink
        to={{
          pathname: `/patients/${patient.id}`,
        }}
        state={{ pathname, text: "patients" }}
        $minHeight="40px"
      >
        <Name deleted={false} data-testid="coverage-column">
          {patient.coverage}
        </Name>
      </TableLink>
    </CoverageWrapper>
  );
};

const SubscriptionColumn: VFC<ColumnProps> = ({ patient, dragOver, dragDirection }) => {
  const { pathname } = useLocation();

  return (
    <SmallWrapper dragOver={dragOver} dragDirection={dragDirection}>
      <TableLink
        to={{
          pathname: `/patients/${patient.id}`,
        }}
        state={{ pathname, text: "patients" }}
        $minHeight="40px"
      >
        <Name deleted={false}>{patient.subscription}</Name>
      </TableLink>
    </SmallWrapper>
  );
};

const StatusColumn: VFC<ColumnProps> = ({ patient, dragOver, dragDirection }) => {
  const { pathname } = useLocation();

  return (
    <StatusWrapper dragOver={dragOver} dragDirection={dragDirection}>
      <TableLink
        to={{
          pathname: `/patients/${patient.id}`,
        }}
        state={{ pathname, text: "patients" }}
        $minHeight="40px"
      >
        <PatientStatus patientStatuses={patient.statuses} />
      </TableLink>
    </StatusWrapper>
  );
};

const WeekColumn: VFC<ColumnProps> = ({ patient, dragOver, dragDirection }) => {
  const { pathname } = useLocation();

  return (
    <SmallWrapper dragOver={dragOver} dragDirection={dragDirection}>
      <TableLink
        to={{
          pathname: `/patients/${patient.id}`,
        }}
        state={{ pathname, text: "patients" }}
        $minHeight="40px"
      >
        <DisplayNumber>{patient.week}</DisplayNumber>
      </TableLink>
    </SmallWrapper>
  );
};

const IDColumn: VFC<ColumnProps> = ({ patient, dragOver, dragDirection }) => {
  const { pathname } = useLocation();

  return (
    <SmallWrapper dragOver={dragOver} dragDirection={dragDirection}>
      <TableLink
        to={{
          pathname: `/patients/${patient.id}`,
        }}
        state={{ pathname, text: "patients" }}
        $minHeight="40px"
      >
        <DisplayNumber>{patient.id}</DisplayNumber>
      </TableLink>
    </SmallWrapper>
  );
};

const PrimaryJointColumn: VFC<ColumnProps> = ({ patient, dragOver, dragDirection }) => {
  const { pathname } = useLocation();

  return (
    <PrimaryJointWrapper dragOver={dragOver} dragDirection={dragDirection}>
      <TableLink
        to={{
          pathname: `/patients/${patient.id}`,
        }}
        state={{ pathname, text: "patients" }}
        $minHeight="40px"
      >
        <Name deleted={false}>
          <JointLabel ailment={patient.ailment} joint={patient.primaryJoint} />
        </Name>
      </TableLink>
    </PrimaryJointWrapper>
  );
};

const LastActivityCompletedAtColumn: VFC<ColumnProps> = ({ patient }) => {
  const { pathname } = useLocation();

  return (
    <DateWrapper>
      <TableLink
        to={{
          pathname: `/patients/${patient.id}`,
        }}
        state={{ pathname, text: "patients" }}
        $minHeight="40px"
      >
        <DateLabel date={patient.lastActivityCompletedAt} />
      </TableLink>
    </DateWrapper>
  );
};

const AdherenceColumn: VFC<ColumnProps> = ({ patient }) => {
  const { pathname } = useLocation();

  return (
    <DateWrapper>
      <TableLink
        to={{
          pathname: `/patients/${patient.id}`,
        }}
        state={{ pathname, text: "patients" }}
        $minHeight="40px"
      >
        <DisplayAdherence adherence={patient.adherence}>{patient.adherence}%</DisplayAdherence>
      </TableLink>
    </DateWrapper>
  );
};

const LastMedicalReferralSignatureAt: VFC<ColumnProps> = ({ patient }) => {
  const { pathname } = useLocation();

  return (
    <DateWrapper>
      <TableLink
        to={{
          pathname: `/patients/${patient.id}`,
        }}
        state={{ pathname, text: "patients" }}
        $minHeight="40px"
      >
        <DateLabel date={patient.lastMedicalReferralSignatureAt} />
      </TableLink>
    </DateWrapper>
  );
};

const ReferralProcessStatus: VFC<ColumnProps> = ({ patient, dragOver, dragDirection }) => {
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const referralProcessStatusHandler = (status: string | null) => {
    switch (status) {
      case "questionnaire_submitted":
        // FIXME: type translation
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return t("patients.referral_process_status.in_review");
      case "waiting_for_referral_upload":
        // FIXME: type translation
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return t("patients.referral_process_status.waiting_for_upload");
      case "rejected":
        // FIXME: type translation
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return t("patients.referral_process_status.rejected");
      case "referral_uploaded":
        // FIXME: type translation
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return t("patients.referral_process_status.uploaded");
      default:
        return "";
    }
  };

  return (
    <PreferredNameWrapper dragOver={dragOver} dragDirection={dragDirection}>
      <TableLink
        to={{
          pathname: `/patients/${patient.id}`,
        }}
        state={{ pathname, text: "patients" }}
        $minHeight="40px"
      >
        <Name deleted={false} data-testid="referral-process-status-column">
          {referralProcessStatusHandler(patient.referralProcessStatus)}
        </Name>
      </TableLink>
    </PreferredNameWrapper>
  );
};

const AddressStateColumn: VFC<ColumnProps> = ({ patient, dragOver, dragDirection }) => {
  const { pathname } = useLocation();

  return (
    <SmallWrapper dragOver={dragOver} dragDirection={dragDirection}>
      <TableLink
        to={{
          pathname: `/patients/${patient.id}`,
        }}
        state={{ pathname, text: "patients" }}
        $minHeight="40px"
      >
        <DisplayNumber>{patient.addressStateCode}</DisplayNumber>
      </TableLink>
    </SmallWrapper>
  );
};

const columnComponents: Record<ColumnType, VFC<ColumnProps>> = {
  name: NameColumn,
  preferredName: PreferredNameColumn,
  coverage: CoverageColumn,
  subscription: SubscriptionColumn,
  primaryJoint: PrimaryJointColumn,
  status: StatusColumn,
  week: WeekColumn,
  id: IDColumn,
  lastActivityCompletedAt: LastActivityCompletedAtColumn,
  lastMedicalReferralSignatureAt: LastMedicalReferralSignatureAt,
  referralProcessStatus: ReferralProcessStatus,
  activityAdherence: AdherenceColumn,
  addressState: AddressStateColumn,
};

const createColumn = (patient: Patient, column: ColumnType, dragDirection: string, dragOver = "") => {
  return React.createElement(columnComponents[column], {
    patient,
    key: column,
    dragOver: column === dragOver,
    dragDirection,
  });
};

type PatientsTableProps = {
  onSortChange: (next: Sorting) => void;
  sort: Sorting;
  searchForm: UseFormReturn<{ search: string }>;
  patientsQuery: UsePatientQuery;
};

const PatientsTable: React.VFC<PatientsTableProps> = ({ onSortChange, sort, searchForm, patientsQuery }) => {
  const theme = useContext(ThemeContext);
  const { watch, register, setValue } = searchForm;

  const { width } = useWindowSize();
  const isSmallScreen = width <= parseInt(theme.breakpoint, 10);
  const isMobileScreen = width <= parseInt(theme.breakpointMobile, 10);

  const { t } = useTranslation();
  const { columns: contextColumns, moveColumn } = useContext<DataColumnsContextType>(PatientsColumnsContext);
  const columns = isSmallScreen ? (["name"] as ColumnType[]) : contextColumns;

  const searchValue = watch("search");
  const { patients, isLoading, error, offset, setOffset } = patientsQuery;

  const [dragColumn, setDragColumn] = useState<ColumnIdentifier | null>(null);
  const [dragOver, setDragOver] = useState<ColumnIdentifier | null>(null);

  const handleDragStart = (event: DragEvent<HTMLTableCellElement>) => {
    const dragName = event.currentTarget.id as ColumnType;
    const dragIndex = columns.indexOf(dragName);

    setDragColumn({ name: dragName, index: dragIndex });
  };

  const handleDragOver = (event: DragEvent<HTMLTableCellElement>) => {
    event.preventDefault();
  };

  const handleDragEnter = (event: DragEvent<HTMLTableCellElement>) => {
    const overName = event.currentTarget.id as ColumnType;
    const overIndex = columns.indexOf(overName);

    setDragOver({ name: overName, index: overIndex });
  };

  const handleOnDrop = (event: DragEvent<HTMLTableCellElement>) => {
    if (!dragColumn) {
      return;
    }
    const dropName = event.currentTarget.id as ColumnType;

    moveColumn(dragColumn.name, dropName);
    setDragOver(null);
    setDragColumn(null);
  };

  useEffect(() => {
    const storedSortColumn = getStorageValue("patient-list-sort");

    if (storedSortColumn && columns.includes(storedSortColumn.selectedColumn)) {
      onSortChange(storedSortColumn);
    }
  }, []);

  const trail = useTrail(patients.length, { opacity: 1, from: { opacity: 0 }, config: { tension: 400 } });

  if (error || isLoading) {
    return (
      <TableContainer $height={width > parseInt(theme.breakpoint, 10) ? "500px" : "auto"}>
        <Table>
          <tbody data-testid="missing-content">
            <TableMissingContent error={!!error} loading />
          </tbody>
        </Table>
      </TableContainer>
    );
  }

  const totalCount = patients?.length;
  const rowsLimit = 50;
  const hasNextPage = offset + rowsLimit < totalCount;
  const hasPreviousPage = offset !== 0;
  const lastRowIndex = (hasNextPage ? offset + rowsLimit : totalCount) - 1;

  const onSort = (clickedColumn: ColumnType) => {
    if (sort.selectedColumn === clickedColumn) {
      onSortChange({ selectedColumn: clickedColumn, descending: !sort.descending });
      setStorageValue("patient-list-sort", { selectedColumn: clickedColumn, descending: !sort.descending });
    } else {
      onSortChange({ selectedColumn: clickedColumn, descending: true });
      setStorageValue("patient-list-sort", { selectedColumn: clickedColumn, descending: true });
    }
    setOffset(0);
  };

  const dragDirection = calculateDragDirection(dragColumn?.index, dragOver?.index);

  return (
    <Wrapper>
      <Container>
        <StyledSearch
          data-testid="search-input"
          reset={() => setValue("search", "")}
          icon={SearchIcon}
          {...register("search")}
          placeholder={t("patients.search")}
          search={searchValue}
        />
        <Pagination
          data-testid="pagination"
          totalCount={totalCount}
          pageInfo={{ hasPreviousPage, hasNextPage }}
          first={offset + 1}
          last={lastRowIndex + 1}
          onPageChange={goTo => {
            setOffset(offset + (goTo === "next" ? rowsLimit : -rowsLimit));
          }}
        />
      </Container>
      <TableContainer $height="auto" style={{ marginBottom: "30px" }}>
        <Table>
          <thead data-testid="table-header">
            {patients.length > 0 && (
              <TableSort
                columns={columns}
                sortBy={sort.selectedColumn}
                descending={sort.descending}
                onSort={clickedColumn => onSort(clickedColumn as ColumnType)}
                translateColumn={column => columnLabel(t, column as ColumnType)}
                paginationLabel={
                  isMobileScreen
                    ? t("common.pagination", { first: offset + 1, last: lastRowIndex + 1, totalCount })
                    : ""
                }
                onDragStart={handleDragStart}
                onDragOver={handleDragOver}
                onDrop={handleOnDrop}
                onDragEnter={handleDragEnter}
                dragOver={dragOver?.name}
                dragDirection={dragDirection}
              />
            )}
          </thead>
          <tbody data-testid="table-body">
            {patients.length > 0 &&
              trail.map((_, index) => {
                const patient = patients[index];
                return index >= offset && index <= lastRowIndex ? (
                  <TableRow key={patients[index].id}>
                    {columns.map(column => createColumn(patient, column, dragDirection, dragOver?.name))}
                  </TableRow>
                ) : null;
              })}
            {patients.length === 0 && (
              <TableRow hoverEnabled={false}>
                <TableCell dragDirection={dragDirection}>{t("patients.no_patients")}</TableCell>
              </TableRow>
            )}
          </tbody>
        </Table>
      </TableContainer>
    </Wrapper>
  );
};

export default PatientsTable;

const calculateDragDirection = (dragIndex?: number, overIndex?: number) => {
  if (isFiniteNumber(dragIndex) && isFiniteNumber(overIndex)) {
    if (dragIndex < overIndex) {
      return "right";
    }
    if (dragIndex > overIndex) {
      return "left";
    }
  }
  return "";
};
