// FIXME: type translation
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useContext } from "react";

import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { CurrentPatientContext } from "utils/contexts";

interface Props {
  ailment?: Ailment;
}

const PatientDetails: React.VFC<Props> = ({ ailment }) => {
  const { patient } = useContext(CurrentPatientContext);
  const { t } = useTranslation();
  const patientName = `${patient.first_name} ${patient.first_name}`;

  const { location, lateral_location } = patient?.most_painful_location ?? {};

  const getAilment = () => {
    if (ailment === "fall_prevention") {
      return <>{t("patients.ailments.fall_prevention")}</>;
    }

    if (ailment === "osteoporosis") {
      return <>{t("patients.ailments.osteoporosis")}</>;
    }

    const painLocation = `${
      lateral_location && lateral_location !== "undefined"
        ? `${t(`patients.lateral_location.${lateral_location}`)} `
        : ""
    }${t(`patients.pain_location.${location}.0`)}`;
    const numberOfPains = patient.pain_locations.length;
    return `${painLocation}${numberOfPains > 1 ? ` + ${numberOfPains}` : ""}`;
  };

  const currentProtocolWeek = patient.week;

  return (
    <Root>
      <div>
        <Label>{t("usercourse.patient")}</Label>
        <Detail>{patientName}</Detail>
      </div>
      <div>
        <Label>{t("patients.ailment")}</Label>
        <Detail>{getAilment()}</Detail>
      </div>
      <div>
        <Label>{t("usercourse.protocol_week")}</Label>
        <Detail>{currentProtocolWeek}</Detail>
      </div>
    </Root>
  );
};

export default PatientDetails;

const Detail = styled.div`
  font-size: 14px;
`;
const Label = styled.div`
  color: ${props => props.theme.colors.redesign.db60};
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 6px;
`;

const Root = styled.div`
  display: flex;
  gap: 22px;
  padding: 24px 32px;
  background-color: #f8f8f8;
  flex-wrap: wrap;
`;
